import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PageHeader, Spin, Card } from 'antd';
import useStudies from '../lib/useStudies';

import './StudyList.scss';

export default function StudyList() {
  const { isStudiesContextLoaded, studyData } = useStudies();
  const { t } = useTranslation();

/*
    // here, if it's a single-site context, we want to emphasize the site
    // else if it's a single-study context, we want to emphasize the study,
    // otherwise we present all of the studies

    // 1 study, 0 or 1 site: no need for sites navigation

    // single study, multiple sites user

  */

  if(!isStudiesContextLoaded) {
    return <Spin />;
  }
  
  if(!studyData) {
    return null;
  }
  
  const compare = (a, b) => {
    const studyNameA = a.studyName.toUpperCase();
    const studyNameB = b.studyName.toUpperCase();
  
    let comparison = 0;
    if (studyNameA > studyNameB) {
      comparison = 1;
    } else if (studyNameA < studyNameB) {
      comparison = -1;
    }
    return comparison;
  }
  studyData.sort(compare);

  return (
    <div className="StudyList">
      <PageHeader
        className="site-page-header"
        title={t('product-name')}
      />
      <Card title={t('navigation.Studies')} className='StudyContainer'>
        {
          studyData.map( s => {

            const studyPath = `/organization/${s.organization.organizationId
                                     }/study/${s.studyId}`;

            return (
              <Link key={`study-${s.studyId}`} to={studyPath} >
                <Card.Grid>
                  {s.studyName}
                </Card.Grid>
              </Link>
            );
          })
        }
      </Card>
    </div>
  );
}
