import React from 'react';
import { Switch, useLocation, Redirect } from 'react-router-dom';
import useAppUser from './lib/useAppUser';

import AntdLocaleProvider from './components/AntdLocaleProvider';
import AuthenticatedRoute from './components/AuthenticatedRoute';
import UnauthenticatedRoute from './components/UnauthenticatedRoute';

import {
  DefaultViewportLayout,
  AdminLayout,
  StudiesViewportLayout,
  SiteViewportLayout,
  DocumentLayout,
  DocumentViewportLayout,
  } from './components/Layout'

import { StudiesProvider } from './lib/StudiesContext';
import { SiteProvider } from './lib/SiteContext';
import { SubjectItemProvider } from './lib/SubjectItemContext';
import { SubjectProvider } from './lib/SubjectContext';
import { DocumentProvider } from './lib/DocumentContext';
import { AdminProvider } from './lib/AdminContext';
import { PreferencesProvider } from './lib/PreferencesContext';
import { SessionTrackingProvider } from './lib/SessionTrackingContext';
import { LocalityProvider } from './lib/LocalityContext';

import NotFound from './containers/NotFound';
import LoginEmail from './containers/login/LoginEmail';
import LoginPassword from './containers/login/LoginPassword';
import Register from './containers/login/Register';
import PasswordReset from './containers/login/PasswordReset';

import Training from './containers/Training';
import UserProfile from './containers/UserProfile';
import Study from './containers/Study';
import SiteView from './containers/SiteView';
import SubjectDocumentList from './containers/SubjectDocumentList';
import SubjectDocumentAdd from './containers/SubjectDocumentAdd';
import SubjectDocumentEdit from './containers/SubjectDocumentEdit';
import DocumentVersionsList from './containers/DocumentVersionsList';

import StudyList from './containers/StudyList';
import DocumentReview from './containers/DocumentReview';
import DocumentView from './containers/DocumentView';

import { AdminOrganizationList } from './containers/admin/AdminOrganizationList';
import { AdminOrganization } from './containers/admin/AdminOrganization';
import { AdminUser } from './containers/admin/AdminUser';
import { AdminStudyList } from './containers/admin/AdminStudyList';
import { AdminStudy } from './containers/admin/AdminStudy';
import { AdminStudyUser } from './containers/admin/AdminStudyUser';
import { AdminStudySiteList } from './containers/admin/AdminStudySiteList';
import { AdminStudySite } from './containers/admin/AdminStudySite';
import { AdminDomainList } from './containers/admin/AdminDomainList';
import { AdminDomain } from './containers/admin/AdminDomain';
import { AdminDatakeyList } from './containers/admin/AdminDatakeyList';
import { AdminDatakey } from './containers/admin/AdminDatakey';

const UnauthenticatedRoutes = props => {

  const { pathname, search } = useLocation();

  if(props.isAuthenticated) {
    return null;
  }

  return (
    <Switch>

      <UnauthenticatedRoute exact path='/login'>
        <DefaultViewportLayout>
          <LoginEmail />
        </DefaultViewportLayout>
      </UnauthenticatedRoute>

      <UnauthenticatedRoute exact path='/login/:email'>
        <DefaultViewportLayout>
          <LoginEmail />
        </DefaultViewportLayout>
      </UnauthenticatedRoute>

      <UnauthenticatedRoute exact path='/password'>
        <DefaultViewportLayout>
          <LoginPassword />
        </DefaultViewportLayout>
      </UnauthenticatedRoute>

      <UnauthenticatedRoute exact path='/register'>
        <DefaultViewportLayout>
          <Register />
        </DefaultViewportLayout>
      </UnauthenticatedRoute>

      <UnauthenticatedRoute exact path='/reset'>
        <DefaultViewportLayout>
          <PasswordReset />
        </DefaultViewportLayout>
      </UnauthenticatedRoute>

      <UnauthenticatedRoute exact path='/'>
        <Redirect to='/login'/>
      </UnauthenticatedRoute>

      <UnauthenticatedRoute>
        <Redirect to={ `/login?redirect=${pathname}${search}` }/>
      </UnauthenticatedRoute>

    </Switch>
  );
};

const AuthenticatedRoutes = props => {

  const { isAuthenticated, isAdminRole } = props;

  if(!isAuthenticated) {
    return null;
  }

  // /password renders a couple of times after logging in
  return (
    <Switch>

      <UnauthenticatedRoute exact path='/password'>
        <DefaultViewportLayout>
          <LoginPassword />
        </DefaultViewportLayout>
      </UnauthenticatedRoute>


      <SessionTrackingProvider>
        <PreferencesProvider>
          <Switch>
            <AuthenticatedRoute exact path='/training'>
              <Training />
            </AuthenticatedRoute>
          <StudiesProvider>
            <Switch>

          { isAdminRole && (

          <AdminProvider path='/admin'>
            <Switch>

            <AuthenticatedRoute exact path='/admin/organization'>
              <AdminLayout>
                <AdminOrganizationList />
              </AdminLayout>
            </AuthenticatedRoute>

            <AuthenticatedRoute exact path='/admin/organization/:organizationId'>
              <AdminLayout>
                <AdminOrganization />
              </AdminLayout>
            </AuthenticatedRoute>

            <AuthenticatedRoute exact path='/admin/user'>
              <AdminLayout>
                <AdminUser />
              </AdminLayout>
            </AuthenticatedRoute>

            <AuthenticatedRoute exact path='/admin/user/:email'>
              <AdminLayout>
                <AdminUser />
              </AdminLayout>
            </AuthenticatedRoute>

            <AuthenticatedRoute exact path='/admin/organization/:organizationId/study'>
              <AdminLayout>
                <AdminStudyList />
              </AdminLayout>
            </AuthenticatedRoute>

            <AuthenticatedRoute exact path='/admin/organization/:organizationId/study/:studyId'>
              <AdminLayout>
                <AdminStudy />
              </AdminLayout>
            </AuthenticatedRoute>

            <AuthenticatedRoute exact path='/admin/organization/:organizationId/study/:studyId/user'>
              <AdminLayout>
                <AdminStudyUser />
              </AdminLayout>
            </AuthenticatedRoute>

            <AuthenticatedRoute exact path='/admin/organization/:organizationId/study/:studyId/site'>
              <AdminLayout>
                <AdminStudySiteList />
              </AdminLayout>
            </AuthenticatedRoute>

            <AuthenticatedRoute exact path='/admin/organization/:organizationId/study/:studyId/site/:siteId'>
              <AdminLayout>
                <AdminStudySite />
              </AdminLayout>
            </AuthenticatedRoute>

            <AuthenticatedRoute exact path='/admin/organization/:organizationId/domain'>
              <AdminLayout>
                <AdminDomainList />
              </AdminLayout>
            </AuthenticatedRoute>

            <AuthenticatedRoute exact path='/admin/organization/:organizationId/domain/:domainId'>
              <AdminLayout>
                <AdminDomain />
              </AdminLayout>
            </AuthenticatedRoute>

            <AuthenticatedRoute exact path='/admin/datakey'>
              <AdminLayout>
                <AdminDatakeyList />
              </AdminLayout>
            </AuthenticatedRoute>

            <AuthenticatedRoute exact path='/admin/datakey/:datakeyId'>
              <AdminLayout>
                <AdminDatakey />
              </AdminLayout>
            </AuthenticatedRoute>

            <AuthenticatedRoute>
              <AdminLayout>
                <NotFound/>
              </AdminLayout>
            </AuthenticatedRoute>

            </Switch>
          </AdminProvider>
          )}


              <AuthenticatedRoute exact path='/'>
                <Redirect to='/study'/>
              </AuthenticatedRoute>

              <AuthenticatedRoute exact path='/study'>
                <StudiesViewportLayout>
                  <StudyList />
                </StudiesViewportLayout>
              </AuthenticatedRoute>

              <AuthenticatedRoute exact path='/profile'>
                <StudiesViewportLayout>
                  <UserProfile />
                </StudiesViewportLayout>
              </AuthenticatedRoute>

              <AuthenticatedRoute exact path='/organization/:organizationId/study/:studyId'>
                <StudiesViewportLayout>
                  <Study />
                </StudiesViewportLayout>
              </AuthenticatedRoute>

              <SiteProvider>
                <LocalityProvider>
                  <Switch>

                    <AuthenticatedRoute exact path='/organization/:organizationId/study/:studyId/site/:siteId/subject'>
                      <SiteViewportLayout>
                        <SiteView />
                      </SiteViewportLayout>
                    </AuthenticatedRoute>

                    <AuthenticatedRoute exact path='/organization/:organizationId/study/:studyId/site/:siteId/document'>
                      <SiteViewportLayout>
                        <SiteView />
                      </SiteViewportLayout>
                    </AuthenticatedRoute>

                    <SubjectItemProvider>
                    <SubjectProvider>
                      <Switch>
                        <AuthenticatedRoute exact path='/organization/:organizationId/study/:studyId/site/:siteId/subject/:subjectId'>
                          <SiteViewportLayout>
                            <SubjectDocumentList />
                          </SiteViewportLayout>
                        </AuthenticatedRoute>

                        <AuthenticatedRoute exact
                            path='/organization/:organizationId/study/:studyId/site/:siteId/subject/:subjectId/document/add'>
                          <SiteViewportLayout>
                            <SubjectDocumentAdd />
                          </SiteViewportLayout>
                        </AuthenticatedRoute>

                        <DocumentProvider>
                          <Switch>

                            <AuthenticatedRoute exact path='/organization/:organizationId/study/:studyId/site/:siteId/subject/:subjectId/document/:documentId/version/:version/edit'>
                              <DocumentViewportLayout>
                                <SubjectDocumentEdit />
                              </DocumentViewportLayout>
                            </AuthenticatedRoute>

                            <AuthenticatedRoute exact path='/organization/:organizationId/study/:studyId/site/:siteId/subject/:subjectId/document/:documentId/version/:version/view'>
                              <DocumentViewportLayout>
                                <DocumentView />
                              </DocumentViewportLayout>
                            </AuthenticatedRoute>

                            <AuthenticatedRoute exact path='/organization/:organizationId/study/:studyId/site/:siteId/subject/:subjectId/document/:documentId/version/:version/review'>
                              <DocumentViewportLayout>
                                <DocumentReview />
                              </DocumentViewportLayout>
                            </AuthenticatedRoute>

                            <AuthenticatedRoute exact path='/organization/:organizationId/study/:studyId/site/:siteId/subject/:subjectId/document/:documentId'>
                              <DocumentLayout>
                                <DocumentVersionsList />
                              </DocumentLayout>
                            </AuthenticatedRoute>

                            <AuthenticatedRoute>
                              <StudiesViewportLayout>
                                <NotFound/>
                              </StudiesViewportLayout>
                            </AuthenticatedRoute>

                          </Switch>
                        </DocumentProvider>

                      </Switch>
                    </SubjectProvider>
                    </SubjectItemProvider>

                  </Switch>
                </LocalityProvider>
              </SiteProvider>

            </Switch>

          </StudiesProvider>
            </Switch>
        </PreferencesProvider>
      </SessionTrackingProvider>

    </Switch>
  );

};

export default function Routes() {

  const { isAuthenticated, isAdminRole } = useAppUser();

  return (
    <AntdLocaleProvider>
      <AuthenticatedRoutes
        isAuthenticated={isAuthenticated} 
        isAdminRole={isAdminRole}
      />
      <UnauthenticatedRoutes isAuthenticated={isAuthenticated}/>
    </AntdLocaleProvider>  
    );

}
