import React, { useState } from 'react';
import { Form, Input, Button } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';    
import './AdminForms.scss';

export const AccessItemsRave = props => {

  const { data } = props;
  const { Item } = Form;

  const ENDPOINT_DEFAULTS = {
    subjectPath: '/RaveWebServices/datasets/rwscmd_getdata.odm',
    usersPath: '/RaveWebServices/datasets/getusers.odm',
  };

  if(data.edcType !== 'rave') {
    return null;
  }

  return (
    <Input.Group className='AdminStudyForm'>
      <Item name='username' label='User name'>
        <Input />
      </Item>
      <Item name='password' label='Password'>
        <Input />
      </Item>
      <Item name='host' label='Host'>
        <Input />
      </Item>
      <Item name='environment' label='Environment'>
        <Input />
      </Item>
      <Item
        name='subjectPath'
        label='Subject Path'
        initialValue={ENDPOINT_DEFAULTS.subjectPath}
      >
        <Input />
      </Item>
      <Item
        name='usersPath'
        label='User Path'
        initialValue={ENDPOINT_DEFAULTS.usersPath}
        >
        <Input />
      </Item>
    </Input.Group>
  );
};

export const AccessItemsP1 = props => {

  const { data } = props;
  const { Item } = Form;

  if(data.edcType !== 'p1edc') {
    return null;
  }

  //'apiId',
  //'apiSecret',
  //'environment',

  return (
    <Input.Group className='AdminStudyForm'>
      <Item name='apiId' label='API ID'>
        <Input />
      </Item>
      <Item name='apiSecret' label='API Secret'>
        <Input />
      </Item>
      <Item name='environment' label='Environment'>
        <Input />
      </Item>
    </Input.Group>
  );
};

export const ListItem = props => {

  const { name, label } = props;
  const { Item } = Form;


  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 18 },
    },
  };

  const formItemLayoutWithOutLabel = {
    wrapperCol: {
      xs: { span: 24, offset: 0 },
      sm: { span: 18, offset: 6 },
    },
  };

  return (
  <div className='AdminStudyForm'>
    <Form.List name={name}>
      {(fields, {add, remove}, { errors }) => (
        <>
          {fields.map( (field, index) => (
            <Item 
              label={index === 0 ? label : ''}
              key={field.key}
              {...(index === 0 ? formItemLayout
                               : formItemLayoutWithOutLabel)} >
              <Item {...field} noStyle >
                <Input style={{ width: '80%', }} />
              </Item>
                <MinusCircleOutlined
                  className='dynamic-delete-button'
                  onClick={() => remove(field.name)}
                />
            </Item>
          ))}

          <Item
              label={fields.length === 0 ? label : ''}
              {...(fields.length === 0 ? formItemLayout
                               : formItemLayoutWithOutLabel)} >
            <Button
              type='dashed'
              onClick={() => add()}
              style={{ width: '80%' }}
              icon={<PlusOutlined />}
            >
              Add field
            </Button>
            <Form.ErrorList errors={errors} />
          </Item>

        </>
      )}
    </Form.List>
  </div>
  );

};

export const DatakeyArnListItem = props => {

  const { name, label, placeholder } = props;
  const { Item } = Form;


  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 3 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 21 },
    },
  };

  const formItemLayoutWithOutLabel = {
    wrapperCol: {
      xs: { span: 24, offset: 0 },
      sm: { span: 21, offset: 3 },
    },
  };

  return (
  <div className='DatakeyArnListItem'>
    <Form.List name={name}>
      {(fields, {add, remove}, { errors }) => (

        <>
          {fields.map( (field, index) => (
            <Item
              label={index === 0 ? label : ''}
              key={field.key}
              {...(index === 0 ? formItemLayout
                               : formItemLayoutWithOutLabel)} >
              <Item {...field} noStyle >
                <Input style={{ width: '94%', }} 
                       placeholder={placeholder}
                />
              </Item>
              { fields.length > 1 &&
              <MinusCircleOutlined
                className='dynamic-delete-button'
                onClick={() => remove(field.name)}
              />
              }
            </Item>
          ))}

          <Item
              label={''}
              {...formItemLayoutWithOutLabel} >
            <Button
              type='dashed'
              onClick={() => add()}
              style={{ width: '94%' }}
              icon={<PlusOutlined />}
              disabled={fields[fields?.length - 1]?.length === 0}
            >
              Add Key ARN
            </Button>
            <Form.ErrorList errors={errors} />
          </Item>

        </>
      )}
    </Form.List>
  </div>
  );

};

export const RawData = props => {

  const { data, title = 'Raw Data' } = props;
  const [ isDataVisible, setIsDataVisible ] = useState(false);

  return (
  <>
    <Button onClick={() => setIsDataVisible(!isDataVisible)}>
      {title}
    </Button>
    <pre style={{
      visibility: isDataVisible ? 'visible' : 'hidden',
      height: isDataVisible ? 'inherit' : '0px',
      backgroundColor: 'white',
      padding: isDataVisible ? '30px' : '0px',
      margin: isDataVisible ? '20px' : '0px',
      border: 'solid black 1px',
    }}>
      {JSON.stringify(data, null, 2)}
    </pre>
  </>
  );
};


