import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Divider, Form, Input, Select, Button } from 'antd';
import useFetch from '../../lib/useFetch';
import useAdmin from '../../lib/useAdmin';
import { onError } from '../../lib/onError';
import { RawData } from '../../components/AdminForms';

const config = {
  name: 'Organization',
};

const EditForm = props => {

  const { data, setData } = props;
  const { organizationId } = useParams();
  const { authenticatedFetch } = useFetch();
  const { organizationItems, setOrganizationItems } = useAdmin();

  const { Item } = Form;
  const { Option } = Select;

  const onFinish = async values => {

    const organizationItem = {
      ...data,
      name: values.name,
      type: values.type,
    };

    const response = await authenticatedFetch(
      `/api/organization/${organizationId}`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(organizationItem),

      });

    const json = await response.json();

    if(response.status !== 200) {
      onError(`Upload Error: '${json.error}'`);
      return;
    }

    const newOrganizationItems = JSON.parse(JSON.stringify(organizationItems));

    const index = newOrganizationItems.findIndex(e => 
                                  e.organizationId === organizationId);
    newOrganizationItems[index] = json;
    setData(json);
    setOrganizationItems(newOrganizationItems, true);
  };



  
  return (
    <Form
      onFinish={onFinish}
      initialValues={{

        name: data.name,
        type: data.type,
      }}
      style={{ width: '600px', }}
    >

      <Item name='name'
            label='Name'
            >
          <Input />
      </Item>

      <Item name='type' label='Type'>
        <Select style={{width: '200px'}}>
          <Option value='SITE'>Site</Option>
          <Option value='SPONSOR'>Sponsor</Option>
          <Option value='CRO'>CRO</Option>
        </Select>
      </Item>

      <Item>
        <Button type='primary' htmlType='submit'>Submit</Button>
      </Item>

    </Form>
  );
};

const ItemList = props => {

  const { organizationId } = useParams();
  const { authenticatedFetch } = useFetch();
  const [ data, setData ] = useState();

  useEffect(() => {

    const loadData = async () => {

      const response = await authenticatedFetch(
                               `/api/organization/${organizationId}`);
      const json = await response.json();
 
      setData(json);
    };

    loadData();
  }, [
    organizationId,
    authenticatedFetch,
  ]);

  if(!data) {
    return null;
  }

  return (
    <div>
      <h1>{config.name} {data.name}</h1>
      <ul>
        <li>Add domain to {data.name}</li>
        <li>Add study to {data.name}</li>
        <li>
          <Link to={`/admin/organization/${organizationId}/study`}>
            {data.name} Studies
          </Link>
        </li>
        <li>
          <Link to={`/admin/organization/${organizationId}/domain`}>
            {data.name} Domains
          </Link>
        </li>
      </ul>
      <Divider orientation='left'>Edit</Divider>

      <EditForm 
        data={data}
        setData={setData} />

      <Divider orientation='left'>Raw</Divider>
      <RawData data={data} />

    </div>
  );
};

const AdminOrganization = props => {
  return <ItemList />;
};

export { AdminOrganization };
