import React, { useState } from 'react';

// follows https://upmostly.com/tutorials/how-to-use-the-usecontext-hook-in-react

const SubjectItemContext = React.createContext([{}, () => {}]);

const SubjectItemProvider = (props) => {

  const [state, setState] = useState(null);
  return (
    <SubjectItemContext.Provider value={[state, setState]}>
      {props.children}
    </SubjectItemContext.Provider>
  );
}

export { SubjectItemContext, SubjectItemProvider };
