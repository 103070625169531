import { useContext, useCallback } from 'react';
import { StudiesContext } from './StudiesContext';


const useStudies = () => {

  const [state, setState ] = useContext(StudiesContext);

  const setStudyData = useCallback(studiesData => {

    setState({
      ...state,
      isStudiesContextLoaded: true,
      data: studiesData,
    });
  }, [ setState, state ]);

  const resetStudyData = () => {
    setState({
      isStudiesContextLoaded: false,
    });
  };

  return {
    isStudiesContextLoaded: state.isStudiesContextLoaded,
    studyData: state.data,
    setStudyData,
    resetStudyData,
  };


};

export default useStudies;
