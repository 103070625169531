import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Spin, PageHeader } from 'antd';
import { useTranslation } from 'react-i18next';
import useFetch from '../lib/useFetch';
import useSubject from '../lib/useSubject';
import { EditDocumentTask } from '../components/EditDocumentTask';
import SiteFrame from '../components/SiteFrame';
import { onError } from '../lib/onError';


import './SubjectDocumentEdit.scss';

export default function SubjectDocumentEdit ( props ) {

  const { organizationId, studyId, siteId, subjectId } = useParams();

  const { t } = useTranslation();

  const { subjectData, setSubjectData } = useSubject();
  const { authenticatedFetch, isFetching } = useFetch();

  const [ documentName, setDocumentName ] = useState(t('document-task.Edit Document'));

  useEffect(() => {
    const url =`/gui/organization/${organizationId
                             }/study/${studyId
                             }/site/${siteId
                             }/subject/${subjectId
                             }/view`;
    const loadSiteData = async () => {

      try {

        const response = await authenticatedFetch(url, {
          headers: {
            TZ: (new Intl.DateTimeFormat()).resolvedOptions().timeZone,
          },
        });

        const json = await response.json();
        setSubjectData(json);
      } catch (e) {
        console.log(e);
        onError(e);
      }
    };

    if(subjectData?.subjectItem?.subjectId !== subjectId
                                    && !isFetching(url)) {

      loadSiteData();
    }

  }, [
    organizationId,
    studyId,
    siteId,
    subjectId,
    subjectData?.subjectItem?.subjectId,
    authenticatedFetch,
    isFetching,
    setSubjectData
  ]);

  if(!subjectData) {
    return (
      <div className='SubjectDocumentEdit spin-holder'>
        <Spin className='spin'/>
      </div>
    );
  }


  return (
    <SiteFrame className='SubjectDocumentEdit' subjectId={subjectId}>
      <PageHeader
        title={documentName}
        className='page-header'
      />
      <EditDocumentTask
        subjectData={subjectData}
        setDocumentName={setDocumentName}
        />
    </SiteFrame>
  );
}
