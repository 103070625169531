import React from "react";
import { useTranslation } from 'react-i18next';
import "./NotFound.css";

export default function NotFound() {
  const { t } = useTranslation();
  return (
    <div className="NotFound">
      <h3>{t('general.Sorry, page not found!')}</h3>
    </div>
  );
}
