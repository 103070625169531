import React from 'react';
import { Route, Redirect, useLocation, useHistory } from 'react-router-dom';
import useAppUser from '../lib/useAppUser';
import SessionWatcher from './SessionWatcher';
import Auth from '../lib/Auth';

export default function AuthenticatedRoute({ children, ...rest }) {
  const { pathname, search } = useLocation();
  const history = useHistory();
  const { isAuthenticated, userHasAuthenticated  } = useAppUser();
  
  return (
    <Route {...rest}>
      {isAuthenticated ? (
        <>
          <SessionWatcher
            warningTime={60}
            expireAction={async () => {
              await Auth.signOut();
              userHasAuthenticated(false);
              history.push('/login');
            }}
          />
          {children}
        </>
      ) : (
        <Redirect to={
          `/login?redirect=${pathname}${search}`
        } />
      )}
    </Route>
  );
}
