import React, { useState } from 'react';

// AppUserContext is used to store data about the app user. Follows
// https://upmostly.com/tutorials/how-to-use-the-usecontext-hook-in-react

const AppUserContext = React.createContext([{}, () => {}]);

const AppUserProvider = (props) => {

  const [state, setState] = useState({
    isUserAuthenticated: false,
    });
  return (
    <AppUserContext.Provider value={[state, setState]}>
      {props.children}
    </AppUserContext.Provider>
  );
}

export { AppUserContext, AppUserProvider };

