import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { Divider, Form, Input, Select, Button } from 'antd';
import useAdmin from '../../lib/useAdmin';
import useFetch from '../../lib/useFetch';
import { onError } from '../../lib/onError';
import { RawData } from '../../components/AdminForms';

const config = {
  name: 'Domain',
};


const AddForm = props => {

  const { organizationId } = useParams();
  const { authenticatedFetch } = useFetch();
  const { domainItems, setDomainItems } = useAdmin();

  const { Item } = Form;
  const { Option } = Select;

  const onFinish = async values => {

    const domainItem = {
      organizationId,
      domain: values.domain,
      authenticationType: values.authenticationType,
    };

    const response = await authenticatedFetch(
      `/api/organization/${organizationId}/domain`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(domainItem),

      });

    const json = await response.json();

    if(response.status !== 201) {
      onError(`Upload Error: '${json.error}'`);
      return;
    }

    const newDomainItems = JSON.parse(JSON.stringify(domainItems));

    newDomainItems.unshift(json);
    setDomainItems(newDomainItems);

  };



  
  return (
    <Form
      onFinish={onFinish}
      initialValues={{
        authenticationType: 'totp',
      }}
      style={{ width: '600px', }}
    >

      <Item name='domain'
            label='Domain'
            >
          <Input placeholder='example.com' />
      </Item>

      <Item name='authenticationType' label='Type'>
        <Select style={{width: '200px'}}>
          <Option value='totp'>TOTP</Option>
          <Option value='password'>Password</Option>
        </Select>
      </Item>

      <Item>
        <Button type='primary' htmlType='submit'>Submit</Button>
      </Item>

    </Form>
  );
};

const ItemList = props => {

  const { organizationId } = useParams();
  const { organizationItems, domainItems } = useAdmin();

  if(!domainItems) {
    return null;
  }

  const organizationName = organizationItems
                             .find(e => e.organizationId === organizationId)
                             ?.name;

  return (
    <div>
      <h1>{organizationName} {config.name} List</h1>
      <ul>
        {
          domainItems.map(r => (
            <li key={r.domainId}>
              <Link to={`/admin/organization/${organizationId}/domain/${r.domainId}`}
              >
                {r.domain}
              </Link>
            </li>
          ))
        }
      </ul>
      <Divider orientation='left'>New {organizationName} Domain</Divider>
      <AddForm />
      <Divider orientation='left'>Raw</Divider>
      <RawData data={domainItems} />
    </div>
  );
};

const AdminDomainList = props => {
  return <ItemList />;
};

export { AdminDomainList };
