import React, { useState }  from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Layout, Menu } from 'antd';
import { authenticator as authenticatorOTP } from 'otplib';

import useAppUser from '../lib/useAppUser';
import Auth from '../lib/Auth';
import { onError } from '../lib/onError';

// two following lines to support dev site role
import usePreferences from '../lib/usePreferences';
import useSite from '../lib/useSite';

import './Header.scss';
import 'antd/dist/antd.css';

const { Header } = Layout;
const { SubMenu } = Menu;

const SuHeader = (props) => {

  const { t } = useTranslation();

  return (
    <Header className='Header' key='header'>
      <div className='navigation'>
        <div className='logo'>
          <Link to='/'>
            <img className='HeaderLogo'
                 src='/SU-logo-with-lettering-426-transparent.png'
                 alt={t('product-name')} 
             />
          </Link>
        </div>
        <TopNavbar theme='light' {...props} />
      </div>
    </Header>
  );
};

const TopNavbar = (props) => {

  const {
    isAuthenticated,
    userHasAuthenticated,
    setSessionData,
    isAdminRole,
  } = useAppUser();
  const history = useHistory();
  const { siteData } = useSite();
  const location = useLocation();

  const handleAutoLogin = async () => {

    const password = process.env.REACT_APP_AUTO_AUTHENTICATE_TYPE === 'totp'
      ? authenticatorOTP.generate(process.env.REACT_APP_DEVELOPER_SECRET)
      : process.env.REACT_APP_DEVELOPER_SECRET ;
    try {
      await Auth.setEmail(
        process.env.REACT_APP_DEVELOPER_EMAIL
      );
      const sessionData = await Auth.signIn(
        process.env.REACT_APP_DEVELOPER_EMAIL,
        password,
      );

      setSessionData(sessionData);
      userHasAuthenticated(true);
      const q = location.search.substring(1);
      const r = q.split('&').filter(i => i.startsWith('redirect='))
                  .map(i => i.substring(9))
                  .pop();
      // robust solution would use decodeURIComponent and stuff.            
      if(sessionData.training.length > 0) {
        history.push(!r || r.startsWith('/training')
                       ? '/training'
                       : `/training?redirect=${r}`);
        return;
      }

      // problem: re-render goes to AuthenticatedRoute, gives white page
      // solution: history.push() here

      history.push(r && !r.startsWith('/training') ? r : '/study');
      return;
    } catch (e) {
      onError(e);
    }
  };


  let authenticatedLinks = [

    isAdminRole ? <AdminMenu key='AdminMenu' { ...props }/> : null,
    <UserMenu key='UserMenu' { ...props }/>,
  ];


  if(process.env.REACT_APP_DEVELOPER_ROLE_SELECTOR
    && process.env.REACT_APP_DEVELOPER_ROLE_SELECTOR === 'true'
    && siteData
    ) {
    
    // no i18n for Site Role
    authenticatedLinks.unshift(
      <SubMenu key='dev-site-role' title='Dev Site Role' {...props}>
        <SiteRoleSelector key='site-role-selector'/>
      </SubMenu>
      );
  }

  let unauthenticatedLinks = [

  ];
  if(process.env.REACT_APP_AUTO_AUTHENTICATE
     && process.env.REACT_APP_AUTO_AUTHENTICATE === 'true') {

    // no i18n for Auto-Login
    unauthenticatedLinks.unshift(
      <Menu.Item key='auto-login' onClick={handleAutoLogin}>Auto-Login</Menu.Item>
    );
  }

  return (
    <Menu theme='light' mode='horizontal' {...props}>
      { isAuthenticated ? authenticatedLinks : unauthenticatedLinks }
    </Menu>
  
  );
};

const UserMenu = props => {

  const { t } = useTranslation();
  const { sessionData, userHasAuthenticated } = useAppUser();
  const history = useHistory();

  const handleLogout = async () => {
    await Auth.signOut();
    userHasAuthenticated(false);
    history.push('/login');
  };

  return (
    <SubMenu title={sessionData.name} key='UserMenu' {...props}>

      <Menu.Item key='profile'>
        <Link to='/profile'>
          {t('navigation.profile')}
        </Link>
      </Menu.Item>
      <Menu.Item key='logout' onClick={handleLogout}>{t('navigation.logout')}</Menu.Item>

    </SubMenu>
    );

};

const AdminMenu = props => {

  //const { t } = useTranslation();
  const { isAdminRole } = useAppUser();
  if( !isAdminRole) {
    return null;
  }

  return (
    <SubMenu title='Admin' {...props}>

      <Menu.Item key='organization'>
        <Link to='/admin/organization'>
          Organizations
        </Link>
      </Menu.Item>

      <Menu.Item key='user'>
        <Link to='/admin/user'>
          App Users
        </Link>
      </Menu.Item>

      <Menu.Item key='datakey'>
        <Link to='/admin/datakey'>
          Data Keys
        </Link>
      </Menu.Item>

    </SubMenu>
    );

};

const SiteRoleSelector = props => {

  // no i18n for SiteRoleSelector
  const { setPreference, getPreference } = usePreferences();
  const { siteData, setSiteData } = useSite();
  const siteRolePreference = getPreference('dev-site-role');
  const [ selectedKeyState, setSelectedKeyState ] = useState( [
    siteRolePreference ? `role-${siteRolePreference}`
                       :'role-default'
    ]);

  if(!siteData) {
    onError('SiteRoleSelector has no siteData, try again later');
    return;
  }

  const setDevSiteRole = roleName => {
    setSiteData({
          ...siteData,
          edcUsers: [{
            ...siteData.edcUsers[0],
            sites: [{
              ...siteData.edcUsers[0].sites[0],
              role: roleName,

            }]
          }]
      });
    setPreference('dev-site-role', roleName);
  };

  const onSelect = ({ item, key, keyPath, selectedKeys, domEvent }) => {

    const oldSelectedRole = selectedKeys.filter(k => k.startsWith('role-'))
                                        .find(k => k !== key);
    const wasFakeIdSelected = selectedKeys.includes('is-fake-id');                                    
    const isKeyRole = key.startsWith('role-');

    let keys = [ key ];
    if(isKeyRole && wasFakeIdSelected) {
      keys.push('is-fake-id');
    }
    if(!isKeyRole) {
      keys.push(oldSelectedRole);
    }

    switch(key) {
      case 'role-site':
          setDevSiteRole('site')
        break;
      case 'role-monitor':
          setDevSiteRole('monitor')
        break;
      case 'role-auditor':
          setDevSiteRole('auditor')
        break;
      case 'role-default':
          setDevSiteRole('default')
        break;
      default:
    }

    if(key === 'is-fake-id') {
      setPreference('dev-is-fake-id', true);
    }
    setSelectedKeyState(keys);
  };

  const onDeselect = ({ item, key, keyPath, selectedKeys, domEvent }) => {

    if(key !== 'is-fake-id') {
      return;
    }

    const oldSelectedRole = selectedKeys.filter(k => k.startsWith('role-'))
                                        .find(k => k !== key);
    setSelectedKeyState([ oldSelectedRole ]);
    setPreference('dev-is-fake-id', false);

  };

  return (
    <Menu
      multiple={true}
      theme='light'
      selectedKeys={selectedKeyState}
      onSelect={onSelect}
      onDeselect={onDeselect}
      >

      <Menu.Item key='role-default'>Default</Menu.Item>
      <Menu.Item key='role-site'>Site User</Menu.Item>
      <Menu.Item key='role-monitor'>Monitor</Menu.Item>
      <Menu.Item key='role-auditor'>Auditor</Menu.Item>
      <Menu.Divider />  
      <Menu.Item key='is-fake-id'>Other Site User</Menu.Item>

    </Menu>    

  );

};

export default SuHeader;
