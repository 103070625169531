import React from 'react';
import { Link } from 'react-router-dom';
import { Divider } from 'antd';
import useAdmin from '../../lib/useAdmin';
import { RawData } from '../../components/AdminForms';

const config = {
  name: 'Study User',
};

const ItemList = props => {

  const { studyUserItems } = useAdmin();

  if(!studyUserItems) {
    return null;
  }


  return (
    <div>
      <h1>{config.name} List</h1>
      <ul>
      {
        studyUserItems.map(r => (
          <li key={r.email}>
            <Link to={`/admin/user/${r.email}`}
            >
              Check {r.email} SU User Account
            </Link>
          </li>

        ))
      }
      </ul>
      <Divider orientation='left'>Raw</Divider>
      <RawData data={studyUserItems} />
    </div>
  );
};

const AdminStudyUser = props => {
  return <ItemList />;
};

export { AdminStudyUser };
