import { useContext, useCallback, useMemo } from 'react';
import { AdminContext } from './AdminContext';

const useAdmin = () => {

  const [state, setState ] = useContext(AdminContext);

  const isArrayEquals = (idField, a, b) => {

    if((a && !b) || (!a && b)) { return false; }
    if(!a && !b) { return true; }
    if(a.length !== b.length) { return false; }

    const bIds = b.map(r => r[idField]);
    return a.every(r => bIds.includes(r[idField]));

  };

  const setOrganizationItems = useCallback(
    (organizationItems, force = false) => {

    if(!force && isArrayEquals('organizationId',
                     organizationItems, state.organizationItems)) {
      return;
    }

    setState({
      ...state,
      organizationItems,
      domainItems: undefined,
      datakeyItems: undefined,
      studyItems: undefined,
      siteItems: undefined,
      studyUserItems: undefined,
    });

  }, [ state, setState ]);

  const setOrganizationId = useCallback((organizationId) => {

    if(organizationId === state.organizationId) {
      return;
    }

    setState({
      ...state,
      organizationId,
      domainItems: undefined,
      datakeyItems: undefined,
      studyItems: undefined,
      siteItems: undefined,
      studyUserItems: undefined,
    });
  }, [ state, setState ]);

  const setDomainItems = useCallback(domainItems => {

    if(isArrayEquals('domainId',
                     domainItems, state.domainItems)) {
      return;
    }

    setState({
      ...state,
      domainItems,
    });

  }, [ state, setState ]);

  const setDatakeyItems = useCallback(datakeyItems => {

    if(isArrayEquals('datakeyId',
                     datakeyItems, state.datakeyItems)) {
      return;
    }

    setState({
      ...state,
      datakeyItems,
    });

  }, [ state , setState ]);

  const resetDatakeyItems = useCallback(() => {

    setState({
      ...state,
      datakeyItems: undefined,
    });

  }, [ state, setState ]);

  const setStudyItems = useCallback(studyItems => {

    if(isArrayEquals('studyId',
                     studyItems, state.studyItems)) {
      return;
    }

    setState({
      ...state,
      studyItems,
      siteItems: undefined,
      studyUserItems: undefined,
    });

  }, [ state, setState ]);

  const resetStudyItems = useCallback(() => {
    setState({
      ...state,
      studyItems: undefined,
      siteItems: undefined,
      studyUserItems: undefined,
    });

  }, [ state, setState ]);

  const setSiteItems = useCallback(siteItems => {

    if(isArrayEquals('siteId',
                     siteItems, state.siteItems)) {
      return;
    }

    setState({
      ...state,
      siteItems,
    });

  }, [ state, setState ]);

  const setStudyUserItems = useCallback(studyUserItems => {

    if(isArrayEquals('userId',
                     studyUserItems, state.studyUserItems)) {
      return;
    }

    setState({
      ...state,
      studyUserItems,
    });

  }, [ state, setState ]);

  const organizationItems = useMemo( () => state.organizationItems
                         ? [ ...state.organizationItems ]
                         : null, [ state.organizationItems ]);

  const organizationId = useMemo( () => state.organizationId
                         ? [ ...state.organizationId ]
                         : null, [ state.organizationId ]);

  const domainItems = useMemo( () => state.domainItems
                         ? [ ...state.domainItems ]
                         : null, [ state.domainItems ]);

  const datakeyItems = useMemo( () => state.datakeyItems
                         ? [ ...state.datakeyItems ]
                         : null, [ state.datakeyItems ]);

  const studyItems = useMemo( () => state.studyItems
                         ? [ ...state.studyItems ]
                         : null, [ state.studyItems ]);

  const siteItems = useMemo( () => state.siteItems
                         ? [ ...state.siteItems ]
                         : null, [ state.siteItems ]);

  const studyUserItems = useMemo( () => state.studyUserItems
                         ? [ ...state.studyUserItems ]
                         : null, [ state.studyUserItems ]);

  return {

    setOrganizationItems,
    organizationItems,
    setOrganizationId,
    organizationId,

    setDomainItems,
    domainItems,

    setDatakeyItems,
    resetDatakeyItems,
    datakeyItems,

    setStudyItems,
    resetStudyItems,
    studyItems,

    setSiteItems,
    siteItems,

    setStudyUserItems,
    studyUserItems,

  };


};

export default useAdmin;
