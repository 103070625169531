import { useContext, useCallback } from 'react';
import { SubjectContext } from './SubjectContext';

const useSubject = () => {

  const [state, setState ] = useContext(SubjectContext);

  const setSubjectData = useCallback(subjectData => {

    setState({
      ...state,
      item: subjectData,
      isLoaded: true,
    });

  }, [ setState, state ]);

  return {
    subjectData: state.item,
    setSubjectData,
    isSubjectItemLoaded: state.isLoaded? true: false,
    currentSubjectId: state.item ? state.item.subjectId : null, 
  };


};

export default useSubject;
