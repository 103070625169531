import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PSPDFKit from 'pspdfkit';
import {
  Input,
  Checkbox,
  Form,
  Spin,
  Descriptions,
  Radio,
  Modal,
  Button,
  Space,
  Popover,
  } from 'antd';
import useSiteFetch from '../lib/useSiteFetch';
import useDocument from '../lib/useDocument';
import { onError } from '../lib/onError';
import SiteFrame from '../components/SiteFrame';
import { WritableDocumentItemView } from '../components/DocumentItemView';
import { SiteUserSignatureForm } from '../components/SiteUserSignatureForm';
import 'antd/dist/antd.css';
import './DocumentReview.scss';
import SparkMD5 from "spark-md5";
import { v4 as uuidv4 } from 'uuid';

const chunkSize = 512 * 1024; // 256 kiB

const RemovePanel = props => {

  const {
    onReject,
    isVisible,
    setIsModalOkButtonEnabled,
    prepareSubmission,
  } = props;

  const { t } = useTranslation();
  const [ reasonsForm ] = Form.useForm();
  const [ isReasonsValid, setIsReasonsValid ] = useState(false)
  const [ isDetailPresent, setIsDetailPresent ] = useState(false);

  const { TextArea } = Input;


  const signatureReasons = [
    t('document-review.This document is not suitable for remote monitoring'),
  ];

  if(!isVisible) {
    return null;
  }


  const onPasswordComplete = async presentSignatureMetadata => {

    await reasonsForm.validateFields();

    if(
      presentSignatureMetadata.password.length !== 6
      || !presentSignatureMetadata.clientDate
      || !presentSignatureMetadata.name
      || !presentSignatureMetadata.email
      || presentSignatureMetadata.reasons.length < 1

      || !isValidReasonForm()
      ) {
      return;
    }

    const rejectionReason = {
      reason: reasonsForm.getFieldValue('reason'),
      detail: reasonsForm.getFieldValue('detail')
              ? reasonsForm.getFieldValue('detail') : '',
      isDiscardDocument: true,
    };

    prepareSubmission({
      function: onReject,
      args: [
        presentSignatureMetadata,
        rejectionReason,
      ],
    });
    setIsModalOkButtonEnabled(true);

  };

  const isValidReasonForm = () => {

    const detail = reasonsForm.getFieldValue('detail');

    return (reasonsForm.getFieldValue('reason')
           && reasonsForm.getFieldValue('reason').length > 0)
         || ( typeof detail !== 'undefined' && detail.length > 0) ;


  };


  return (
    <div className='RemovePanel'>
      <h3>{t('document-review.Action Details')}</h3>
      <Form
        form={reasonsForm}
        onFieldsChange={(c, a) => {
          setIsReasonsValid(isValidReasonForm() );
          }}
        className='RemovePanelForm'
      >
        <Form.Item
          name='reason'
          rules={[
            {
              required: !isDetailPresent,
              message: t('document-review.Choose one or more reasons, or write some detail'),
            },
          ]}
        >
          <Checkbox.Group>
            <p>
              <Checkbox value='wrong-redaction'>
                {t('document-review.Wrong redaction')}
              </Checkbox>
            </p>
            <p>
              <Checkbox value='wrong-subject'>
                {t('document-review.Wrong subject')}
              </Checkbox>
            </p>
          </Checkbox.Group>
        </Form.Item>

        <Form.Item name='detail' >
          <p>

            <TextArea id='rejection-reason-detail'
               rows={1}
               placeholder={ t('document-review.Add detail to help the certifier') }
               onChange={e => {
                 setIsDetailPresent(e?.target?.value.length > 0);
               }}
            />
          </p>          
        </Form.Item>

      </Form>
      <h3>{t('document-review.Signature')}</h3>
      <SiteUserSignatureForm 
        onPasswordComplete={ onPasswordComplete }
        onPasswordIncomplete={() => setIsModalOkButtonEnabled(false)}
        isPasswordDisabled={ !isReasonsValid }
        signatureReasons={ signatureReasons }
      />
    </div>  
  );
};

const RejectPanel = props => {

  const {
    onReject,
    isVisible,
    setIsModalOkButtonEnabled,
    prepareSubmission,
  } = props;

  const { t } = useTranslation();
  const [ reasonsForm ] = Form.useForm();
  const [ isReasonsValid, setIsReasonsValid ] = useState(false)
  const [ isDetailPresent, setIsDetailPresent ] = useState(false);

  const { TextArea } = Input;


  const signatureReasons = [
    t('document-review.This document is not suitable for remote monitoring'),
  ];

  if(!isVisible) {
    return null;
  }


  const onPasswordComplete = async presentSignatureMetadata => {

    await reasonsForm.validateFields();

    if(
      presentSignatureMetadata.password.length !== 6
      || !presentSignatureMetadata.clientDate
      || !presentSignatureMetadata.name
      || !presentSignatureMetadata.email
      || presentSignatureMetadata.reasons.length < 1

      || !isValidReasonForm()
      ) {
      return;
    }

    const rejectionReason = {
      reason: reasonsForm.getFieldValue('reason'),
      detail: reasonsForm.getFieldValue('detail')
              ? reasonsForm.getFieldValue('detail') : '',
      isDiscardDocument: false,
    };

    prepareSubmission({
      function: onReject,
      args: [
        presentSignatureMetadata,
        rejectionReason,
      ],
    });
    setIsModalOkButtonEnabled(true);

  };

  const isValidReasonForm = () => {

    const detail = reasonsForm.getFieldValue('detail');

    return (reasonsForm.getFieldValue('reason')
           && reasonsForm.getFieldValue('reason').length > 0)
         || ( typeof detail !== 'undefined' && detail.length > 0) ;


  };


  return (
    <div className='RejectPanel'>
      <h3>{t('document-review.Action Details')}</h3>
      <Form
        form={reasonsForm}
        onFieldsChange={(c, a) => {
          setIsReasonsValid(isValidReasonForm() );
          }}
        className='RejectPanelForm'
      >
        <Form.Item
          name='reason'
          rules={[
            {
              required: !isDetailPresent,
              message: t('document-review.Choose one or more reasons, or write some detail'),
            },
          ]}
        >
          <Checkbox.Group>
            <p>
              <Checkbox value='missing-redaction'>
                {t('document-review.Missing redaction')}
              </Checkbox>
            </p>
            <p>
              <Checkbox value='missing-visit'>
                {t('document-review.Missing visit')}
              </Checkbox>
            </p>
            <p>
              <Checkbox value='wrong-visit'>
                {t('document-review.Wrong visit')}
              </Checkbox>
            </p>
            <p>
              <Checkbox value='missing-log'>
                {t('document-review.Missing log')}
              </Checkbox>
            </p>
            <p>
              <Checkbox value='wrong-log'>
                {t('document-review.Wrong log')}
              </Checkbox>
            </p>
          </Checkbox.Group>
        </Form.Item>

        <Form.Item name='detail' >
          <p>

            <TextArea id='rejection-reason-detail'
               rows={1}
               placeholder={ t('document-review.Add detail to help the certifier') }
               onChange={e => {
                 setIsDetailPresent(e?.target?.value.length > 0);
               }}
            />
          </p>          
        </Form.Item>

      </Form>
      <h3>{t('document-review.Signature')}</h3>
      <SiteUserSignatureForm 
        onPasswordComplete={ onPasswordComplete }
        onPasswordIncomplete={() => setIsModalOkButtonEnabled(false)}
        isPasswordDisabled={ !isReasonsValid }
        signatureReasons={ signatureReasons }
      />
    </div>  
  );
};

const ApprovePanel = props => {

  const {

    reasons,
    onApprove,
    isVisible,

    setIsModalOkButtonEnabled,
    prepareSubmission,


  } = props;
  const { t } = useTranslation();

  if(!isVisible) {
    return null;
  }

  const onPasswordComplete = presentSignatureMetadata => {
  
    if(
      presentSignatureMetadata.password.length !== 6
      || !presentSignatureMetadata.clientDate
      || !presentSignatureMetadata.name
      || !presentSignatureMetadata.email
      || presentSignatureMetadata.reasons.length < 1

      ) {
      return;
    }

    prepareSubmission({
      function: onApprove,
      args: [
        presentSignatureMetadata,
      ],
    });
    setIsModalOkButtonEnabled(true);

  };

  return (
    <div className='Sign'>
      <h3>{t('document-review.Signature')}</h3>
      <SiteUserSignatureForm 
        onPasswordComplete={ onPasswordComplete }
        onPasswordIncomplete={() => setIsModalOkButtonEnabled(false)}
        isPasswordDisabled={ false }
        signatureReasons={ reasons }
      />
    </div>      
  );

};

const SignaturePanel = props => {

  const {
          approvalReasons,
          onApprove,
          onReject,
          setIsModalOkButtonEnabled,
          setPreparedSubmission,
          setIsDiscardDocument,
        } = props;

  const [ isApproveVisible, setIsApproveVisible ] = useState(false);      
  const [ isRejectVisible, setIsRejectVisible ] = useState(false);      
  const [ isRemoveVisible, setIsRemoveVisible ] = useState(false);      

  const { t } = useTranslation();

  return (
    <div className='SignaturePanel'>

      <h3>{t('document-review.Document Action')}</h3>

      <Radio.Group
        className='SignaturePanelChooser'
        options={[
          {label: 'Approve', value: 'Approve'},
          {label: 'Reject', value: 'Reject'},
          {label: 'Remove', value: 'Remove'},
        ]}

        onChange={e => {

          if(e.target.value === 'Approve') {
            setIsDiscardDocument(false);
            setIsRejectVisible(false);
            setIsRemoveVisible(false);
            setIsApproveVisible(true);
          }

          if(e.target.value === 'Reject') {
            setIsDiscardDocument(false);
            setIsApproveVisible(false);
            setIsRemoveVisible(false);
            setIsRejectVisible(true);
          }

          if(e.target.value === 'Remove') {
            setIsDiscardDocument(true);
            setIsApproveVisible(false);
            setIsRejectVisible(false);
            setIsRemoveVisible(true);
          }
        }}
      >
      </Radio.Group>


      <ApprovePanel
        {...{
          reasons: approvalReasons,
          onApprove,
          setIsModalOkButtonEnabled,
          prepareSubmission: setPreparedSubmission,
          isVisible: isApproveVisible,
        }}
      />

      <RejectPanel
        {...{
          onReject,
          setIsModalOkButtonEnabled,
          prepareSubmission: setPreparedSubmission,
          isVisible: isRejectVisible,
        }}
      />

      <RemovePanel
        {...{
          onReject,
          setIsModalOkButtonEnabled,
          prepareSubmission: setPreparedSubmission,
          isVisible: isRemoveVisible,
        }}
      />

    </div>
  );

};

const ModalSignaturePanel = props => {

  const {
    title,
    okText,
    isVisible,
    setIsVisible,

    onReject,
    onApprove,
    approvalReasons,

    setIsDiscardDocument,

  } = props;


  const [ isOkButtonEnabled, setIsOkButtonEnabled ] = useState( false );
  const [ preparedSubmission, setPreparedSubmission ] = useState(null);      
  const [ isWaiting, setIsWaiting ] = useState(false);

  const okButtonRef = useRef();

  const onOk = async () => {

    if(!preparedSubmission) {
      throw new Error('There is no prepared submission');
    }
    setIsOkButtonEnabled(false);
    await preparedSubmission.function(
      ...preparedSubmission.args,
      setIsWaiting,
      );

  };

  useEffect(() => {

    if(!preparedSubmission || !isOkButtonEnabled) {
      return;
    }
    okButtonRef.current.focus();
  }, [ preparedSubmission, isOkButtonEnabled ]);


  return (
    <Modal
      visible={isVisible}
      title={title}
      onOk={onOk}
      onCancel={() => {
        setIsVisible(false);
        setIsDiscardDocument(false);
      }}
      width={800}
      okText={okText}
      okButtonProps={{
        disabled: !isOkButtonEnabled,
        ref: okButtonRef,
      }}
      destroyOnClose={true}
      >
      <Spin spinning={isWaiting}>
        <SignaturePanel {...{
          approvalReasons,
          onApprove,
          onReject,
          setIsModalOkButtonEnabled: setIsOkButtonEnabled,
          setPreparedSubmission,
          setIsDiscardDocument,
        }} />
      </Spin>
    </Modal>
  );

};

const ApprovalRevocationNotes = props => {

  const { documentItem } = props;
  const { t } = useTranslation();

  if(!documentItem?.reviewData?.rejectionReason?.reason
     || !documentItem.reviewData.rejectionReason.reason.includes('approval-revocation') )
  {
    return null;
  }

  return (
    <div className='ApprovalRevocationNotes'>
      <p>
        <Descriptions column={1}>
          <Descriptions.Item 
            key='approvalRevoker'
            label={t('document-review.Approval Revoked By')}>
              {documentItem.reviewData?.approvalRevoker || t('document-review.Unknown')}
          </Descriptions.Item>
          <Descriptions.Item 
            key='reason'
            label={t('document-review.Reasons')}>
            <ul>
             {
               documentItem.reviewData?.rejectionReason?.reason.map(
                 r => <li>{r}</li>
               )
             }
            </ul> 
          </Descriptions.Item>
          <Descriptions.Item 
            key='detail'
            label={t('document-review.Detail')}>
              {documentItem.reviewData.rejectionReason?.detail || t('document-review.Unknown')}
          </Descriptions.Item>
        </Descriptions>
      </p>
    </div>
  );
};

export default function DocumentReview ( props ) {

  const { organizationId, studyId, siteId, subjectId, documentId, version } = useParams();

  const { t } = useTranslation();
  const history = useHistory();

  const { siteFetch } = useSiteFetch();

  const { documentItem, isDocumentItemLoaded } = useDocument();

  const annotationIds = useRef([]);

  const [ displayDocument, setDisplayDocument ] = useState(null);
  const [ isDiscardDocument, setIsDiscardDocument ] = useState(false);
  const [ pspdfkitInstance, setPspdfkitInstance ] = useState(null);
  const [ isLoading, setIsLoading ] = useState( false );
  const [ isWaiting, setIsWaiting ] = useState( false );

  const [ isModalDialogVisible, setIsModalDialogVisible ] = useState( false);


  const approvalReasons = [
    t('document-review.I have performed a review of the entire document ensuring that all identifying information has been redacted and is no longer readable'),
  ];

// chunking from https://github.com/y3n3rrr/LargeFileUpload/blob/master/src/App.js
const counter = useRef(0);
const [fileToBeUpload, setFileToBeUpload] = useState({});
const beginningOfTheChunk = useRef(0);
const endOfTheChunk = useRef(chunkSize);
const [progress, setProgress] = useState(0);
const [fileGuid, setFileGuid] = useState(0);
const fileSize = useRef(0);
const [chunkCount, setChunkCount] = useState(0);
const [presentSignatureMetadata, setPresentSignatureMetadata] = useState();
const [rejectionReason, setRejectionReason ] = useState();
const [documentItemFormData, setDocumentItemFormData ] = useState();

const resetChunkProperties = useCallback( (e) => {
  counter.current = 0;
  beginningOfTheChunk.current = 0
  endOfTheChunk.current = chunkSize
},[])

const getFileContext = useCallback( (e) => {

  resetChunkProperties();
  const _file = e.blob;
  const _totalCount = _file.size % chunkSize === 0 ? _file.size / chunkSize : Math.floor(_file.size / chunkSize) + 1; // Total count of chunks will have been upload to finish the file
  setChunkCount(_totalCount)
  const _fileID = uuidv4() + '-' + documentId;
  setFileGuid(_fileID);
  fileSize.current = _file.size;
  setFileToBeUpload(_file);

},[documentId, resetChunkProperties])


const uploadCompleted = useCallback(() => {
  const upload = async () => {
    setIsWaiting(true);
    const formData = new FormData();
    const hash = '';
    const blob = new Blob([], { type: "application/pdf" });

    formData.append('uploadSource', blob, documentItem.originalFileMetadata.name);
    formData.append('fileName', fileGuid);
    formData.append('documentItem', JSON.stringify({
      ...documentItem,
      hash,
      reviewData: {
        ...documentItem.reviewData,
        status: rejectionReason.isDiscardDocument
                ? 'discarded' : 'rejected',
        rejectionReason,
      },

      signatureMetadata: [
        ...documentItem.signatureMetadata,
        presentSignatureMetadata,
      ],
    }));

    try {

      const url = `/gui/organization/${organizationId}/study/${studyId}/site/${siteId}/subject/${subjectId}/document/${documentId}/version/${documentItem.version}`;

      const response = await siteFetch(url, {

        method: 'PATCH',
        headers: {
          TZ: (new Intl.DateTimeFormat()).resolvedOptions().timeZone,
        },
        body: formData,

      });
      setIsWaiting(false);

      if( response.status === 200 ) {
        history.push(`/organization/${organizationId}/study/${studyId}/site/${siteId}/document`);
      } else {

        const json = await response.json();
        
        const msg = `${t('api.Upload Error')}: ${response.status} ${response.statusText}: ${json.error ? json.error : ''}`;
        console.log(msg);
        onError(msg + (response.status === 401 ? ': ' + t('api.try again'): ''));

      }

    } catch (e) {
      setIsWaiting(false);
      console.log({ e });
      onError(e);
    }      
  }
  upload();
},[    
  fileGuid,
  organizationId,
  siteId,
  studyId,
  subjectId,
  t,
  history,
  documentId,
  documentItem,
  rejectionReason,
  presentSignatureMetadata,
  siteFetch,
]);

const uploadChunk = useCallback( async (chunk) => {

  if(fileGuid === 0) return;
  setIsWaiting(true);
  try {
    const formData = new FormData();
    const blob = new Blob([chunk], { type: 'application/pdf' });
    formData.append('uploadSource', blob, documentId);        
    formData.append('flowChunkNumber', counter.current);
    formData.append('flowChunkSize', chunkSize);
    formData.append('flowTotalSize', fileSize.current);
    formData.append('flowIdentifier', fileGuid);
    formData.append('flowFilename', documentId);
    formData.append('singleChunk', chunkCount === 1 ? true : false);
    formData.append('flowTotalChunks', chunkCount);
    
    for (const entry of documentItemFormData.entries()) {
      if (entry[0] !== "documentItem") {
        continue
      }

        const nativeValue = JSON.parse(entry[1]);
        nativeValue.signatureMetadata[0] = nativeValue.signatureMetadata[nativeValue.signatureMetadata.length - 1];
        formData.append(entry[0], JSON.stringify(nativeValue));
    }
      const response = await siteFetch(`/gui/organization/${organizationId}/study/${studyId}/site/${siteId}/subject/${subjectId}/document/chunk`, {
      method: 'POST',
      headers: {
        TZ: (new Intl.DateTimeFormat()).resolvedOptions().timeZone
      },
      body: formData, // body
    });

    const data = await response.json();
    if (data.isSuccess) {
      beginningOfTheChunk.current = endOfTheChunk.current;
      endOfTheChunk.current = endOfTheChunk.current + chunkSize;
      if (counter.current === chunkCount) {
        uploadCompleted();      
      } else {
        let percentage = (counter.current / chunkCount) * 100;
        setProgress(percentage);
      }
    } else {
      
      setIsWaiting(false);
      setIsLoading(false);
      
      if(response.status === 401
        && (data.error === 'Session expired'
          || data.error === 'Session lost'
          )
        ) {
        onError(`${t('api.401 Unauthorized')}: '${t('api.' + data.error)}'`);
        return;
      }

      const msg = `${t('api.Upload Error')}: ${response.status} ${response.statusText}: ${data.error ? data.error : ''}`;
      onError(msg + (response.status === 401 ? `: ${t('api.try again')}`: ''));      
    }
  } catch (error) {
    console.log({ error });
    setIsWaiting(false);
    setIsLoading(false);
  }
},[
  counter, 
  chunkCount, 
  endOfTheChunk, 
  organizationId,
  siteId,
  studyId,
  subjectId,
  uploadCompleted,
  fileGuid,
  documentId,
  documentItemFormData,
  t,
  siteFetch
]);

useEffect(() => {
  const fileUpload = async () => {
    counter.current = counter.current + 1;
    if (counter.current <= chunkCount) {
      let chunk = fileToBeUpload.slice(beginningOfTheChunk.current, endOfTheChunk.current);
      await uploadChunk(chunk)
    }
  };
    if (fileSize.current > 0 && fileToBeUpload && fileToBeUpload.size > 0 ) {
      fileUpload();
    }
}, [
  fileToBeUpload, 
  progress, 
  counter, 
  beginningOfTheChunk, 
  chunkCount, 
  endOfTheChunk, 
  uploadChunk
])

// end chunking

  useEffect(() => {

    const onLoad = async () => {

      setIsLoading(true);

      const url = `/gui/organization/${organizationId
                             }/study/${studyId
                              }/site/${siteId
                           }/subject/${subjectId
                          }/document/${documentId
                           }/version/${version
                           }/download`
      const response = await siteFetch(url, {
        headers: {
          TZ: (new Intl.DateTimeFormat()).resolvedOptions().timeZone,
        },
      });

      setIsLoading(false);

      if(response.status !== 200) {
        const json = await response.json();
        onError(`${response.status} ${response.statusText}: '${json.error}'`);
        return;
      }
      
      
      const buffer = await response.arrayBuffer();
      setDisplayDocument(buffer);

    };

    if(!displayDocument) {
      onLoad();
    }

  },[
      organizationId,
      studyId,
      siteId,
      subjectId,
      documentId,
      version,
      displayDocument,
      siteFetch,
      t,
    ]);
 
  useEffect(() => {

    const drawDiscardMarks = async () => {

      let annotations = [];

      for (let pageNum = 0; pageNum < pspdfkitInstance.totalPageCount; pageNum++) {

        const pageInfo = pspdfkitInstance.pageInfoForIndex(pageNum);
        const pageBounds = new PSPDFKit.Geometry.Rect({
            left: 0,
            top: 0,
            width: pageInfo.width,
            height: pageInfo.height,
        });

        const l1 = new PSPDFKit.Annotations.LineAnnotation({
          pageIndex: pageNum,
          startPoint: new PSPDFKit.Geometry.Point({ x: 0, y: 0 }),
          endPoint: new PSPDFKit.Geometry.Point({ x: pageInfo.width,  y: pageInfo.height}),
          strokeWidth: 8,
          strokeColor: PSPDFKit.Color.RED,
          boundingBox: pageBounds,
          opacity: 0.7,
        });

        const l2 = new PSPDFKit.Annotations.LineAnnotation({
          pageIndex: pageNum,
          startPoint: new PSPDFKit.Geometry.Point({ x: 0, y: pageInfo.height }),
          endPoint: new PSPDFKit.Geometry.Point({ x: pageInfo.width,  y: 0}),
          strokeWidth: 8,
          strokeColor: PSPDFKit.Color.RED,
          boundingBox: pageBounds,
          opacity: 0.7,
        });

        const stamp = new PSPDFKit.Annotations.StampAnnotation({
          pageIndex: pageNum,
          stampType: 'Custom',
          title: t('document-review.Document Discarded'),
          subtitle: t('document-review.Not viewable by Sponsor/CRO'),
          color: new PSPDFKit.Color({ r: 0, g: 51, b: 79 }),
          boundingBox: new PSPDFKit.Geometry.Rect({ left: 10, top: 20, width: 150, height: 40 }),
          opacity: 0.7,
        });

        annotations.push(l1, l2, stamp);

      }

      annotationIds.current = await pspdfkitInstance.create(annotations);

    };

    const removeDiscardMarks = async () => {
      await pspdfkitInstance.delete(annotationIds.current);
      annotationIds.current = [];
    };

    if (!pspdfkitInstance) {
      return;
    }

    if (isDiscardDocument && annotationIds.current.length === 0) {
      drawDiscardMarks();
    } else {
      removeDiscardMarks();
    }



  }, [ isDiscardDocument, pspdfkitInstance, t ]);

   if(!isDocumentItemLoaded) {
    return null;
  }

  const passwordVerify = async (presentSignatureMetadata) => {

    const formData = new FormData();

    const signatureMetadataItem = {
      signatureMetadata: [
        ...documentItem.signatureMetadata,
        presentSignatureMetadata,
      ],
    }

// next block overwrites val[0], server only needs presentSignatureMetadata
    for (const key in signatureMetadataItem) {
      if (key === "signatureMetadata") {
        const val = signatureMetadataItem[key];
        val[0] = val[val.length - 1];
      }
    }

    formData.append('documentItem', JSON.stringify(signatureMetadataItem));

    const url = `/gui/organization/${organizationId}/study/${studyId}/site/${siteId}/subject/${subjectId}/verify`;

    const response = await siteFetch(url, {

      method: 'PATCH',
      headers: {
        TZ: (new Intl.DateTimeFormat()).resolvedOptions().timeZone
      },
      body: formData,

    });
    return response;
      
  }

  const onApprove = async (presentSignatureMetadata, setIsModalWaiting) => {
    setIsWaiting(true);
    setIsModalWaiting(true);
    const formData = new FormData();
    const blob = new Blob([], { type: "application/pdf" });
    const hash = SparkMD5.ArrayBuffer.hash(displayDocument);
    formData.append('fileName', '');
    formData.append('uploadSource', blob, documentItem.originalFileMetadata.name);

    formData.append('documentItem', JSON.stringify({
      ...documentItem,
      hash,
      reviewData: {
        ...documentItem.reviewData,
        status: 'approved',
      },
      signatureMetadata: [
        ...documentItem.signatureMetadata,
        presentSignatureMetadata,
      ],
    }));

    try {
      const isTotpVerified = await passwordVerify(presentSignatureMetadata);

      if(isTotpVerified.status !== 200){
        onError((isTotpVerified.status === 401 ? '' + t('api.try again'): '')); 
        setIsWaiting(false);
        setIsModalWaiting(false);
        return;
      }
      const url = `/gui/organization/${organizationId
                  }/study/${studyId
                  }/site/${siteId
                  }/subject/${subjectId
                  }/document/${documentId
                  }/version/${documentItem.version}`;

      const response = await siteFetch(url, {

        method: 'PATCH',
        headers: {
          TZ: (new Intl.DateTimeFormat()).resolvedOptions().timeZone,
        },
        body: formData,

      });
      setIsWaiting(false);
      setIsModalWaiting(false);

      if( response.status === 200 ) {
        history.push(`/organization/${organizationId}/study/${studyId}/site/${siteId}/document`);
      } else {

        const json = await response.json();
        const msg = `${t('api.Upload Error')}: ${response.status} ${response.statusText}: ${json.error ? json.error : ''}`;
        console.log(msg);
        onError(msg + (response.status === 401 ? ': ' + t('api.try again'): ''));

      }

    } catch (e) {
      setIsWaiting(false);
      setIsModalWaiting(false);
      console.log({ e });
      onError(e);
    }

  };

  const onReject = async ( presentSignatureMetadata, rejectionReason, setIsModalWaiting ) => {

    setIsWaiting(true);
    setIsModalWaiting(true);

    if( rejectionReason.isDiscardDocument ) {

      if(!pspdfkitInstance){
        throw new Error('expected pdf instance');
      }


      const isTotpVerified = await passwordVerify(presentSignatureMetadata);

      if(isTotpVerified.status !== 200){
        onError((isTotpVerified.status === 401 ? '' + t('api.try again'): '')); 
        setIsModalWaiting(false);
        setIsWaiting(false);
        return;
      }

      const buffer = await pspdfkitInstance.exportPDF();
      const blob = new Blob([buffer], { type: "application/pdf" });  
      const formData = new FormData();
      formData.append('documentItem', JSON.stringify({
        ...documentItem,
        hash: '',
        reviewData: {
          ...documentItem.reviewData,
          status: rejectionReason.isDiscardDocument
                  ? 'discarded' : 'rejected',
          rejectionReason,
        },
  
        signatureMetadata: [
          ...documentItem.signatureMetadata,
          presentSignatureMetadata,
        ],
      }));
      setDocumentItemFormData(formData);
      setRejectionReason(rejectionReason);
      setPresentSignatureMetadata(presentSignatureMetadata);
      getFileContext({blob});

      setIsModalWaiting(false);
      setIsWaiting(false);

      return;

    } // rejectionReason.isDiscardDocument

    const url = `/gui/organization/${organizationId}/study/${studyId}/site/${siteId}/subject/${subjectId}/document/${documentId}/version/${documentItem.version}`;
    const formData = new FormData();
    const hash = SparkMD5.ArrayBuffer.hash(displayDocument);
    const blob = new Blob([], { type: "application/pdf" });

    formData.append('uploadSource', blob, documentItem.originalFileMetadata.name);
    formData.append('fileName', '');
    formData.append('documentItem', JSON.stringify({
      ...documentItem,
      hash,
      reviewData: {
        ...documentItem.reviewData,
        status: rejectionReason.isDiscardDocument
                ? 'discarded' : 'rejected',
        rejectionReason,
      },
  
      signatureMetadata: [
        ...documentItem.signatureMetadata,
        presentSignatureMetadata,
      ],
    }));
  
    try {
      const isTotpVerified = await passwordVerify(presentSignatureMetadata);

      if(isTotpVerified.status !== 200){
        onError((isTotpVerified.status === 401 ? '' + t('api.try again'): '')); 
        setIsWaiting(false);
        setIsModalWaiting(false);
  
        return;
      }

      const response = await siteFetch(url, {
  
        method: 'PATCH',
        headers: {
          TZ: (new Intl.DateTimeFormat()).resolvedOptions().timeZone
        },
        body: formData,
  
      });
      setIsWaiting(false);
      setIsModalWaiting(false);
  
      if( response.status === 200 ) {
        history.push(`/organization/${organizationId}/study/${studyId}/site/${siteId}/document`);
      } else {
  
        const json = await response.json();
        
        // we can get 401 if the code is fatfingered/late or if the 
        // session has expired.
        // if code is fatfingered / late, json.error is 'Unauthorized'
        // if session has expired, json.error is 'Session lost' or 'Session
        // expired'
        if(response.status === 401
            && (json.error === 'Session expired'
               || json.error === 'Session lost'
               )
            ) {
          onError(`${t('api.401 Unauthorized')}: '${t('api.' + json.error)}'`);
          return;
        }
  
        const msg = `${t('api.Upload Error')}: ${response.status} ${response.statusText}: ${json.error ? json.error : ''}`;
        console.log(msg);
        onError(msg + (response.status === 401 ? ': ' + t('api.try again'): ''));
  
      }
  
    } catch (e) {
      setIsWaiting(false);
      setIsModalWaiting(false);
      console.log({ e });
      onError(e);
    }      


  }; // onReject

  if(!displayDocument) {
    return (
    <SiteFrame className='DocumentReview'>
      <Spin spinning={isLoading}>
      </Spin>
    </SiteFrame>  
    );
  }

  return (
    <SiteFrame className='DocumentReview'>
      <Spin spinning={isLoading || isWaiting}>
        <h1>{t('document-review.Review Document')}</h1>

        <div className='summary'>
          <WritableDocumentItemView
            documentItem={documentItem}
            displayDocument={displayDocument}
            pspdfkitInstance={pspdfkitInstance}
            setPspdfkitInstance={setPspdfkitInstance}
            ToolBar={
              <Space>
              {
                documentItem?.reviewData?.rejectionReason?.reason
                && documentItem.reviewData.rejectionReason.reason
                   .includes('approval-revocation')
                && (
                  <Popover
                    content={<ApprovalRevocationNotes 
                      documentItem={documentItem}
                      />}
                    title={t('document-review.Document Revocation Notes')}
                    >
                  <Button
                    className='document-item-header-toolbar'
                  >
                    {t('document-review.Document Revocation Notes')}
                  </Button>
                  </Popover>
                )
              }
              <Button
                className='document-item-header-toolbar'
                onClick={() => setIsModalDialogVisible(true)}
              >
                { t('document-review.Review Document') }
              </Button>
              </Space>
            }
          />
            <ModalSignaturePanel
              {...{

              title: t('document-review.E-Signature'),
              okText: t('document-review.Apply Signature'),
              isVisible: isModalDialogVisible,
              setIsVisible: setIsModalDialogVisible,

              onReject,
              onApprove,
              approvalReasons,

              setIsDiscardDocument,

             }}
            />
        </div>


      </Spin>
    </SiteFrame>

  );

}
