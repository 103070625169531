import React from 'react';
import { useTranslation } from 'react-i18next';
import { Layout } from 'antd';
import { onError } from '../lib/onError';
import useSite from '../lib/useSite';

import "antd/dist/antd.css";
import './SiteFrame.scss';

export default function SiteFrame ( props )  {

  const { t } = useTranslation();
  const {  className, children } = props;
  const { siteData } = useSite();
  const { Content } = Layout;

  if( !siteData ) {
    return null;
  }

  if(siteData === null) {
    onError(t('api.Error fetching site data'));
    return;
  }

  const cssClasses = className
                    ? `SiteFrame ${className}` : 'SiteFrame';

  return (
    <Layout className={cssClasses}>
      <Content className='site-frame-content'>
        {children}
      </Content>
    </Layout>
  );

};

