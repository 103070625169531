import React, { useRef, useEffect } from 'react';
import PSPDFKit from 'pspdfkit';
import './DocumentEditor.scss';

export const DocumentEditor = (props) => {
  
  const { document,
          licenseKey,
          baseUrl,
          setPspdfkitInstance,
          eventListeners,
        } = props;
  const ref = useRef();

  useEffect(() => {

    const createViewer = async () => {

      if(ref.current && ref.current.childNodes.length > 0) {
        return;
      }

      const instance = await PSPDFKit.load({

        document: document.slice(),
        container: ref.current,
        licenseKey,
        baseUrl,
        initialViewState: new PSPDFKit.ViewState({
          showToolbar: false,
          interactionMode: PSPDFKit.InteractionMode.REDACT_TEXT_HIGHLIGHTER,
          previewRedactionMode: true,
          enableAnnotationToolbar: false,
        }),
        annotationPresets: {
          redaction: {
            ...PSPDFKit.defaultAnnotationPresets.redaction,
            outlineColor: PSPDFKit.Color.BLACK,
            color: PSPDFKit.Color.BLACK,
          },
        },
      });

      for(const eventListener of eventListeners) {
        instance.addEventListener( eventListener.action, 
                                   eventListener.listener);
      }

      setPspdfkitInstance && setPspdfkitInstance(instance);

    };

    createViewer();

  }, [
       document,
       licenseKey,
       baseUrl,
       setPspdfkitInstance,
       eventListeners,  
  ]);

  return (
    <div ref={ref} className='document-container'/>
  );

};

