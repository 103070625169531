import React, { useState, useEffect, useRef } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Table, Typography, Input, Button, Space, } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import useSiteFetch from '../lib/useSiteFetch';
import useSite from '../lib/useSite';
import useTraceUpdate from '../lib/useTraceUpdate';
import useLocality from '../lib/useLocality';
import { onError } from '../lib/onError';
import 'antd/dist/antd.css';
import './SiteSubjectList.scss';

const PAGE_SIZE = 25;
const DEFAULT_SORT_STATE = { columnKey: 'edcSubjectKey', order: 'ascend' };
const DEFAULT_FILTER_STATE = [];

const SiteSubjectList = (props) => {
  const { t } = useTranslation();
  const { organizationId, studyId, siteId } = useParams();
  const { siteFetch } = useSiteFetch();
  const { siteData } = useSite();
  const pagingData = useRef({});
  
  const [ subjectItems, setSubjectItems ] = useState([]);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const pageSize = PAGE_SIZE;
  const [totalItemCount, setTotalItemCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [sortState, setSortState] = useState(DEFAULT_SORT_STATE);
  const [filterState, setFilterState] = useState(DEFAULT_FILTER_STATE);
  const {locality} = useLocality();

  useTraceUpdate({
    siteFetch,
    organizationId,
    studyId,
    siteId,
    pageSize,
    currentPageNumber,
    sortState,
    filterState,
    setSubjectItems,
    t,
  },'subjectList');
  
  useEffect(() => {
    
    const loadData = async () => {
      try {
        const response = await siteFetch(
          `/gui/organization/${organizationId}/study/${studyId}/site/${siteId}/subject?max-results=${pageSize}&paging=${
            pagingData.current.lastKey
              ? encodeURIComponent(JSON.stringify(pagingData.current.lastKey))
              : ''
          }&sort=${
            typeof sortState.order !== 'undefined' && sortState.order !== false
              ? encodeURIComponent(JSON.stringify(sortState))
              : ''
          }&filter=${
            filterState.length > 0
              ? encodeURIComponent(JSON.stringify(filterState))
              : ''
          }`
        );

        const json = await response.json();

        if (response.status === 200) {
          setIsLoading(false);
          setSubjectItems(json.items);

          if (json.paging.lastKey) {
            setTotalItemCount(currentPageNumber * pageSize + 1);
          } else {
            setTotalItemCount(
              (currentPageNumber - 1) * pageSize + json.items.length
            );
          }

          pagingData.current.lastKey = json.paging.lastKey;
        } else {
          const msg = `${t('api.Loading Error')}: ${response.status} ${
            response.statusText
          }: ${json.error ? json.error : ''}`;
          console.log(msg);
          onError(msg);
        }
      } catch (e) {
        onError(e);
        throw e;
      } finally {
        setIsLoading(false);
      }
    };

    if (currentPageNumber === 1) {
      pagingData.current = {};
    }
    /* 
    TODO: Come back here and eliminate the need to have if(siteData && locality){}
    find out the root cause of locality being null at times.
    */
    if(siteData && locality){
      setIsLoading(true);
      loadData();
    }

  }, [
    locality,
    siteData,
    siteFetch,
    organizationId,
    studyId,
    siteId,
    pageSize,
    currentPageNumber,
    sortState,
    filterState,
    setSubjectItems,
    t,
  ]);

  

  const onTableChange = async (pagination, filters, sorter, extra) => {
    /*
     pagination: {current: 2, pageSize: 3, total: 5}
     filters: { associatedEvents: null, associatedLogs: null }
     */

    switch (extra.action) {
      case 'paginate':
        setCurrentPageNumber(pagination.current);
        break;
      case 'sort':
        setSortState({
          columnKey: sorter.columnKey,
          order: sorter.order,
        });
        setCurrentPageNumber(1);
        break;
      case 'filter':
        const requestFilters = [];

        if (filters.edcSubjectKey && filters.edcSubjectKey.length > 0) {
          requestFilters.push({
            attribute: ['edcSubjectKey'],
            operator: 'begins_with',
            value: filters.edcSubjectKey[0],
          });
        }

        setFilterState(requestFilters);

        break;
      default:
        throw new Error(`Unrecognized table action '${extra.action}'`);
    }
  };

  const SubjectFilterDropdown = (props) => {
    const { setSelectedKeys, selectedKeys, confirm, clearFilters } = props;

    return (
      <div className='SubjectFilterDropdown' style={{ padding: 8 }}>
        <Input
          style={{ width: 188, marginBottom: 8, display: 'block' }}
          placeholder={`${t('site.subjects.Subjects starting with')} ...`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={confirm}
        />
        <Space>
          <Button
            type='primary'
            icon={<SearchOutlined />}
            size='small'
            style={{ width: 90 }}
            onClick={() => confirm({ closeDropdown: false })}
          >
            {t('site.subjects.Search')}
          </Button>
          <Button size='small' style={{ width: 90 }} onClick={clearFilters}>
            {t('site.subjects.Clear')}
          </Button>
        </Space>
      </div>
    );
  };

  const sitePath = `/organization/${organizationId}/study/${studyId}/site/${siteId}`;

  const columns = [];
  columns.push({
    title: t('site.subjects.Subject'),
    dataIndex: ['edcSubjectKey'],
    key: 'edcSubjectKey',
    render: (text, record) => (
      <Link to={`${sitePath}/subject/${record.subjectId}`}>
        {text}
      </Link>
    ),
    sorter: true,
    sortOrder:
      sortState.columnKey === 'edcSubjectKey' ? sortState.order : false,
    filterDropdown: (props) => <SubjectFilterDropdown {...props} />,
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
  });
  columns.push({
    title: t('site.subjects.Approved Document Count'),
    dataIndex: ['documentData', 'approvedDocumentCount'],
    key: 'approvedDocumentCount',
    render: (text, record) => (
      <Typography.Text ellipsis={true}>{text}</Typography.Text>
    ),
  });
  columns.push({
    title: t('site.subjects.Last Approval Date'),
    dataIndex: ['documentData', 'dateOfLastApprovedDocument'],
    key: 'dateOfLastApprovedDocument',
    render: (value) =>
      value === 0 ? '-' : t('study-context.formattedDate', { date: value }),
    sorter: true,
    sortOrder:
      sortState.columnKey === 'dateOfLastApprovedDocument'
        ? sortState.order
        : false,
  });

  if (!subjectItems || !subjectItems[0]) {
    return null;
  }

  return (
    <div className='SiteSubjectList'>
      <Table
        className='subject-table'
        dataSource={subjectItems}
        loading={isLoading}
        columns={columns}
        rowKey={record => record.subjectId}
        onChange={onTableChange}
        pagination={{
          current: currentPageNumber,
          pageSize: pageSize,
          total: siteData?.edcUsers?.[0]?.sites?.[0]?.subjectsCount
                 || totalItemCount,
          // hideOnSinglePage can leave user stranded if totalItemCount active
          // and true total equals pageSize, because of our adaptive total
          hideOnSinglePage: siteData?.edcUsers?.[0]?.sites?.[0]?.subjectsCount
                            ? true : false,
        }}
      />
    </div>
  );
};

export default SiteSubjectList;
