import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Spin, PageHeader } from 'antd';
import { useTranslation } from 'react-i18next';
import useSubject from '../lib/useSubject';
import useFetch from '../lib/useFetch';
import { onError } from '../lib/onError';
import SiteFrame from '../components/SiteFrame';
import { NewDocumentTask } from '../components/NewDocumentTask';

import './SubjectDocumentAdd.scss';

export default function SubjectDocumentAdd ( props ) {

  const { organizationId, studyId, siteId, subjectId } = useParams();

  const { t } = useTranslation();

  const { authenticatedFetch } = useFetch();
  const { subjectData, setSubjectData } = useSubject();

  const [ documentName, setDocumentName ] = useState(t('document-task.New Document'));

  useEffect(() => {

    const url = `/gui/organization/${organizationId
                           }/study/${studyId
                            }/site/${siteId
                         }/subject/${subjectId
                         }/view`;

    const loadSiteData = async () => {

      try {
        const response = await authenticatedFetch(url, {
          headers: {
            TZ: (new Intl.DateTimeFormat()).resolvedOptions().timeZone,
          },
        });
        const json = await response.json();
        setSubjectData(json);
      } catch (e) {
        console.log(e);
        onError(e);
      }
    };

    if(subjectData?.subjectItem?.subjectId !== subjectId) {
      loadSiteData();
    }
  }, [
    organizationId,
    studyId,
    siteId,
    subjectId,
    subjectData?.subjectItem?.subjectId,
    setSubjectData,
    authenticatedFetch,
  ]);

  if(!subjectData) {
    return (
      <SiteFrame className='SubjectDocumentAdd' subjectId={subjectId}>
        <div className='spin-holder'>
          <Spin className='spin'/>
        </div>
      </SiteFrame>
      );
  }

  return (
    <SiteFrame className='SubjectDocumentAdd' subjectId={subjectId}>
      <PageHeader
        className='page-header'
        title={documentName}
      /> 
      <div className='local-file-notice'>
        {t('document-task.Working locally; file has not been committed to the server')}
      </div>  
      <NewDocumentTask
        subjectData={subjectData}
        setDocumentName={setDocumentName}
      />
    </SiteFrame>
  );
}
