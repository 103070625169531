import React, { useRef, useEffect } from 'react';
import PSPDFKit from 'pspdfkit';
import './DocumentViewer.scss';

export const DocumentViewer = (props) => {

  const { document, licenseKey, baseUrl, customActions = [], setPspdfkitInstance } = props;
  const ref = useRef();

  useEffect(() => {

    const createViewer = async () => {

/*
      const downloadButton = {
        type: "custom",
        id: "my-download",
        title: "Download",
        onPress:  async () => {
          const buffer = await instance.exportPDF();
          const blob = new Blob([buffer], { type: "application/pdf" });
          const fileName = "document.pdf";
          if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, fileName);
          } else {
            const objectUrl = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = objectUrl;
            a.style = "display: none";
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(objectUrl);
            document.body.removeChild(a);
          }
        }
      };

*/

      const instance = await PSPDFKit.load({

        document: document.slice(),
        container: ref.current,
        licenseKey,
        baseUrl: baseUrl,
        toolbarItems: [
          { type: 'pager' },
          { type: 'zoom-in' },
          { type: 'zoom-out' },
          { type: 'zoom-mode' },
          { type: 'layout-config' },
          //downloadButton,
          ...customActions,
        ],
        toolbarPlacement: PSPDFKit.ToolbarPlacement.BOTTOM,
        trustedCAsCallback: async () => {

          const response = await fetch('/pki/sourceupload.crt');
          const cert = await response.text();
          return [ cert ];
        },

      });

      setPspdfkitInstance && setPspdfkitInstance(instance);

    };

    if(ref.current && ref.current.childNodes.length > 0) {
      return;
    }

    createViewer();

  }, [ document, licenseKey, baseUrl, setPspdfkitInstance, customActions ]);

  return (
    <div ref={ref} className='document-container'/>
  );

};

