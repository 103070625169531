import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Form, Button, Input } from 'antd';
import TotpInput from '../../components/TotpInput';
import Auth from '../../lib/Auth';
import useAppUser from '../../lib/useAppUser';
import { onError } from '../../lib/onError';
import './Login.css';

const resetLabel = (t, authenticationType) => {

  switch (authenticationType) {
    case 'totp':
      return t('authentication.New TOTP Key');
    case 'password':
    default:
      return t('authentication.New Password');
  }
};

const passwordLabel = (t, authenticationType) => {

  switch (authenticationType) {
    case 'totp':
      return t('authentication.TOTP Code');
    case 'password':
    default:
      return t('authentication.Password');
  }
};

export default function LoginPassword() {

  const history = useHistory();
  const { t } = useTranslation();
  const location = useLocation();
  const { accountState, userHasAuthenticated, setSessionData } = useAppUser();
  const [ isLoading, setIsLoading ] = useState(false);

  if(!Auth.hasEmail()) {
    history.push('/login');
  }

  async function handleSubmit(value) {

    setIsLoading(true);

    try {
      const sessionData = await Auth.signIn(value.email, value.password);
      setSessionData(sessionData);
      userHasAuthenticated(true);
      //setIsLoading(false); // causes error: Can't perform a React state update on an unmounted component.
      if(sessionData.training.length > 0) {
        const q = location.search.substring(1);
        const r = q.split('&').filter(i => i.startsWith('redirect='))
                    .map(i => i.substring(9))
                    .pop();
        history.push(!r || r.startsWith('/training')
                       ? '/training'
                       : `/training?redirect=${r}`);            
        return;
      }
    } catch (e) {

      if(e.message === 'no email') {
        onError(e);
        return;
      }
      onError(e);
      setIsLoading(false);
    }
  }

  const handleForgottenPassword = async () => {
    Auth.forgotPassword(Auth.getEmail());
    history.push('/reset');
  };

  return (

  <div className='Login'>

    <Form
      onFinish={handleSubmit}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
    >
      <Form.Item label={t('authentication.Email')}
        name='email'
        initialValue={Auth.getEmail()}
      >
        <Input
          disabled={true}
          type='email'
        />
      </Form.Item>

      <Form.Item
        label={passwordLabel(t, accountState.authenticationType)}
        type='password'
        name='password'
        rules={[
          {
            required: true,
            //message: 'Please define your password!',
          },
          // don't be so hasty to draw rejection messages
          form => ({
            validator: (rule, value) =>
              new Promise(
                (resolve, reject) => setTimeout(() => resolve(), 800))
          }),
          {len: 6},
        ]}
      >

        <TotpInput />

      </Form.Item>

      <Form.Item wrapperCol={{ offset: 8, span: 16 }} >
        <Button
          //disabled={ !isSubmitButtonVisible }
          loading={isLoading}
          htmlType='submit'
          type='primary'
        >{t('authentication.Login')}</Button>
        <div>
          <Button type='link'
                  style={{ padding: '0px'}}
                  onClick={ handleForgottenPassword }>{
            resetLabel(t, accountState.authenticationType)
          }</Button>
        </div>
      </Form.Item>

    </Form>
  </div>
  );
}
