import React, { useState } from 'react';

// SiteContext
// follows https://upmostly.com/tutorials/how-to-use-the-usecontext-hook-in-react

const SiteContext = React.createContext([{}, () => {}]);

const SiteProvider = (props) => {

  const [state, setState] = useState({
    isSiteContextLoaded: false,
    });
  return (
    <SiteContext.Provider value={[state, setState]}>
      {props.children}
    </SiteContext.Provider>
  );
}

export { SiteContext, SiteProvider };

