import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Layout,
         Input,
         Button,
         Space,
         PageHeader,
         Spin,
         Divider,
         } from 'antd';
import PSPDFKit from 'pspdfkit';
import { PSPDFKIT_LICENSE_KEY } from './pspdfkit/PspdfkitInstance';
import { DocumentEditor } from './pspdfkit/DocumentEditor';
import 'antd/dist/antd.css';
import './DocumentRedactionEditor.scss';

const RedactionMode = {
  Area: PSPDFKit.InteractionMode.REDACT_SHAPE_RECTANGLE,
  Text: PSPDFKit.InteractionMode.REDACT_TEXT_HIGHLIGHTER,
  Search: null,
};

export const DocumentRedactionEditor = props => {

  const { sourceFile,
          setDestinationDocument,
          setStepConfirm,
          setOnStepFinished,
          isLoading,
        } = props;

  const { t } = useTranslation();
  const [ pspdfkitInstance, setPspdfkitInstance ] = useState(null);
  const [ activeDocument, setActiveDocument ] = useState();
  const [ redactionMode, setRedactionMode ] = useState(RedactionMode.Text);
  const [ interactionMode, setInteractionMode ] = useState(null);
  const [ searchText, setSearchText ] = useState('');
  const [ hasRedactions, setHasRedactions ] = useState(false);

  const stepConfirmWarning = t('You have made no redactions on this document; please verify that this is correct');

  useEffect(() => {

    const openFile = async file => {

      // assert: file is an ArrayBuffer

      if(!(file instanceof ArrayBuffer)) {
        // by discipline, we now require sourceFile to always
        // be an ArrayBuffer
        throw new Error('expected file to be an ArrayBuffer');
      }

      setActiveDocument(file);  
      
    };

    if(!sourceFile || activeDocument) {
      return;
    }

    openFile(sourceFile);

  }, [sourceFile, activeDocument ]);

  useEffect(() => {
    setStepConfirm(stepConfirmWarning);

  }, [ setStepConfirm, stepConfirmWarning ]);


  useEffect(() => {

      const saveRedactedPdf = async () => {
        if(!pspdfkitInstance){
          return;
        }
        if(hasRedactions) {
          await pspdfkitInstance.applyRedactions();
        }
        const buffer = await pspdfkitInstance.exportPDF();
        setDestinationDocument(buffer);
        setStepConfirm(null);
      }

      setOnStepFinished(() => () => saveRedactedPdf());

  }, [ setOnStepFinished,
       pspdfkitInstance,
       setDestinationDocument,
       setStepConfirm,
       hasRedactions,
     ]);

  useEffect(() => {

    const checkRedactionCount = async () => {

      let isRedactionFound = false;

      for (let i = 0; i < pspdfkitInstance.totalPageCount; i++) {
        const anns = await pspdfkitInstance.getAnnotations(i);
        const redactionAnns = anns
          .filter(ann => ann instanceof PSPDFKit.Annotations.RedactionAnnotation)
          .map(ann => ann.id);

        if(redactionAnns.size > 0) {

          isRedactionFound = true;
          break;
        }

      }

      setHasRedactions(isRedactionFound);
      setStepConfirm(isRedactionFound ? null : stepConfirmWarning);

    };

    if(!pspdfkitInstance) {
      return;
    }

    pspdfkitInstance.addEventListener(
      'annotations.change',
       checkRedactionCount,
    );
  }, [ pspdfkitInstance, setStepConfirm, stepConfirmWarning ]);

  useEffect(() => {

    if(!pspdfkitInstance) {
      return;
    }

    if(interactionMode === redactionMode) {
      return;
    }

    pspdfkitInstance.setViewState(viewState => 
      viewState.set('interactionMode', redactionMode));

  }, [ pspdfkitInstance, redactionMode, interactionMode ]);

  useEffect(() => {

    if(!pspdfkitInstance) {
      return;
    }

    pspdfkitInstance.setViewState(viewState => 
      viewState.set('interactionMode', redactionMode));

  }, [ pspdfkitInstance, redactionMode, ]);

  const eventListeners = [
    {
      action: 'viewState.change',
      listener: viewState => setInteractionMode(viewState.interactionMode),
    }
  ];

  const clearAnnotations = async () => {
    for (let i = 0; i < pspdfkitInstance.totalPageCount; i++) {
      const anns = await pspdfkitInstance.getAnnotations(i);
      const redactionAnns = anns
        .filter(ann => ann instanceof PSPDFKit.Annotations.RedactionAnnotation)
        .map(ann => ann.id);
      pspdfkitInstance.delete(redactionAnns);
    }
  };

  if( !activeDocument) {
    return null;
  }

  const { Content } = Layout;
  const { Search } = Input;

  return (
    <Spin spinning={isLoading}>
      <Layout className='DocumentRedactionEditor'>
        <PageHeader className='controls'>
          <Space className='controls-left'>
            <Button
              type={redactionMode === RedactionMode.Area ? 'primary' : 'default'}
              onClick={() => { 
                setRedactionMode(RedactionMode.Area);
              }}
            >{t('document-task.redaction-editor.Area Redaction')}</Button>
            <Divider type='vertical' />
            <Button
              type={redactionMode === RedactionMode.Text ? 'primary' : 'default'}
              onClick={() => { 
                setRedactionMode(RedactionMode.Text);
              }}
            >
              {t('document-task.redaction-editor.Text Redaction')}
            </Button>
            <Divider type='vertical' />
            <Search
              className='text-search-redaction'
              placeholder={t('document-task.redaction-editor.Text to redact')}
              enterButton={
                <Button
                  type={redactionMode === RedactionMode.Search ? 'primary' : 'default'}
                >
                  {t('document-task.redaction-editor.Search Redaction')}
                </Button>
                }
              value={searchText}
              onChange={e => {
                setSearchText(e.target.value);
              }}

              onSearch={ async text => {
                if (text === '') {
                  // alert
                  return;
                }
                await pspdfkitInstance.createRedactionsBySearch(
                  text, {
                    searchType: PSPDFKit.SearchType.TEXT, // TEXT, PRESET, or REGEX
                    searchInAnnotations: true,
                    annotationPreset: {
                      //overlayText: 'Redacted'
                    }
                });

              }}
              onFocus={() => {
                setRedactionMode(RedactionMode.Search);

              }}
              onBlur={e => {
                setRedactionMode(RedactionMode.Text);
                setSearchText('');
              }}
            />
            <Divider type='vertical' />
            <Button
              onClick={async () => { 
                await clearAnnotations();
              }}
            >{t('document-task.redaction-editor.Clear Redactions')}</Button>
          </Space>  
          <Space className='controls-right'>
            <Button 
              onClick={() => { 
                pspdfkitInstance.setViewState(viewState => (
                  viewState.zoomIn()
                  ))
              }}
            >{t('document-task.redaction-editor.Zoom In')}</Button>

            <Button 
              onClick={() => { 
                pspdfkitInstance.setViewState(viewState => (
                  viewState.zoomOut()
                  ))
              }}
            >{t('document-task.redaction-editor.Zoom Out')}</Button>
            <Button
              onClick={() => { 
                pspdfkitInstance.setViewState(viewState => (
                  viewState.set('pagesRotation',
                    viewState.pagesRotation === 270 
                      ? 0 : viewState.pagesRotation + 90)
                  ))
              }}
            >{t('document-task.redaction-editor.Rotate')}</Button>

          </Space>
        </PageHeader>

        <Content className='document'>
          <DocumentEditor 
            document={activeDocument}
            licenseKey={PSPDFKIT_LICENSE_KEY}
            baseUrl={`${window.location.protocol}//${window.location.host}/${process.env.PUBLIC_URL}`}
            setPspdfkitInstance={setPspdfkitInstance}
            eventListeners={eventListeners}
            //eventListeners={[]}
          />
        </Content>

      </Layout>
    </Spin>
  );
};
