import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
         Transfer,
         Steps,
         Row,
         Col,
         Button,
         Upload,
         Popconfirm,
         Popover,

       } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { VisitLabel, FormLabel } from './Labels';

import './DocumentTask.scss';
import 'antd/dist/antd.css';

const DocumentUpload = props => {

  const { t } = useTranslation();
  const { setSourceFile, setOriginalFileMetadata } = props;

  const [fileList, setFileList] = useState([]);

  const handleChange = ({ fileList }) => {
    setFileList(fileList);
  };

  const uploadSource = async argv => {

    setOriginalFileMetadata({
      lastModified: argv.file.lastModified,
      lastModifiedDate: argv.file.lastModifiedDate,
      name: argv.file.name,
      size: argv.file.size,
      type: argv.file.type,
    });
    setSourceFile(await argv.file.arrayBuffer());
    argv.onSuccess();
  };

  const uploadProps = {
    ...props,
    name: 'uploadSource',
    customRequest: uploadSource,
    onChange: handleChange,
    fileList,
    listType: 'picture-card',
    accept: 'application/pdf',
  };

  const uploadButton = (
    <div style={{ visible: fileList.length < 1 }}>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>
        {t('document-task.document-upload.Upload')}
      </div>
    </div>
  );

  return (
    <Upload {...uploadProps} className='DocumentUpload'>
      {fileList.length < 1 ? uploadButton : null}
    </Upload>
  );
};

export const DocumentUploadPage = props => {
  const { t } = useTranslation();
    return (
    <div className='DocumentUploadPage'>
      <h3>{t('document-task.Upload Source')}</h3>
      <DocumentUpload
        setSourceFile={props.setSourceFile}
        setOriginalFileMetadata={props.setOriginalFileMetadata}
      />
    </div>
  );
};

const EventTransfer = props => {
  const { t } = useTranslation();

  const { studyEventData, associatedEvents, setAssociatedEvents, setStepConfirm } = props;

  // Sort in place by studyEventDate desc.
  // We assume data has ISO 8601 format like 2019-02-12T05:00:00.000Z.
  // Any naturally sortable format will work.
  studyEventData.sort((a, b) => a.studyEventDate < b.studyEventDate ? 1 : -1);

  const initialData = {

    data: studyEventData ? studyEventData.map(
      (studyEventItem, index) => ({
          key: index,
          label: <VisitLabel studyEventItem={studyEventItem} />,
        })) : [],
    targetKeys: associatedEvents.map(ae => 
      studyEventData.findIndex(se => 
        se.edcStudyEventOID === ae.edcStudyEventOID
        && se.edcStudyEventName === ae.edcStudyEventName
      )),
  };

  const [data, setData] = useState(initialData);

  const handleChange = (targetKeys) => {
    setData({ ...data, targetKeys });
    setAssociatedEvents(targetKeys.map((i) => studyEventData[i]));
  };

  const onSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {

    setStepConfirm(sourceSelectedKeys.length + targetSelectedKeys.length > 0
                   ? t('document-task.transfer.Warning- unused selections')
                   : null);
  };

  return (
    <Transfer
      dataSource={data.data}
      targetKeys={data.targetKeys}
      showSearch
      listStyle={{
        width: 600,
        height: 430,
      }}
      operations={[t('document-task.transfer.Associate'),
                   t('document-task.transfer.Disassociate')]}
      onChange={handleChange}
      onSelectChange={onSelectChange}
      render={item => item.label}
      filterOption={(inputValue, option) => 
        option.label.props.studyEventItem.studyEventName.includes( inputValue )
      }
    />
  );
};

const LogTransfer = (props) => {
  const { t } = useTranslation();

  const { studyLogData, associatedLogs, setAssociatedLogs, setStepConfirm } = props;

  const initialData = {
    data: studyLogData.map((studyLogItem, index) => ({
      key: index,
      label: <FormLabel studyLogItem={studyLogItem} />,
    })),
    targetKeys: associatedLogs.map(ae => 
      studyLogData.findIndex(se => 
        se.edcFormOID === ae.edcFormOID
        && se.edcName === ae.edcName
        && se.edcFormRepeatKey === ae.edcFormRepeatKey
      )),
  };

  const [data, setData] = useState(initialData);

  const handleChange = (targetKeys) => {
    setData({ ...data, targetKeys });
    setAssociatedLogs(targetKeys.map((i) => studyLogData[i]));
  };

  const onSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {

    setStepConfirm(sourceSelectedKeys.length + targetSelectedKeys.length > 0
                   ? t('document-task.transfer.Warning- unused selections')
                   : null);
  };

  return (
    <Transfer
      dataSource={data.data}
      targetKeys={data.targetKeys}
      showSearch
      listStyle={{
        width: 600,
        height: 430,
      }}
      operations={[t('document-task.transfer.Associate'),
                   t('document-task.transfer.Disassociate')]}
      onChange={handleChange}
      onSelectChange={onSelectChange}
      render={item => item.label}
      filterOption={(inputValue, option) => 
        option.label.props.studyLogItem.edcName.includes( inputValue )
      }
    />
  );
};

export const VisitSelector = (props) => {
  const { t } = useTranslation();

  return (
    <div className='VisitSelector'>
      <h3>{t('document-task.Associate Events')}</h3>
      <EventTransfer
        studyEventData={props.subjectData.event}
        associatedEvents={ props.associatedEvents}
        setAssociatedEvents={ props.setAssociatedEvents }
        setStepConfirm={ props.setStepConfirm }
      />
    </div>
  );
};

export const FormSelector = (props) => {
  const { t } = useTranslation();
  return (
    <div className='FormSelector'>
      <h3>{t('document-task.Associate Logs')}</h3>
      <LogTransfer
        studyLogData={props.subjectData.log}
        associatedLogs={props.associatedLogs}
        setAssociatedLogs={props.setAssociatedLogs}
        setStepConfirm={ props.setStepConfirm }
      />
    </div>
  );
};

const ReviewStatus = props => {

  const { t } = useTranslation();
  const { reviewData } = props;
  const reason = reviewData.rejectionReason
  return (
    <div>
      <ul> { reason.reason.map(r => <li>{r}</li>) } </ul>
       {reason.detail && (
       <div>
         <b>{t('document-task.redaction-editor.Detail')}</b>
         <p> {reason.detail} </p>
       </div>)}
    </div>
  );
};


// follows https://stackoverflow.com/questions/32553158/detect-click-outside-react-component#answer-42234988
function useOutsideAlerter(ref) {
  useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        console.log("You clicked outside!");
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

export const DocumentEditSteps = props => {

  const { className, steps, reviewData, stepConfirm, setStepConfirm } = props;

  const { t } = useTranslation();
  const { Step } = Steps;

  const [ currentStep, setCurrentStep ] = useState(0);

  const [ onStepFinished, setOnStepFinished ] = useState(null);
  // const [ stepConfirm, setStepConfirm ] = useState(null);
  const [ isPreviousStepConfirmVisible, setIsPreviousStepConfirmVisible ] = useState(false);
  const [ isNextStepConfirmVisible, setIsNextStepConfirmVisible ] = useState(false);

  const ref = useRef( null );
  useOutsideAlerter(ref);

  const history = useHistory();

  const next = () => {

    if(stepConfirm) {

      setIsNextStepConfirmVisible(true);
      return;
    }

    if(onStepFinished !== null) {
      onStepFinished();
      setOnStepFinished(null);
    }

    setIsNextStepConfirmVisible(false);
    setCurrentStep(currentStep + 1);

  };

  const prev = () => {
    if(stepConfirm) {

      setIsPreviousStepConfirmVisible(true);
      return;
    }

    setOnStepFinished(null);

    setIsPreviousStepConfirmVisible(false);
    setCurrentStep(currentStep - 1);
  };

  return (
    <div className={`DocumentEditSteps ${className}`}
         ref={ref}>
      <Steps current={currentStep}>
        {steps.map(item => {
          return <Step
            key={item.title}
            title={item.title} 
            />
        })}
      </Steps>
      <div className='steps-content'>{
        React.cloneElement(steps[currentStep].content, {setStepConfirm,
                                                        setOnStepFinished})
       }</div>
      <div className='steps-action'>
        <Row justify='space-between'>
          {currentStep > 0 && (
            <Col span={4}>
              <Popconfirm
                visible={isPreviousStepConfirmVisible}
                title={stepConfirm}
                onConfirm={e => {
                    setIsPreviousStepConfirmVisible(false);
                    setCurrentStep(currentStep - 1);
                    setStepConfirm(null);
                }}
                onCancel={ e =>  {
                    setIsPreviousStepConfirmVisible(false);
                }}
                cancelText={t('document-task.Stay')}
                okText={t('document-task.Previous')}
              >
                <Button onClick={prev} block size='large'>
                  {t('document-task.Previous')}
                </Button>
              </Popconfirm>
            </Col>
          )}
          {currentStep === 0 && steps?.[currentStep]?.isRedactStep && (
            <Col span={4}>
            <Popconfirm
                visible={isPreviousStepConfirmVisible}
                title={stepConfirm}
                onConfirm={e => {
                    setIsPreviousStepConfirmVisible(false);
                    setStepConfirm(null);
                    history.go(-1);
                }}
                onCancel={ e =>  {
                    setStepConfirm(t('You have made no redactions on this document; please verify that this is correct'));
                    setIsPreviousStepConfirmVisible(false);
                }}
                cancelText={t('document-task.Stay')}
            >
              <Button
              block size='large'
              onClick={
                () => { 
                  setStepConfirm(t('document-task.Abandon document processing?'));
                  setIsPreviousStepConfirmVisible(true);
                }}
              >{t('document-task.Cancel')}</Button>
            </Popconfirm>
            </Col>
          )}
          { !reviewData?.status && (
            <Col span={12}/>
          )}
          { reviewData?.status && (
          <>
            <Col span={4}/>
            <Col span={4}>
            <Popover content={(<ReviewStatus reviewData={reviewData}/>)}
                     title={t(`document-task.redaction-editor.review-status-value.${reviewData.status}`)}
                     trigger='focus'
                     >
              <Button
                type='primary'
                block
                size='large'
              >
                {t('document-task.redaction-editor.Review Feedback')}
              </Button>
            </Popover>
            </Col>
            <Col span={4}/>
          </>
          )}
          {currentStep < steps.length - 1 && (
            <Col span={4}>
              <Popconfirm
                visible={isNextStepConfirmVisible}
                title={stepConfirm}
                onConfirm={e => {

                    if(onStepFinished !== null) {
                      onStepFinished();
                      setOnStepFinished(null);
                    }

                    setIsNextStepConfirmVisible(false);
                    setCurrentStep(currentStep + 1);           
                    setStepConfirm(null);

                }}
                onCancel={ e =>  {
                    setIsNextStepConfirmVisible(false);
                }}
                cancelText={t('document-task.Stay')}
                okText={t('document-task.Next')}
              >
                <Button type='primary' onClick={next} block size='large'>
                  {t('document-task.Next')}
                </Button>
              </Popconfirm>
            </Col>
          )}
          {currentStep === steps.length - 1 && (
            <Col span={4} />
          )}
        </Row>
      </div>
    </div>
  );

};
