import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Image } from 'antd';
import { useTranslation } from 'react-i18next';
import TotpInput from './TotpInput';
import Auth from '../lib/Auth';
import { onError } from '../lib/onError';
import 'antd/dist/antd.css';

export const SubmitPasswordForm  = props => {

  const { t } = useTranslation();
  const [ form ] = Form.useForm();
  const [ isSubmitButtonVisible, setIsSubmitButtonVisible ] = useState( false );
  const [ isLoading, setIsLoading ] = useState(false);

  const submitPassword = async value => {
    
    setIsLoading(true);

    try {
      const newUser = {
        username: value.email,
        password: value.password,
      };
      await Auth.signUp(newUser);
      setIsLoading(false);
      props.onPasswordRegistered(newUser);
    } catch (e) {
      console.log(e);
      onError(e);
      setIsLoading(false);
    }    
  }

  return (
    <Form onFinish={submitPassword}
      form={form}
      onValuesChange={(changedValues, allValues) => {
        setIsSubmitButtonVisible(
          allValues.email
          && allValues.email.length > 0
          && allValues.password
          && allValues.password.length > 0
          && allValues.confirmPassword
          && allValues.confirmPassword === allValues.password
        );
      }} 
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
    >
        <Form.Item label={t('authentication.Email')}
          name='email'
          initialValue={props.email}
        >
          <Input
            disabled={true}
            type='email'
          />
        </Form.Item>

        <Form.Item label={t('authentication.Password')}
          type='password'
          name='password'
          hasFeedback
          rules={[
            {
              required: true,
              message: t('authentication.Please define your password'),
            },
            // don't be so hasty to draw rejection messages
            form => ({
              validator: (rule, value) =>
                new Promise(
                  (resolve, reject) => setTimeout(() => resolve(), 800))
            }),
            {min: 2},
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          label={t('authentication.Confirm Password')}
          type='password'
          name='confirmPassword'
          dependencies={['password']}
          hasFeedback
          rules={[
            {
              required: true,
              message: t('authentication.Please confirm your password'),
            },
            ({ getFieldValue }) => ({
              validator: (rule, value) => {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return new Promise((resolve, reject) => {
                setTimeout(() => reject(t('authentication.Passwords dont match')), 800);
               });
              },
            }), // getFieldValue => ({})
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }} >
          <Button
            disabled={ !isSubmitButtonVisible }
            loading={isLoading}
            htmlType='submit'
          >{t('authentication.Register')}</Button>
        </Form.Item>
    </Form>
  );
};

export const EmailConfirmationForm = props => {

  const { t } = useTranslation();
  const [ form ] = Form.useForm();
  const [ isSubmitButtonVisible, setIsSubmitButtonVisible ] = useState( false );
  const [ isLoading, setIsLoading ] = useState(false);

  const confirmEmail = async value => {

    setIsLoading(true);

    try { 
      const response = await Auth.confirmSignUp(props.email, value.confirmationCode);
      if(response.error && response.error === 'Invalid confirmation code') {
        console.log(t('authentication.Invalid confirmation code'));
        onError(t('authentication.Invalid confirmation code'));
        setIsLoading(false);
        return;
      }
      if(response.error && response.error === 'Confirmation code has expired') {
        Auth.register(Auth.getEmail());
        const message = t('authentication.confirmation-code-expiry-message');
        console.log(message);
        onError(message);
        setIsLoading(false);
        return;
      }

      if(response.error) {

        console.log(response.error);
        onError(response.error);
        setIsLoading(false);
        return;
      }

      props.onEmailConfirmed();
    } catch (e) {
      console.log(e);
      onError(e);
      setIsLoading(false);
    }

  };

  return (
    <Form onFinish={confirmEmail}
      form={form}
      onValuesChange={(changedValues, allValues) => {
        setIsSubmitButtonVisible(
          allValues.confirmationCode
          && allValues.confirmationCode.length > 0
        );
      }} 
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
    >
      <Form.Item
        name='confirmationCode'
        label={t('authentication.Confirmation Code')}
        extra={t('authentication.please-check-your-email-for-the-code')}
        rules={[
            {
              required: true,
              message: t('authentication.email confirmation code is required'),
            },
            // don't be so hasty to draw rejection messages
            form => ({
              validator: (rule, value) =>
                new Promise(
                  (resolve, reject) => setTimeout(() => resolve(), 800))
            }),
            {len: 6},
          ]}
      >
        <Input
          type='tel'
        />
      </Form.Item>
      <Form.Item wrapperCol={{ offset: 8, span: 16 }} >
        <Button
          disabled={ !isSubmitButtonVisible }
          loading={isLoading}
          htmlType='submit'
        >{t('authentication.Submit')}</Button>
      </Form.Item>
    </Form>
    );
};

export const TOTPKeyForm = props => {
  
  const { t } = useTranslation();
  const [ form ] = Form.useForm();
  const [ isSubmitButtonVisible, setIsSubmitButtonVisible ] = useState( false );
  const [ isLoading, setIsLoading ] = useState(false);
  const [ totpKeyResponse, setTotpKeyResponse ] = useState(null);

  useEffect(() => {

    const fetchTotpKeyImage = async () => {
      try {
        const response = await Auth.setupTOTP(props.email);
        setTotpKeyResponse(response);
      } catch (e) {
        console.log(e);
        onError(e);
      }
    };

    if( totpKeyResponse) {
      return;
    }
    fetchTotpKeyImage();

  }, [ totpKeyResponse, props.email ]);

  async function setupTOTP(value) {

    setIsLoading(true);

    try {
      const newUser = {
        username: value.email,
        password: value.password,
      };
      setIsLoading(false);
      props.onTotpRegistered(newUser);
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }    
  }

  return (
    <Form
      onFinish={setupTOTP}
      form={form}
      onValuesChange={(changedValues, allValues) => {
        setIsSubmitButtonVisible(
           allValues.email
           && allValues.email.length > 0
           && allValues.password
           && allValues.password.length === 6
         );
      }}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
    >
        <Form.Item label={t('authentication.Email')}
          name='email'
          initialValue={props.email}
        >
          <Input
            disabled={true}
            type='email'
          />
        </Form.Item>      

        <Form.Item label={t('authentication.TOTP Key')}
          name='totpKey'
          help={t('authentication.scan-the-qrcode-instruction')}
          >
          <Image
            width={228}
            src={totpKeyResponse?.keyImage}
          />
          <div>{t('authentication.TOTP Key')}: {totpKeyResponse?.secret}</div>
        </Form.Item>

        <Form.Item label={t('authentication.TOTP Code')}
          type='password'
          name='password'
          rules={[
            {
              required: true,
              message: t('authentication.TOTP code is required'),
            },
            // don't be so hasty to draw rejection messages
            form => ({
              validator: (rule, value) =>
                new Promise(
                  (resolve, reject) => setTimeout(() => resolve(), 800))
            }),
            {len: 6},
          ]}
        >
          <TotpInput />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }} >
          <Button
            disabled={ !isSubmitButtonVisible }
            loading={isLoading}
            htmlType='submit'
          >{t('authentication.Register')}</Button>
        </Form.Item>
    </Form>
  );
};

