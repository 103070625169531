import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal, Spin } from 'antd';
import { WritableDocumentItemView } from './DocumentItemView';
import { SiteUserSignatureForm } from './SiteUserSignatureForm';

const CertifyDocumentForm = props => {

  const { setSignatureMetadata,
          setIsModalOkButtonEnabled,
          reasons
        } = props;

  const onPasswordReady = presentSignatureMetadata => {

    setSignatureMetadata(presentSignatureMetadata);
    setIsModalOkButtonEnabled(true);
  };


  return (
      <SiteUserSignatureForm 
        onPasswordComplete={ onPasswordReady }
        onPasswordIncomplete={() => setIsModalOkButtonEnabled(false)}
        isPasswordDisabled={ false }
        signatureReasons={ reasons }
      />
      );
};

const DocumentApprovalForm = props => {

  const {

    documentItem,
    displayDocument,
    reasons,
    onPasswordComplete,
    className,
    isInlineTitle = false,
    } = props;

  const [ pspdfkitInstance, setPspdfkitInstance ] = useState(null);

  const { t } = useTranslation();

  const [ isModalDialogVisible, setIsModalDialogVisible ] = useState(false);
  const [ signatureMetadata, setSignatureMetadata ] = useState(null);
  const [ isOkButtonEnabled, setIsOkButtonEnabled ] = useState( false );
  const [ isWaiting, setIsWaiting ] = useState(false);  
  const okButtonRef = useRef();

  useEffect(() => {
    if(!isOkButtonEnabled) {
      return;
    }
    okButtonRef.current.focus();
  }, [ isOkButtonEnabled ]);

  return (
    <div className='DocumentItemView'>
      <WritableDocumentItemView 
        {...{
          documentItem,
          displayDocument,
          className,
          pspdfkitInstance,
          setPspdfkitInstance,
          ToolBar: (
            <Button
              className='document-item-header-toolbar'
              onClick={() => setIsModalDialogVisible(true)}
            >
              {t('document-task.certification-page.Certify')}
            </Button>
          ),
          isInlineTitle,
        }}
      />

    <Modal 
      visible={isModalDialogVisible}
      title={t('document-task.certification-page.Certify')}
      onCancel={() => setIsModalDialogVisible(false)}
      onOk={() => {
        setIsOkButtonEnabled(false);
        onPasswordComplete(signatureMetadata, setIsWaiting);
      }}

      okText={t('document-review.Apply Signature')}

      okButtonProps={{
        disabled: !isOkButtonEnabled,
        ref: okButtonRef,
      }}
      width={800}
      destroyOnClose={true}
    >
      <Spin spinning={isWaiting}>
        <CertifyDocumentForm
          setSignatureMetadata={setSignatureMetadata}
          setIsModalOkButtonEnabled={setIsOkButtonEnabled}
          reasons={reasons}
        />
      </Spin>  
    </Modal>

  </div>
  );  
};


export const CertificationPage = props => {

  const {

    displayDocument,
    originalFileMetadata,
    associatedEvents,
    associatedLogs,
    onPasswordComplete,
    isRedactionRequired,

    } = props;

  const { t } = useTranslation();  

  const reasons = [
   t('document-task.certification-page.I am the person who has created this copy for all pages'),
   t('document-task.certification-page.This is an exact copy of each page having all of the same attributes and information as the original'),
   t('document-task.certification-page.I have verified each page of the copy'),
   t('document-task.certification-page.My signature and the current date and time will be applied to the entire document'),

  ];
  if(isRedactionRequired) {
    reasons.push(
      t('document-task.certification-page.I have "pseudonymized" the patient identifier by associating the document with the appropriate subject identifier from the research database'),
      t('document-task.certification-page.I have masked any unnecessary private information unrelated to the trial'),
    );
  };

  return (
    <div className='CertificationPage'>
      <DocumentApprovalForm
        documentItem={{
          originalFileMetadata: originalFileMetadata,
          associatedEvents: associatedEvents,
          associatedLogs: associatedLogs,
          }}
        displayDocument={displayDocument}
        reasons={reasons}
        onPasswordComplete={onPasswordComplete}
      />
    </div>
  );
};
