import React, { useState } from 'react';

// PreferencesContext
// follows https://upmostly.com/tutorials/how-to-use-the-usecontext-hook-in-react

const PreferencesContext = React.createContext([{}, () => {}]);

const PreferencesProvider = (props) => {

  const [state, setState] = useState({});

  return (
    <PreferencesContext.Provider value={[state, setState]}>
      {props.children}
    </PreferencesContext.Provider>
  );
}

export { PreferencesContext, PreferencesProvider };

