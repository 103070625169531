import React, { useEffect, useState, useRef } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Table, Tag, Typography, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import useAppUser from '../lib/useAppUser';
import useSiteFetch from '../lib/useSiteFetch';
import documentItemsUtil from '../lib/documentItemsUtil';
import useSite from '../lib/useSite';
import useSubjectItem from '../lib/useSubjectItem';
import { VisitLabel, FormLabel } from './Labels';
import { onError } from '../lib/onError';
import 'antd/dist/antd.css';
import './ListDocumentsTask.scss';


const PAGE_SIZE = 25;
const DEFAULT_SORT_STATE = { columnKey: 'dateUpdate', order: 'descend' };

// filter by status approved
const MONITOR_FILTER = {
                         attribute: ['reviewData', 'status'],
                         operator: 'in',
                         value: ['approved']
                       };
const DEFAULT_FILTER_STATE = [ MONITOR_FILTER ];

const viewDocumentUrl = (row) => {

  const url = `/organization/${row.context.organizationId}/study/${row.context.studyId}/site/${row.context.siteId}/subject/${row.context.subjectId}/document/${row.documentId}/version/${row.version}/view`;
  return url;

};

const DataTable = props => {

  const {
    className,
    dataSource,
    loading,
    isSiteMonitor,
  } = props;

  const { t } = useTranslation();

  const ownerFromRecord = record => {
    switch (record.reviewData.status) {
      case 'pending':
        return record.reviewData.certifier;
      case 'rejected':
      case 'discarded':
      case 'approved':
        return record.reviewData.reviewer;
      default: throw new Error(`Unrecognized review status '${record.reviewData.status}'`);
    }
  };

  const columns = [];

  columns.push({
      title: t('subject.Visits'),
      dataIndex: 'associatedEvents',
      key: 'associatedEvents',

      render: items => {
        if(!items || items.length === 0) {
          return '-';
        }
        return items.map(item => (
          <Typography.Text ellipsis={true}>
            <Tag key={item.studyEventName}>
              <VisitLabel studyEventItem={item} />
            </Tag>
          </Typography.Text>));
      },
  });
  columns.push({
      title: t('subject.Logs'),
      dataIndex: 'associatedLogs',
      key: 'associatedLogs',
      render: items => {
        if(!items || items.length === 0) {
          return '-';
        }
        return items.map(item => (
          <Typography.Text ellipsis={true}>
            <Tag key={`${item.edcName} ${item.edcFormRepeatKey}`}>
              <FormLabel studyLogItem={item} />
            </Tag>
          </Typography.Text>));
      },
  });
  columns.push({
      title: t('subject.Date'),
      dataIndex: ['dateCreate'],
      key: 'created',
      render: text => t('subject.formattedDate', { date: text }),
  });
  if(!isSiteMonitor) {
    columns.push({
      title: t('subject.versions.Owner'),
      key: 'owner',
      sorter: (a, b) => ownerFromRecord(a) < ownerFromRecord(b) ? -1 : 1,
      render: (text, record) => ownerFromRecord(record),
    });
  } // !isSiteMonitor
  columns.push({
      title: t('subject.versions.Status'),
      key: 'status',
      dataIndex: ['reviewData', 'status'],
      render: text => t(`subject.versions.review-status.${text}`),
  });
  columns.push({
      title: t('subject.Version'),
      key: 'version',
      render: (text, record) => {
      return <Link to={viewDocumentUrl(record)} >
        {record.version}
      </Link>}, 

  });

  return (
    <Table id='versionsTable'
           className={className}
           dataSource={dataSource}
           columns={columns}
           rowKey={row => row.version}
           loading={loading}
           />
  );
};

export const ListDocumentsTask = props => {

  const { 
    isSiteMonitor,
  } = props;

  const { t } = useTranslation();
  const [ isLoading, setIsLoading ] = useState(true);
  const [ isExpandedRowLoading, setIsExpandedRowLoading ] = useState(true);
  const { organizationId, studyId, siteId, subjectId } = useParams();
  const { sessionData } = useAppUser();
  const { siteFetch } = useSiteFetch();
  const { siteData } = useSite();
  const [ documentItems, setDocumentItems ] = useState(null);
  const [ documentVersions, setDocumentVersions ] = useState();
  const pagingData = useRef({});
  const { subjectItem } = useSubjectItem();

  const [ currentSubjectId, setCurrentSubjectId ] = useState(null);
  const [ currentPageNumber, setCurrentPageNumber ] = useState(1);
  const pageSize = PAGE_SIZE;
  const [ totalItemCount, setTotalItemCount ] = useState(0);
  const [ sortState, setSortState ] = useState( DEFAULT_SORT_STATE );
  const [ filterState, setFilterState ] = useState( DEFAULT_FILTER_STATE );
  const [ selectedDocumentId, setSelectedDocumentId ] = useState(0);
  const [ expandedRowKeys, setExpandedRowKeys ] = useState([]);

  useEffect(() => {

    if(isSiteMonitor) {
      setFilterState([ MONITOR_FILTER ]);
    } else {
      setFilterState(DEFAULT_FILTER_STATE);
    }

  }, [ isSiteMonitor ]);

  useEffect(() => {

    const onLoad = async () => {
      try {

        const response = await siteFetch(

          `/gui/organization/${organizationId
             }/study/${studyId
             }/site/${siteId
             }/subject/${subjectId
             }/document?max-results=${pageSize
             }&paging=${
               pagingData.current.lastKey
               ? encodeURIComponent(JSON.stringify(pagingData.current.lastKey))
               : ''
             }&sort=${

               typeof sortState.order !== 'undefined' && sortState.order !== false
                 ? encodeURIComponent(JSON.stringify(sortState))
                 : ''

             }&filter=${
               filterState.length > 0
                 ?  encodeURIComponent(JSON.stringify(filterState))
                 : ''
             }`
        );

        const json = await response.json();

        if(response.status === 200) {
          setDocumentItems(json.items);
          if(json.paging.lastKey) {
            setTotalItemCount(currentPageNumber * pageSize + 1);
          } else {
            setTotalItemCount((currentPageNumber - 1) * pageSize + json.items.length);
          }

          pagingData.current.lastKey = json.paging.lastKey;


        } else {
          const msg = `${t('api.Loading Error')}: ${response.status} ${response.statusText}: ${json.error ? json.error : ''}`;
          console.log(msg);
          onError(msg);

        }
      } catch (e) {
        onError(e);
      } finally {
        setIsLoading(false);
      }
    };

    if(subjectId !== currentSubjectId) {

      setCurrentSubjectId(subjectId);
      setCurrentPageNumber(1);
      setTotalItemCount(0);
      setDocumentItems([]);
      pagingData.current = {};
      setSortState(DEFAULT_SORT_STATE);
      setFilterState(isSiteMonitor
                      ? [ MONITOR_FILTER ] : DEFAULT_FILTER_STATE);
      return;

    }

    if(currentPageNumber === 1) {
      pagingData.current = {};
    }

    setIsLoading(true);
    onLoad();

  }, [
       organizationId,
       studyId,
       siteId,
       subjectId,
       siteFetch,
       pageSize,
       currentPageNumber,
       currentSubjectId,
       sortState,
       filterState,
       isSiteMonitor,
       t,
     ]);

  useEffect(() => {

    const getVersionsPerDoc = async (key) => {
      if(!key) return;
      const keys = key;
      setIsExpandedRowLoading(true);
      try {
        let documentId = keys;
        if(documentId){
          const response = await siteFetch(
            `/gui/organization/${organizationId
               }/study/${studyId
               }/site/${siteId
               }/subject/${subjectId
               }/document/${documentId}`
          );
          setIsExpandedRowLoading(false);
          const json = await response.json();
          setDocumentVersions(json);  
        }
      } catch (e) {
        setIsExpandedRowLoading(false);
        onError(e);
      }
    };

    const onLoad = async () => {
      await getVersionsPerDoc(selectedDocumentId);
    }

    onLoad();
    
  }, [ 
    selectedDocumentId,
    siteFetch,
    organizationId,
    siteId,
    studyId,
    subjectId,
    t,
  ]);

  if(!documentItems) {
    return <Spin />;
  }

  const visitFilters = documentItemsUtil.extractVisitFilters(documentItems);
  const logFilters = documentItemsUtil.extractSubjectLogFilters(documentItems);


  const onTableRowExpand = (expanded, record) => {

    if(expanded){
      setSelectedDocumentId(record.documentId);
    }
    setExpandedRowKeys(expanded ? [ record.documentId ] : []);
    
  }
  const onTableChange = async (pagination, filters, sorter, extra) => {

    switch (extra.action) {
      case 'paginate':
        setCurrentPageNumber(pagination.current);
      break;
      case 'sort':
        setSortState({
          columnKey: sorter.columnKey,
          order: sorter.order,
          });
        setCurrentPageNumber(1);
      break;
      case 'filter':

        const requestFilters = isSiteMonitor
          ? [ MONITOR_FILTER ]: [];

        if(filters.associatedEvents && filters.associatedEvents.length > 0) {

          requestFilters.push({

            attribute: ['associatedEventNames'],
            operator: 'containsAny',
            value: filters.associatedEvents,

          });

        }

        if(filters.associatedLogs && filters.associatedLogs.length > 0) {
          requestFilters.push({

            attribute: ['associatedLogNames'],
            operator: 'containsAny',
            value: filters.associatedLogs,

          });
        }

        if(filters['reviewData.status']
           && filters['reviewData.status'].length > 0) {

          const value = filters['reviewData.status']
                        .filter(i => !i.startsWith('pending'));

          const isPendingPresent = filters['reviewData.status']
                                     .includes('pending');
          const isPendingMinePresent = filters['reviewData.status']
                                         .includes('pending-mine');

          const extra = {};
          if(isPendingPresent || isPendingMinePresent) {
            value.push('pending');

            if(!isPendingMinePresent) {
              extra['pending-exclude'] = siteData.email;
            }

            else if(!isPendingPresent) {
              extra['pending-include'] = siteData.email;
            }

          }

          requestFilters.push({

            attribute: ['reviewData', 'status'],
            operator: 'in',
            value,
            extra,

          });

        }


        setFilterState(requestFilters);
      break;
      default:
       throw new Error(`Unrecognized table action '${extra.action}'`);
    }
    

  };

  const expandedRowRender = (record, index, indent, expanded) => {
 
    if(expanded){
      if(documentVersions && documentVersions.length > 0){
        return <DataTable
          className='DocumentList'
          dataSource={documentVersions}
          loading={isExpandedRowLoading}
          isSiteMonitor={isSiteMonitor}
        />
      } else {
        return <Table></Table>
      }
    }
  };


  const columns = [];

  columns.push({
      title: t('subject.File name'),
      dataIndex: ['originalFileMetadata', 'name'],
      key: 'filename',
      render: (text, record) => {
        return (
          <Link to={viewDocumentUrl(record)}>
            {text}
          </Link>)
      }
    });

  columns.push({
      title: t('subject.Visits'),
      dataIndex: 'associatedEvents',
      width: '30%',
      // studyEventDate: 2019-03-18T04:00:00.000Z
      render: items => {
        if(!items || items.length === 0) {
          return '-';
        }
        return items.map(item =>
          <Typography.Text ellipsis={true}>
            <Tag key={item.studyEventName}>
              <VisitLabel studyEventItem={item} />
            </Tag>
          </Typography.Text>);
      },
      filters: visitFilters,

  });

  columns.push({
      title: t('subject.Logs'),
      dataIndex: 'associatedLogs',
      width: '20%',
      render: items => {

        if(!items || items.length === 0) {
          return '-';
        }

        return items.map(item =>
        <Typography.Text ellipsis={true}>
          <Tag key={`${item.edcName} ${item.edcFormRepeatKey}`}>
            <FormLabel studyLogItem={item} />
          </Tag>
        </Typography.Text>);
      },
     filters: logFilters,
  });

  if(siteData?.edcUsers?.[0]?.sites?.[0]?.isRedactionQualityControlRequired
     && !isSiteMonitor
    ) {

    columns.push({
      title: t('subject.documents.Review Status'),
      dataIndex: ['reviewData', 'status'],
      render: (text, record) =>  {

        let value = text;
        if(record.reviewData.status === 'pending'
           && record.reviewData.certifier === sessionData.email) {

          value = 'pending-mine';

        }

        if(value === 'pending') {

          const url = `/organization/${organizationId}/study/${studyId}/site/${siteId}/subject/${record.context.subjectId}/document/${record.documentId}/version/${record.version}/review`;

          return <Link to={url}>
            {t('subject.documents.review-status.pending')}
          </Link>

        }
        if(value === 'rejected' && record.reviewData.certifier === sessionData.email) {

          const url = `/organization/${organizationId}/study/${studyId}/site/${siteId}/subject/${record.context.subjectId}/document/${record.documentId}/version/${record.version}/edit`;
          return <Link to={url}>
              {t('subject.documents.review-status.rejected')}
            </Link>

        }
        return t(`subject.documents.review-status.${value}`);
      },
      filters: [
        { text: t('subject.documents.review-status.pending'),
          value: 'pending' },
        { text: t('subject.documents.review-status.pending-mine'),
          value: 'pending-mine' },
        { text: t('subject.documents.review-status.rejected'),
          value: 'rejected' },
        { text: t('subject.documents.review-status.discarded'),
          value: 'discarded' },
        { text: t('subject.documents.review-status.approved'),
          value: 'approved' },
      ],
      defaultFilteredValue: ['approved'],
    });
  }

  columns.push({
      title: t('subject.Date'),
      dataIndex: ['dateUpdate'],
      key: 'dateUpdate',
      render: text => t('subject.formattedDate', { date: text }),
      sorter: true,
      sortOrder: sortState.columnKey === 'dateUpdate' ? sortState.order : false,

    });

  if(siteData?.edcUsers?.[0]?.sites?.[0]?.isRedactionQualityControlRequired
     && !isSiteMonitor) {

    columns.push({
      title: t('subject.Version'),
      key: 'version',
      dataIndex: 'version',
      className: 'version',
    });

  }

  return (
    <Table 
      className='ListDocumentsTask'
      dataSource={documentItems}
      loading={isLoading}
      columns={columns}

      expandedRowRender={expandedRowRender}
      expandedRowKeys={expandedRowKeys}
      expandIconColumnIndex={
        siteData?.edcUsers?.[0]?.sites?.[0]?.isRedactionQualityControlRequired
        && !isSiteMonitor
        ?  5 : -1
        }
      onExpand={onTableRowExpand}
      expandIconAsCell={false}

      rowKey={row => row.documentId}
      onChange={onTableChange}
      pagination={{
        current: currentPageNumber,
        pageSize,
        total: subjectItem?.documentData?.documentCount || totalItemCount,
        hideOnSinglePage: subjectItem?.documentData?.documentCount
                            !== undefined ? true : false,
        }}
      >
    </Table>                 
  );
};
