import React, {useState, useEffect, useRef} from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Table, PageHeader, Input, Button, Space, } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import useFetch from '../lib/useFetch';
import useStudies from '../lib/useStudies';

import { onError } from '../lib/onError';

import 'antd/dist/antd.css';
import './Study.scss';

const PAGE_SIZE = 25;
const DEFAULT_SORT_STATE = { columnKey: 'siteName', order: 'ascend' };
const DEFAULT_FILTER_STATE = [];

const SiteNameFilterDropdown = (props) => {

  const { setSelectedKeys, selectedKeys, confirm, clearFilters } = props;
  const { t } = useTranslation();

  return (
    <div className='site-name-filter-dropdown'>
      <Input
        style={{ width: 188, marginBottom: 8, display: 'block' }}
        placeholder={`${t('study.sites.Names')} ...`}
        value={selectedKeys[0]}
        onChange={(e) =>
          setSelectedKeys(e.target.value ? [e.target.value] : [])
        }
        onPressEnter={confirm}
      />
      <Space>
        <Button
          type='primary'
          icon={<SearchOutlined />}
          size='small'
          style={{ width: 90 }}
          onClick={() => confirm({ closeDropdown: false })}
        >
          {t('study.sites.Search')}
        </Button>
        <Button size='small' style={{ width: 90 }} onClick={clearFilters}>
          {t('study.sites.Clear')}
        </Button>
      </Space>
    </div>
  );
};

const SiteTable = props => {

  const { studyItem } = props;
  const { organizationId, studyId } = useParams();

  const { t } = useTranslation();

  const { authenticatedFetch } = useFetch();

  const pagingData = useRef({});

  const [ currentPageNumber, setCurrentPageNumber ] = useState(1);
  const [ siteItems, setSiteItems ] = useState([]);
  const [ isLoading, setIsLoading ] = useState(true);
  const [ sortState, setSortState ] = useState(DEFAULT_SORT_STATE);
  const [ filterState, setFilterState ] = useState(DEFAULT_FILTER_STATE);

  const pageSize = PAGE_SIZE;

  const studyPath = `/organization/${studyItem.organization.organizationId}/study/${studyItem.studyId}`;

  useEffect( () => {

    const loadData = async () => {

      setIsLoading(true);
      try {

        const response = await authenticatedFetch(
          `/gui/organization/${organizationId
                     }/study/${studyId}/site?max-results=${pageSize
          }&paging=${
            pagingData.current.lastKey
              ? encodeURIComponent(JSON.stringify(pagingData.current.lastKey))
              : ''
          }&sort=${
            typeof sortState.order !== 'undefined' && sortState.order !== false
              ? encodeURIComponent(JSON.stringify(sortState))
              : ''
          }&filter=${
            filterState.length > 0
              ? encodeURIComponent(JSON.stringify(filterState))
              : ''
          }`);

        const json = await response.json();
 
        if(response.status === 200) {
          setSiteItems(json.items);
          pagingData.current.lastKey = json.paging.lastKey;
        } else {
          const msg = `Upload error: ${response.status} ${response.statusText}: ${json.error ? json.error : ''}`;
          console.log(msg);

        }

      } catch (e) {
        console.trace(e);
        onError(e);
      } finally {
        setIsLoading(false);
      }

    };


    if(currentPageNumber === 1) {
      pagingData.current = {};
    }

    loadData();
  }, [
       organizationId,
       studyId,
       pageSize,
       currentPageNumber,
       sortState,
       filterState,
       authenticatedFetch,
     ]);

  const onTableChange = async (pagination, filters, sorter, extra) => {
    /*
     pagination: {current: 2, pageSize: 3, total: 5}
     filters: { associatedEvents: null, associatedLogs: null }
     */
    switch (extra.action) {
      case 'paginate':
        setCurrentPageNumber(pagination.current);
        break;
      case 'sort':
        setSortState({
          columnKey: sorter.columnKey,
          order: sorter.order,
        });
        setCurrentPageNumber(1);
        break;
        case 'filter':
          const requestFilters = [];
          if (filters.siteName && filters.siteName.length > 0) {
            requestFilters.push({
              attribute: ['siteName'],
              operator: 'begins_with',
              value: filters.siteName[0],
            });
          }
  
          setFilterState(requestFilters);
  
          break;  
      default:
        throw new Error(`Unrecognized table action '${extra.action}'`);
    }
  };

  
  const columns = [
    {
      title: t('study.Site Name'),
      dataIndex: 'siteName',
      key: 'siteName',
      defaultSortOrder: 'ascend',
      sorter: true,
      sortOrder:
        sortState.columnKey === 'siteName'
            ? sortState.order
            : false,
      render: ( text, record ) => {

        if(!record?.isSiteActive) {
          return `${text} (${t('study.inactive')})`;
        }

        return (
        <Link
          to={`${studyPath}/site/${record.siteId}/subject`} >
          {text}
        </Link>
        );
      },
      filterDropdown: (props) => <SiteNameFilterDropdown {...props} />,
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      
    },
    {
      title: t('study.Subject Count'),
      dataIndex: [ 'subjectsCount' ],
      key: 'subjectsCount',
    },
    {
      title: t('study.Last Approval Date'),
      dataIndex: ['documentData','dateOfLastApprovedDocument'],
      key: 'dateOfLastApprovedDocument',
      sorter: true,
      sortOrder:
        sortState.columnKey === 'dateOfLastApprovedDocument'
            ? sortState.order
            : false,
      render: value => !value || value === 0
                ? '-' 
                : t('study.formattedDate', { date: value}),
    },
  ];

  return (
    <Table
      className={props.className}
      dataSource={siteItems}
      loading={isLoading}
      rowKey={(row) => row.siteId}
      columns={columns}
      onChange={onTableChange}
      pagination={{
        current: currentPageNumber,
        pageSize,
        total: studyItem.specificEdcUserView.sitesCount,
        hideOnSinglePage: true,
      }}
    />
  );
};

export default function Study() {
  
  const { t } = useTranslation();
  const { organizationId, studyId } = useParams();
  
  const { authenticatedFetch } = useFetch();
  const { isStudiesContextLoaded, studyData } = useStudies();

  const downloadAudit = async () => {

    try {

      const response = await authenticatedFetch(
        `/gui/organization/${organizationId}/study/${studyId}/audit`);

      const buffer = await response.arrayBuffer();
      var blob = new Blob([buffer], { type: 'application/vnd.ms-excel' });
      const objectUrl = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = objectUrl;
      a.style = 'display: none';
      a.download = `studyaudittrail${(new Date()).toISOString().replace(/:/g, '-')}.xlsx`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(objectUrl);
      document.body.removeChild(a);      

    } catch (e) {
      console.trace(e);
      onError(e);
    }

  };

  const renderStudy = studyData => (
    <div className='content'>
      <div className='study-page-header-row'>
          <PageHeader
            className='study-page-header'
            title={studyData.studyName}
          />
          <Button
            className='audit-button'
            onClick={() => downloadAudit()}>
            {t('study.Download Audit Trail')}
          </Button>
      </div>    
      <SiteTable
        className='site-list-table'
        studyItem={studyData}
        sites={studyData.sites}
      />
    </div>
  );

  if(!studyData) {
    return null;
  }

  const studyIndex = studyData.findIndex(i => i.studyId === studyId);
  return (
    <div className='Study'>
      {isStudiesContextLoaded && renderStudy(studyData[studyIndex])}
    </div>

  );
}
