import { useContext, useCallback } from 'react';
import { AppUserContext } from './AppUserContext';

const adminCapabilities = [

  'admin-organization-list',
  'admin-organization-get',
  'admin-study-list',
  'admin-study-get',
  'admin-domain-list',
  'admin-domain-get',
  'admin-edcuser-list',
  'admin-site-list',
  'admin-site-get',
  'admin-appuser-get',
  'admin-site-update',
  'admin-study-update',
  'admin-organization-update',
  'admin-organization-add',
  'admin-domain-update',
  'admin-domain-add',
  'admin-domain-remove',
  'admin-study-add',
  'admin-appuser-add',
  'admin-appuser-update',
  'gui-study-refresh-cache',
  'gui-appuser-invite',
  'admin-datakey-add',
  'admin-datakey-list',
  'admin-datakey-get',
  'admin-datakey-update',
  'admin-datakey-remove',
  'gui-datakey-generate',
  'gui-datakey-versions-list',
  'gui-edcuser-site-list',
  'gui-site-update',
  'gui-study-user-access-audit',
  'gui-user-training-list',

];

const useAppUser = () => {

  const [state, setState ] = useContext(AppUserContext);

  function userHasAuthenticated(isAuthenticated) {
    //the state => {} for here appears to be critical
    //setState({ ...state, isUserAuthenticated: isAuthenticated });
    setState(state => ({ ...state, isUserAuthenticated: isAuthenticated }));
  }

  function setAccountState( accountState ) {  

    setState({
      ...state,
      accountState: {
        isRegistered: accountState.isRegistered,
        isResetRequested: accountState.isResetRequested,
        isEmailConfirmed: accountState.isEmailConfirmed,
        email: accountState.email.toLowerCase().trim(),
        authenticationType: accountState.authenticationType,
      },
    });
  }

  function setSessionData(sessionData) {

    setState({
      ...state,
      sessionData: {
        ...state.sessionData,
        email: sessionData.email,
        name: sessionData.name,
        sessionId: sessionData.sessionId,
        role: sessionData.role,
        training: sessionData.training,
      },

    });
  }

  const invalidateSession = useCallback (() => {

    setState({
      isUserAuthenticated: false,
    });

  }, [ setState ]);

  return {
    userHasAuthenticated,
    setSessionData,
    setAccountState,
    isAuthenticated: state.isUserAuthenticated ? true : false,
    sessionData: { ...state.sessionData },
    accountState: { ...state.accountState },
    invalidateSession,
    isAdminRole: state.sessionData 
      && adminCapabilities.every(
        c => state?.sessionData?.role?.capabilities.includes(c)),
    };

};

export default useAppUser;
