import React, { useState } from 'react';

// follows https://upmostly.com/tutorials/how-to-use-the-usecontext-hook-in-react

const DocumentContext = React.createContext([{}, () => {}]);

const DocumentProvider = (props) => {

  const [state, setState] = useState({isLoaded: false});
  return (
    <DocumentContext.Provider value={[state, setState]}>
      {props.children}
    </DocumentContext.Provider>
  );
}

export { DocumentContext, DocumentProvider };
