import { useRef, useEffect } from 'react';
/*

  This is a dev utility to trace why a react component is re-rendering.
  It's based on 

    https://stackoverflow.com/questions/41004631/trace-why-a-react-component-is-re-rendering#answer-51082563

  Usage:

  // Usage

  function MyComponent(props) {

    useTraceUpdate(props);
    return <div>{props.children}</div>;

  }
   
*/

const useTraceUpdate = (props, label='0') => {

  const prev = useRef(props);

  useEffect(() => {
    const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
      if (prev.current[k] !== v) {
        ps[k] = [prev.current[k], v];
      }
      return ps;
    }, {});
    if (Object.keys(changedProps).length > 0) {
      console.log(`[${label}] Changed props:`, changedProps);
    }
    prev.current = props;
  });

};
export default useTraceUpdate;
