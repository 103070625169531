import React, { useState } from 'react';
import { Input } from 'antd';


const digits = ['1','2','3','4','5','6','7','8','9','0'];
const numInputs = 6;

const TotpInput = props => {

  const { 
          // first three appear to be injected by Form
          id,
          onChange,
          value,

          key = 'totp-input',
          disabled,

          onPasswordComplete = () => {},
          onPasswordIncomplete = () => {},
        } = props;

  const [ fieldValue, setFieldValue ] = useState(value);
        
  
  const handleChange = e => {

    const otp = e.target.value;
    if(otp.length > 0) {

      let cleanValue = '';
      for(const c of otp) {
        if(digits.includes(c)) {
          cleanValue += c;
        }
      }

      if(cleanValue !== otp) {
        setFieldValue(cleanValue);
        return;
      }

      if(otp.length > numInputs) {
        setFieldValue(otp.substr(0, numInputs));
        return;
      }
    }

    setFieldValue(otp);
    onChange(otp);
    if(otp.length === numInputs){
      onPasswordComplete();
    } else {
      onPasswordIncomplete();
    }

  };

  return (
    <Input
      id={id}
      value={fieldValue}
      key={key}
      disabled={disabled}
      onChange={handleChange}
      autoFocus={true}
    />
  );
};

export default TotpInput;
