import { useContext, useCallback } from 'react';
import { DocumentContext } from './DocumentContext';

const useDocument = () => {

  const [state, setState ] = useContext(DocumentContext);

  const setDocumentItem = useCallback( documentItem => 
    setState({
      ...state,
      item: {
        ...documentItem,
        associatedLogs: documentItem?.associatedLogs || [],
        associatedEvents: documentItem?.associatedEvents || [],
      },
      isLoaded: true,
    }), [ setState, state ]);

  return {
    documentItem: state.item,
    setDocumentItem,
    isDocumentItemLoaded: state.isLoaded? true: false,
    currentDocumentId: state.item ? state.item.documentId : null, 
    currentDocumentVersion: state.item ? state.item.version : null, 
  };


};

export default useDocument;
