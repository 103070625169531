import { useContext, useCallback, useMemo } from 'react';
import { LocalityContext } from './LocalityContext';

const useLocality = () => {

  const [ state, setState ] = useContext(LocalityContext);

  const setLocality = useCallback(
                             locality => {
                               if(locality === state){
                                return;  
                               }
                               setState(locality);
                              },
                             [ state, setState ]);
  const locality = useMemo( () => {
    return state !== null
    ? JSON.parse(JSON.stringify(state))
    : null;
  },[state]);
  
  return {
    locality,
    setLocality,
  };

};

export default useLocality;
