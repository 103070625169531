import React, { useEffect, useState } from 'react';
import { ConfigProvider } from 'antd';

const AntdLocaleProvider = props => {

  const [locale, setLocale] = useState( null );
  const [direction, setDirection] = useState( 'ltr' );

  useEffect(() => {

    const importAntdLocale = async tag => {

      // We'll translate from IETF language code(*) to antd's file naming system
      // here.
      // https://en.wikipedia.org/wiki/IETF_language_tag


      switch (tag) {
        case 'fr':
        case 'fr-FR':
          setDirection('ltr');
          return import ('antd/lib/locale/fr_FR');
        case 'en-GB':
          setDirection('ltr');
          return import ('antd/lib/locale/en_GB');
        case 'en':
        case 'en-US':
          setDirection('ltr');
          return import ('antd/lib/locale/en_US');
        case 'de':
        case 'de-DE':
          setDirection('ltr');
          return import ('antd/lib/locale/de_DE');
        case 'he':
        case 'he-IL':
          setDirection('rtl');
          return import ('antd/lib/locale/he_IL');
        case 'ja':
        case 'ja-JP':
          setDirection('ltr');
          return import ('antd/lib/locale/ja_JP');
        case 'zh':
          setDirection('ltr');
          return import ('antd/lib/locale/zh_CN');
        default:
          setDirection('ltr');
          return import ('antd/lib/locale/en_US');

      }

    };

    const initializeAntd = async tag => {

      const locale = await importAntdLocale(tag);
      setLocale(locale.default);

    };

    initializeAntd(navigator.language);

  }, [ ]);

  if( !locale ) {
    return null;
  }

  return (
    <ConfigProvider locale={locale} direction={direction}>
       {props.children}
    </ConfigProvider>
  );

};

export default AntdLocaleProvider;
