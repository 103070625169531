const extractVisitFilters = documentItems => {

  if (
    !documentItems
    || documentItems.length === 0
  ) {
    return [];
  }

  return [
    ...new Set(
      documentItems.flatMap((e) =>
        e?.associatedEvents?.map((e1) => e1.studyEventName)
      )
    ),
  ]
    .sort((a, b) => (a < b ? -1 : 1))
    .map((e) => ({ text: e, value: e }));

};

const extractSubjectLogFilters = documentItems => {
  
  if (
    !documentItems
    || documentItems.length === 0
  ) {
    return [];
  }


  const map = documentItems.reduce((acc, value) => {

    const stepResult = { ...acc };
    value?.associatedLogs?.forEach(e => {
      const text = e.edcName;
      stepResult[text] = {
        text: text,
        value: text,
      };
    });
    return stepResult;

  }, {});

  return Object.values(map)
               .sort((a, b) => a.text < b.text ? -1: 1);

};

const documentItemsUtil = {
  extractVisitFilters,
  extractSubjectLogFilters,
};
export default documentItemsUtil;
