import React, { useState, useEffect, useRef, useCallback, useMemo} from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useSubjectItem from '../lib/useSubjectItem';
import useSite from '../lib/useSite';
import useSiteFetch from '../lib/useSiteFetch';
import { onError } from '../lib/onError';
import {
  Layout,
  Descriptions,
  Card,
  Button,
  Space,
  PageHeader,
  Tooltip,
  Tag,
  Form,
  Input,
  Modal,
  Spin,
} from 'antd';
import {

  LockOutlined,
  FileDoneOutlined,
  FileProtectOutlined,
  LikeOutlined,
  CloseOutlined,

} from '@ant-design/icons';
import PSPDFKit from 'pspdfkit';
import { PSPDFKIT_LICENSE_KEY } from './pspdfkit/PspdfkitInstance';
import { DocumentViewer } from './pspdfkit/DocumentViewer';
import { VisitLabel, FormLabel } from './Labels';
import { SiteUserSignatureForm } from './SiteUserSignatureForm';
import 'antd/dist/antd.css';
import './DocumentItemView.scss';
import { v4 as uuidv4 } from 'uuid';

const PdfEmbeddedDigitalSignatures = props => {

  const { t } = useTranslation();
  const [ signaturesInfo, setSignaturesInfo ] = useState(null);

  useEffect(() => {

    const getSignaturesInfo = async () => {

      setSignaturesInfo(await props.pspdfkitInstance.getSignaturesInfo());

    };

    if(! props.pspdfkitInstance) {
      return;
    }
    getSignaturesInfo();

  }, [ props.pspdfkitInstance ]);

  if(!signaturesInfo) {
    return null;
  }

  return (
    <Card key='pdf-embedded-digsigs'
          title={t('document-item-view.Document Embedded Digital Signatures')}>
       <Descriptions column={1}>
         <Descriptions.Item
           label={t('document-item-view.Checked At')}>
           {t('document-item-view.formattedDate', { date: signaturesInfo.checkedAt })}
         </Descriptions.Item>
         <Descriptions.Item
           label={t('document-item-view.Status')}>
           {t(`document-item-view.document-validation-status-value.${
             signaturesInfo.status }`)
           }
         </Descriptions.Item>
         <Descriptions.Item
           label={t('document-item-view.Modified Since Signature')}>
           {t(`document-item-view.boolean.${
             signaturesInfo.documentModifiedSinceSignature ? 'yes': 'no' }`)
           }
         </Descriptions.Item>
       </Descriptions>
       {signaturesInfo.signatures.map( (s, i) => (
         <Card type='inner'
                key={`digsig-${i}`}
                title={`${t('document-item-view.Signer')} ${
                  s.signerName || t('document-item-view.unknown')}`}>
           <Descriptions column={1} size='small'>
             <Descriptions.Item
               label={t('document-item-view.Created')}>
               {t('document-item-view.formattedDate', { date: s.creationDate })}
             </Descriptions.Item>
             <Descriptions.Item
               label={t('document-item-view.Signature Validation Status')}>
               {t(`document-item-view.signature-validation-status-value.${
                 s.signatureValidationStatus}`)
               }
             </Descriptions.Item>
             <Descriptions.Item
               label={t('document-item-view.Certificate Validation')}>
               {t(`document-item-view.certificate-chain-validation-status-value.${
                 s.certificateChainValidationStatus}`)
               }
             </Descriptions.Item>
             <Descriptions.Item
               label={t('document-item-view.Document Integrity')}>
               {t(`document-item-view.document-integrity-status-value.${
                 s.documentIntegrityStatus}`)
               }
             </Descriptions.Item>
             <Descriptions.Item
               label={t('document-item-view.Trusted')}>
               {t(`document-item-view.boolean.${
                 s.isTrusted ? 'yes' : 'no'}`)
               }
             </Descriptions.Item>
             <Descriptions.Item
               label={t('document-item-view.Expired')}>
               {t(`document-item-view.boolean.${
                 s.isExpired ? 'yes' : 'no'}`)
               }
             </Descriptions.Item>
             <Descriptions.Item
               label={t('document-item-view.Location')}>
               {s.signatureLocation}
             </Descriptions.Item>
             <Descriptions.Item
               label={t('document-item-view.Signature Reason')}>
               <ul>{s.signatureReason 
                    ? s.signatureReason.split('\n')
                       .map((r, ri) => (
                  <li key={`digsig-reason-${ri}`}>{r}</li>
                  )) : ""}
               </ul>
             </Descriptions.Item>
           </Descriptions>
           
         </Card>
       ))}
    </Card>      
  );
};

const RejectionReason = props => {
  const { reason } = props;
  const { t } = useTranslation();

  return <div>
    <ul>
    {
      reason.reason.map((r, i) => <li key={`reason-${i}`}>{r}</li>)

     }
     </ul>
     {reason.detail && (
     <div>
       <b>{t('document-item-view.Detail')}</b>
       <p>
         {reason.detail}
       </p>
     </div>)}
  </div>;
};

const ReviewStatus = props => {
  const { t } = useTranslation();
  
  if(!props.reviewData) {
    return null;
  }

  return (
    <Card key={`review-status`} title={t('document-item-view.Review Status')}>
      <Descriptions column={1}>
        <Descriptions.Item label={t('document-item-view.Status')}>
          {t(`document-item-view.review-status-value.${props.reviewData.status}`)}
        </Descriptions.Item>
        { props.reviewData.certifier && (
        <Descriptions.Item label={t('document-item-view.Certifier')}>{props.reviewData.certifier}</Descriptions.Item>
        )}
        { !props.reviewData.certifier && (
        <Descriptions.Item label={t('document-item-view.Certifier')}>N/A</Descriptions.Item>
        )}
        { props.reviewData.reviewer && (
          <Descriptions.Item label={t('document-item-view.Reviewer')}>{props.reviewData.reviewer}</Descriptions.Item>
        )}
        { props.reviewData.rejectionReason && (
          <Descriptions.Item label={t('document-item-view.Rejection Reason')}>
            <RejectionReason 
              reason={props.reviewData.rejectionReason}
            /></Descriptions.Item>
        )}
      </Descriptions>
    </Card>
  );
}

const DocumentIntegrityStatusIcon = props => {
  const { isValid, } = props;
  const { t } = useTranslation();
  if(!isValid) {
    return null;
  }
  return <Tooltip title={t('document-item-view.Document Valid')}><LockOutlined /></Tooltip>
}

const DocumentSignedIcon = props => {
  const { isValid, } = props;
  const { t } = useTranslation();
  if(!isValid) {
    return null;
  }
  return <Tooltip title={t('document-item-view.Signature Valid')}><FileDoneOutlined /></Tooltip>
}

const DocumentRedactedIcon = props => {
  const { isRedactionRequired, isValid, } = props;
  const { t } = useTranslation();
  if(!isRedactionRequired || !isValid) {
    return null;
  }
  return <Tooltip title={t('document-item-view.Document Redacted')}><FileProtectOutlined /></Tooltip>
}

const RevokeApprovalForm = props => {

  const { onRevokeApproval, prepareSubmission, setIsModalOkButtonEnabled } = props;
  const { t } = useTranslation();
  const { TextArea } = Input;
  const [ form ] = Form.useForm();
  const [ isSubmitButtonVisible, setIsSubmitButtonVisible ] = useState(false);

  const onPasswordComplete = async presentSignatureMetadata => {

    try {
      await form.validateFields();

      prepareSubmission({
        function: onRevokeApproval,
        args: [
          presentSignatureMetadata,
          form.getFieldsValue(true),
        ],
      });
      setIsModalOkButtonEnabled(true);


    } catch (e) {

      // no-op

    }
  };

  const signatureReasons = [
    t('document-review.I revoke approval for remote monitoring'),
  ];

  const isValidForm = () => {
      return form.getFieldValue('detail') 
             && form.getFieldValue('detail').length > 0;
  };


  return (
  <div>
    <Form
      form={form}
      onFieldsChange={(c, a) => {
        setIsSubmitButtonVisible(isValidForm());
      }}
    >
      <Form.Item
        name='detail'
        rules={[{
          required: true,
          message: t('document-review.Detail is required for approval revocation'),
        }]}

        >
        <TextArea
          rows={1}
          placeholder={t('document-review.Add detail to help the reviewer')}
        >
        </TextArea>
      </Form.Item>
      
    </Form>
    <h3>{t('document-review.Signature')}</h3>
    <SiteUserSignatureForm
      onPasswordComplete={onPasswordComplete}
      onPasswordIncomplete={() => setIsModalOkButtonEnabled(false)}
      isPasswordDisabled={ !isSubmitButtonVisible }
      signatureReasons={signatureReasons}
    />
    </div>
    
  );
};


const DocumentApprovedIcon = props => {

  const { isValid } = props;
  const { t } = useTranslation();

  if(!isValid) {
    return null;
  }

  return (
    <Tooltip title={t('document-item-view.Approved')}>
      <LikeOutlined/ >
    </Tooltip>
    );
}

const DocumentStatusIconBar = props => {

  const { pspdfkitInstance, reviewData, } = props;

  const [ signaturesInfo, setSignaturesInfo ] = useState(null);
  const { siteData } = useSite();

  const site = siteData.edcUsers[0].sites[0];

  const isRedactionRequired =
      typeof site.isRedactionRequired !== 'undefined'
      ? site.isRedactionRequired
      : true;  

  useEffect(() => {

    const getSignaturesInfo = async () => {

      setSignaturesInfo(await pspdfkitInstance.getSignaturesInfo());

    };

    if(! pspdfkitInstance) {
      return;
    }
    getSignaturesInfo();

  }, [ pspdfkitInstance ]);

  if(!signaturesInfo) {
    return null;
  }

  return <div className='DocumentStatusIconBar'>
    <Space className='container'>
      <DocumentSignedIcon isValid={signaturesInfo.status === 'valid'} />
      <DocumentIntegrityStatusIcon isValid={
        signaturesInfo.status === 'valid'
        && !signaturesInfo.documentModifiedSinceSignature
        }/>
      <DocumentRedactedIcon
        isRedactionRequired={isRedactionRequired}
        isValid={true}
        />
      <DocumentApprovedIcon
        isValid={reviewData && reviewData.status === 'approved'}
        />
    </Space>  
    </div>;
};

export const DocumentItemView = props => {
  const chunkSize = 512 * 1024; // 256 kiB
  const { documentItem, displayDocument, className, isInlineTitle= true } = props;
  const { organizationId, studyId, siteId, subjectId } = documentItem?.context || {};
  const [ pspdfkitInstance, setPspdfkitInstance ] = useState(null);
  const { subjectItem } = useSubjectItem();
  const [ subjectName, setSubjectName ] = useState(null);

  const [ timer, setTimer ] = useState(null);
  const isAuditLogged = useRef(false);
  const lastPage = useRef(0);
  const { siteData } = useSite();

  const { t } = useTranslation();
  const history = useHistory();
  const { siteFetch } = useSiteFetch();

  const [ isModalDialogVisible, setIsModalDialogVisible ] = useState(false);
  const [ preparedSubmission, setPreparedSubmission ] = useState(null);
  const [ isOkButtonEnabled, setIsOkButtonEnabled ] = useState( false );
  const [ isWaiting, setIsWaiting ] = useState( false );

  const okButtonRef = useRef();

  const { documentId } = documentItem ? documentItem : {documentId: ''};

  // chunking from https://github.com/y3n3rrr/LargeFileUpload/blob/master/src/App.js
  const counter = useRef(0)
  const [fileToBeUpload, setFileToBeUpload] = useState({})
  const beginningOfTheChunk = useRef(0)
  const endOfTheChunk = useRef(chunkSize)
  const [progress, setProgress] = useState(0)
  const [fileGuid, setFileGuid] = useState("")
  const fileSize = useRef(0)
  const [chunkCount, setChunkCount] = useState(0)
  const [presentSignatureMetadata, setPresentSignatureMetadata] = useState();
  const [rejectionReason, setRejectionReason ] = useState();

  const resetChunkProperties = useCallback( (e) => {
    counter.current = 0;
    beginningOfTheChunk.current = 0
    endOfTheChunk.current = chunkSize
  },[chunkSize])

  const getFileContext = useCallback( (e) => {
    resetChunkProperties();
    const _file = e.blob;
    

    const _totalCount = _file.size % chunkSize === 0 ? _file.size / chunkSize : Math.floor(_file.size / chunkSize) + 1; // Total count of chunks will have been upload to finish the file
    setChunkCount(_totalCount)

    
    const _fileID = uuidv4() + '-' + documentId;
    setFileGuid(_fileID);
    fileSize.current = _file.size;
    setFileToBeUpload(_file)
  },[documentId, chunkSize, resetChunkProperties])


  const uploadCompleted = useCallback(() => {
    const upload = async () => {
      setIsWaiting(true);
      const url = `/gui/organization/${organizationId
      }/study/${studyId
      }/site/${siteId
      }/subject/${subjectId
      }/document/${documentId
      }/version/${documentItem.version
      }/revoke-approval`;
      
      const formData = new FormData();
      formData.append('fileName', fileGuid);
      formData.append('uploadSource', new Blob([null], { type: 'application/pdf' }), documentItem.originalFileMetadata.name);
      const site = siteData.edcUsers[0].sites[0];
      const newDocumentItem = {
        ...documentItem,
        reviewData: {
          ...documentItem.reviewData,
          status: site?.isRedactionQualityControlRequired ? 'pending' : 'discarded',
          rejectionReason: {
            detail: rejectionReason.detail,
            reason: [ 'approval-revocation' ],
            isDiscardDocument: site?.isRedactionQualityControlRequired ? false : true,
          },
        },
  
        signatureMetadata: [
          ...documentItem.signatureMetadata,
          presentSignatureMetadata,
        ],
      };
  
      formData.append('documentItem', JSON.stringify(newDocumentItem));
  
      try {
  
        const response = await siteFetch(url, {
  
          method: 'PATCH',
          headers: {
            TZ: (new Intl.DateTimeFormat()).resolvedOptions().timeZone
          },
          body: formData,
  
        });
        
  
        if( response.status === 200 ) {
          history.push(`/organization/${organizationId}/study/${studyId}/site/${siteId}/document`);
          setIsModalDialogVisible(false);
        } else {
  
          const json = await response.json();
  
          // we can get 401 if the code is fatfingered/late or if the
          // session has expired.
          // if code is fatfingered / late, json.error is 'Unauthorized'
          // if session has expired, json.error is 'Session lost' or 'Session
          // expired'
          if(response.status === 401
              && (json.error === 'Session expired'
                 || json.error === 'Session lost'
                 )
              ) {
            onError(`${t('api.401 Unauthorized')}: '${t('api.' + json.error)}'`);
            return;
          }
  
          const msg = `${t('api.Upload Error')}: ${response.status} ${response.statusText}: ${json.error ? json.error : ''}`;
          console.log(msg);
          onError(msg + (response.status === 401 ? ': ' + t('api.try again'): ''));
  
        }
  
      } catch (e) {
        setIsWaiting(false);
        console.log({ e });
        onError(e);
      }
    }
    upload();
  },[    
    fileGuid,
    organizationId,
    siteId,
    studyId,
    subjectId,
    t,
    history,
    documentId,
    documentItem,
    rejectionReason,
    presentSignatureMetadata,
    siteData,
    siteFetch,
  ]);

  const uploadChunk = useCallback( async (chunk) => {
    
    if(fileGuid === 0) return;
    setIsWaiting(true);
    try {
      const formData = new FormData();
      const blob = new Blob([chunk], { type: 'application/pdf' });
      formData.append('uploadSource', blob, documentId);        
      formData.append('flowChunkNumber', counter.current);
      formData.append('flowChunkSize', chunkSize);
      formData.append('flowTotalSize', fileSize.current);
      formData.append('flowIdentifier', fileGuid);
      formData.append('flowFilename', documentId);
      formData.append('singleChunk', chunkCount === 1 ? true : false);
      formData.append('flowTotalChunks', chunkCount);

      const site = siteData.edcUsers[0].sites[0];

      const newDocumentItem = {
        ...documentItem,
        reviewData: {
          ...documentItem.reviewData,
          status: site?.isRedactionQualityControlRequired ? 'pending' : 'discarded',
          rejectionReason: {
            detail: rejectionReason.detail,
            reason: [ 'approval-revocation' ],
            isDiscardDocument: site?.isRedactionQualityControlRequired ? false : true,
          },
        },
  
        signatureMetadata: [
          ...documentItem.signatureMetadata,
          presentSignatureMetadata,
        ],
      };
  
      newDocumentItem.signatureMetadata[0] = newDocumentItem.signatureMetadata[newDocumentItem.signatureMetadata.length - 1];
      console.log('DocItemView')
      console.log({newDocumentItem})

      formData.append('documentItem', JSON.stringify(newDocumentItem));
    const response = await siteFetch(`/gui/organization/${organizationId}/study/${studyId}/site/${siteId}/subject/${subjectId}/document/chunk`, {
        method: 'POST',
        headers: {
          TZ: (new Intl.DateTimeFormat()).resolvedOptions().timeZone,
        },
        body: formData, // body
      });

      const data = await response.json();
      if (data.isSuccess) {
        beginningOfTheChunk.current = endOfTheChunk.current;
        endOfTheChunk.current = endOfTheChunk.current + chunkSize;
        if (counter.current === chunkCount) {
          uploadCompleted();
        } else {
          let percentage = (counter.current / chunkCount) * 100;
          setProgress(percentage);
        }
      } else {
        setIsWaiting(false);
        console.trace('Error Occurred:', data.errorMessage)
        if(response.status === 401
          && (data.error === 'Session expired'
            || data.error === 'Session lost'
            )
          ) {
          onError(`${t('api.401 Unauthorized')}: '${t('api.' + data.error)}'`);
          return;
        }

        const msg = `${t('api.Upload Error')}: ${response.status} ${response.statusText}: ${data.error ? data.error : ''}`;
        onError(msg + (response.status === 401 ? `: ${t('api.try again')}`: ''));
                
      }
    } catch (error) {
      console.log('error', error);
      setIsWaiting(false);
    }
  },[
    counter, 
    chunkCount, 
    endOfTheChunk, 
    organizationId,
    siteId,
    studyId,
    subjectId,
    uploadCompleted,
    fileGuid,
    chunkSize,
    documentId,
    documentItem,
    presentSignatureMetadata,
    rejectionReason,
    siteData,
    t,
    siteFetch,
  ]);



  useEffect(() => {
    const fileUpload = async () => {
      counter.current = counter.current + 1;
      if (counter.current <= chunkCount) {
        let chunk = fileToBeUpload.slice(beginningOfTheChunk.current, endOfTheChunk.current);
        await uploadChunk(chunk)
      }
    };
      if (fileSize.current > 0 && fileToBeUpload && fileToBeUpload.size > 0 ) {
        fileUpload();
      }
  }, [
    fileToBeUpload, 
    progress, 
    counter, 
    beginningOfTheChunk, 
    chunkCount, 
    endOfTheChunk, 
    uploadChunk
  ])

  // end chunking

  const passwordVerify = useCallback( async (presentSignatureMetadata) => {
    const url = `/gui/organization/${organizationId}/study/${studyId}/site/${siteId}/subject/${subjectId}/verify`;
    const formData = new FormData();
    let signatureMetadataItem = {
      signatureMetadata: [ presentSignatureMetadata ],
    }

    for (let key in signatureMetadataItem) {
      if (key === "signatureMetadata") {
        let tmpItem = signatureMetadataItem[key];
        tmpItem[0] = tmpItem[tmpItem.length - 1];
        
      }
    }
    formData.append('documentItem', JSON.stringify(signatureMetadataItem));

      const response = await siteFetch(url, {

        method: 'PATCH',
        headers: {
          TZ: (new Intl.DateTimeFormat()).resolvedOptions().timeZone,
        },
        body: formData,

      });
      console.log('verify response', response)
      return response;

  },[organizationId, siteId, studyId, subjectId, siteFetch])

  const auditTrail = useCallback (async (closeMessage) => {
    if(!organizationId){
      return;
    }
    if(!documentItem) {
      return;
    }
    if(isAuditLogged.current){
      return;
    }

    let page = lastPage.current + 1;
    isAuditLogged.current = true;
    const auditData = {
      documentItem: {
        context: documentItem.context,
        name: documentItem.uploadSource.originalname,
        pageViewed: page,
        closeMessage,
        subjectKey: subjectName
      },
    };
    await siteFetch(
      `/gui/organization/${organizationId}/study/${studyId}/site/${siteId}/subject/${subjectId}/document/${documentId}/version/${documentItem.version}/audit`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          TZ: (new Intl.DateTimeFormat()).resolvedOptions().timeZone,
        },
        body: JSON.stringify(auditData),
      }
    );
  
  }, [lastPage, organizationId, studyId, siteId, subjectId, documentId, documentItem, subjectName, siteFetch]);

  
  useEffect( () => {

    if(subjectItem){
      setSubjectName(subjectItem.edcSubjectKey);
    }
    return async() => {
      if(subjectName){
        await auditTrail('close document');
      }
    };

  },[subjectItem, auditTrail, subjectName])

  useEffect(() => {
    if(!preparedSubmission || !isOkButtonEnabled) {
      return;
    }
    okButtonRef.current.focus();
  }, [ preparedSubmission, isOkButtonEnabled ]);

  if(!documentItem) {
    return null;
  }

  if(pspdfkitInstance){
    pspdfkitInstance.addEventListener("viewState.currentPageIndex.change", (pageIndex) => {
      if(lastPage.current !== pageIndex) {
        clearTimeout(timer);
        isAuditLogged.current = false;
        lastPage.current = pageIndex;
        setTimer(setTimeout(auditTrail,2000));
      }
    });
  }


  const onRevokeApproval = async (presentSignatureMetadata,
                                  rejectionReason, 
                                  setIsWaiting
                                  ) => {
    
    setPresentSignatureMetadata(presentSignatureMetadata);
    setRejectionReason(rejectionReason);

    const drawDiscardMarks = async () => {

      let annotations = [];

      for (let pageNum = 0; pageNum < pspdfkitInstance.totalPageCount; pageNum++) {

        const pageInfo = pspdfkitInstance.pageInfoForIndex(pageNum);
        const pageBounds = new PSPDFKit.Geometry.Rect({
            left: 0,
            top: 0,
            width: pageInfo.width,
            height: pageInfo.height,
        });

        const l1 = new PSPDFKit.Annotations.LineAnnotation({
          pageIndex: pageNum,
          startPoint: new PSPDFKit.Geometry.Point({ x: 0, y: 0 }),
          endPoint: new PSPDFKit.Geometry.Point({ x: pageInfo.width,  y: pageInfo.height}),
          strokeWidth: 8,
          strokeColor: PSPDFKit.Color.RED,
          boundingBox: pageBounds,
          opacity: 0.7,
        });

        const l2 = new PSPDFKit.Annotations.LineAnnotation({
          pageIndex: pageNum,
          startPoint: new PSPDFKit.Geometry.Point({ x: 0, y: pageInfo.height }),
          endPoint: new PSPDFKit.Geometry.Point({ x: pageInfo.width,  y: 0}),
          strokeWidth: 8,
          strokeColor: PSPDFKit.Color.RED,
          boundingBox: pageBounds,
          opacity: 0.7,
        });

        const stamp = new PSPDFKit.Annotations.StampAnnotation({
          pageIndex: pageNum,
          stampType: 'Custom',
          title: t('document-review.Document Discarded'),
          subtitle: t('document-review.Not viewable by Sponsor/CRO'),
          color: new PSPDFKit.Color({ r: 0, g: 51, b: 79 }),
          boundingBox: new PSPDFKit.Geometry.Rect({ left: 10, top: 20, width: 150, height: 40 }),
          opacity: 0.7,
        });

        annotations.push(l1, l2, stamp);

      }

      return pspdfkitInstance.create(annotations);

    };

    setIsWaiting(true);

    let documentBuffer = displayDocument;


    const site = siteData.edcUsers[0].sites[0];
    if(!site?.isRedactionQualityControlRequired) {
      await drawDiscardMarks();
      documentBuffer = await pspdfkitInstance.exportPDF();
    }

    const isTotpVerified = await passwordVerify(presentSignatureMetadata);
    console.log({isTotpVerified})
    if(isTotpVerified.status === 200){
      const blob = new Blob([documentBuffer], { type: "application/pdf" });
      // chunk upload the blob with getFileContext
      getFileContext({blob});  
    }else{
      // throw exception
      onError((isTotpVerified.status === 401 ? `${t('api.try again')}`: ''));
      setIsWaiting(false);
    }



  };

  const onOk = async () => {

    if(!preparedSubmission) {
      throw new Error('There is no prepared submission');
    }
    setIsOkButtonEnabled(false);
    await preparedSubmission.function(...preparedSubmission.args, setIsWaiting);
    setIsWaiting(false);
  };

  return (
    <div className='DocumentItemView'>
      <WritableDocumentItemView 
        {...{
          documentItem,
          displayDocument,
          className,
          pspdfkitInstance,
          setPspdfkitInstance,
          ToolBar: documentItem?.reviewData?.status === 'approved' && (
            <Button
              className='document-item-header-toolbar'
              onClick={() => setIsModalDialogVisible(true)}
            > 
              { t('document-review.Revoke Approval') }
            </Button>
          ),
          isInlineTitle,
        }}
      />

    <Modal 
      visible={isModalDialogVisible}
      title={t('document-review.Revoke Approval')}
      onCancel={() => setIsModalDialogVisible(false)}
      onOk={onOk }
      okText={t('document-review.Apply Signature')}

      okButtonProps={{
        disabled: !isOkButtonEnabled,
        ref: okButtonRef,
      }}
      width={800}
      destroyOnClose={true}
    >
      <Spin spinning={isWaiting}>
        <RevokeApprovalForm
          onRevokeApproval={onRevokeApproval}
          prepareSubmission={setPreparedSubmission}
          setIsModalOkButtonEnabled={setIsOkButtonEnabled}
        />
      </Spin>  
    </Modal>

  </div>
  );


};

// follows https://stackoverflow.com/questions/10420352/converting-file-size-in-bytes-to-human-readable-string/10420404#answer-20732091
const humanFilesize = size => {
  // 1000 for SI (eg kB), 1024 for IEC (eg KiB)
  const i = parseInt(size) === 0 ? 0
    : Math.floor( Math.log(size) / Math.log(1000) );
  return ( size / Math.pow(1000, i) ).toFixed(2) * 1 // * 1 converts to int
    + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
};

export const WritableDocumentItemView = props => {

  const {
    documentItem,
    displayDocument,
    className,
    pspdfkitInstance,
    setPspdfkitInstance,
    ToolBar,
    isInlineTitle = true,

  } = props;
  const { t } = useTranslation();
  const [ isMetadataVisible, setMetadataVisible ] = useState(false);

  const customActions = useMemo(() => [{
      type: "custom",
      id: "su-metadata",
      title: 'Metadata',
      onPress:  () => {
         setMetadataVisible(true);
      }
    }], []);

  if( !documentItem || !displayDocument) {
    return null;
  }

  const { Header, Content, Sider } = Layout;


  return (
    <Layout className={`DocumentItemView ${className ? className : ''}`} >
      <Header>

      { isInlineTitle && (

        <PageHeader
          className='page-title'
          title={ documentItem.originalFileMetadata.name }
        />

        ) }

        <div className='summary'>
        {

          documentItem?.associatedEvents?.map(e => (
            <Tag key={`${e.edcStudyEventOID}-${e.studyEventDate}`}>
              <VisitLabel studyEventItem={e} />
            </Tag>))
        }{        

          documentItem?.associatedLogs?.map(e => (
            <Tag key={`${e.edcName}-${e.edcFormRepeatKey}`}>
              <FormLabel studyLogItem={e} />
            </Tag>))
        }
        </div>

        {ToolBar}

      </Header>
      <Layout>

        <Content className='document'>
          <DocumentViewer 
            document={displayDocument}
            licenseKey={PSPDFKIT_LICENSE_KEY}
            baseUrl={`${window.location.protocol}//${window.location.host}/${process.env.PUBLIC_URL}`}
            setPspdfkitInstance={setPspdfkitInstance}
            customActions={customActions}
          />
          <DocumentStatusIconBar
            {...{
              pspdfkitInstance,
              reviewData: documentItem.reviewData,
            }}
          />
        </Content>

        <Sider
          className='metadata'
          collapsible={true}
          collapsed={!isMetadataVisible}
          reverseArrow={true}
          theme='light'
          width={500}
          collapsedWidth={0}
        >

        <div className='metadata-toolbar' >

        <Button
          onClick={() => setMetadataVisible(false)}
          icon={<CloseOutlined />}
          type='text'
          style={{
            padding: 0,
            margin: 0,
            height: '44px',
            lineHeight: '0px',
            textAlign: 'center',
            //float: 'right',
            }}
          />
        </div>
          <Card key='original-file-metadata'
                title={t('document-item-view.Original File Metadata')}>
            <Descriptions column={1}>
              <Descriptions.Item
                label={t('document-item-view.Name')}>
                {documentItem.originalFileMetadata.name}
              </Descriptions.Item>
              <Descriptions.Item
                label={t('document-item-view.Size')}>
                {humanFilesize(documentItem.originalFileMetadata.size)}
              </Descriptions.Item>
              <Descriptions.Item
                label={t('document-item-view.Type')}>
                {documentItem.originalFileMetadata.type}
              </Descriptions.Item>
              <Descriptions.Item
                label={t('document-item-view.Last Modified')}>
                {t('document-item-view.formattedDate', { date: documentItem.originalFileMetadata.lastModified }) }
              </Descriptions.Item>
            </Descriptions>
          </Card>
          <ReviewStatus reviewData={documentItem.reviewData}/>
          {documentItem?.associatedEvents 
           && documentItem.associatedEvents.length > 0 && (
            <Card key='associated-events'
                  title={t('document-item-view.Associated Study Events')}>
              { documentItem.associatedEvents.map(e => (
                <p key={`${e.edcStudyEventOID}-${e.studyEventDate}`}>
                  <VisitLabel studyEventItem={e} />
                </p>))
              }
            </Card>
          )}
          {documentItem?.associatedLogs
           && documentItem.associatedLogs.length > 0 && (
            <Card key='associated-logs'
                  title={t('document-item-view.Associated Patient Logs')}>
              {documentItem.associatedLogs.map(e => (
                <p key={`${e.edcName}-${e.edcFormRepeatKey}`}>
                  <FormLabel studyLogItem={e} />
                </p>))
              }
            </Card>
          )}
          <PdfEmbeddedDigitalSignatures
            pspdfkitInstance={pspdfkitInstance}
          />
        </Sider>

      </Layout>
    </Layout>

  );

};
