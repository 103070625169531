import React, { useState } from 'react';

// StudiesContext
// follows https://upmostly.com/tutorials/how-to-use-the-usecontext-hook-in-react

const StudiesContext = React.createContext([{}, () => {}]);

const StudiesProvider = (props) => {

  const [state, setState] = useState({
    isStudiesContextLoaded: false,
    });
  return (
    <StudiesContext.Provider value={[state, setState]}>
      {props.children}
    </StudiesContext.Provider>
  );
}

export { StudiesContext, StudiesProvider };
