import { useContext, useCallback } from 'react';
import { SubjectItemContext } from './SubjectItemContext';

const useSubjectItem = () => {

  const [state, setState ] = useContext(SubjectItemContext);

  const setSubjectItem = useCallback(
     subjectItem => setState(subjectItem), [ setState ]);

  return {
    subjectItem: JSON.parse(JSON.stringify(state)),
    setSubjectItem,
  };


};

export default useSubjectItem;
