import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Spin } from 'antd';
import { DocumentRedactionEditor } from './DocumentRedactionEditor';
import { CertificationPage } from './CertificationPage';
import useSiteFetch from '../lib/useSiteFetch';
import useSite from '../lib/useSite';
import useDocument from '../lib/useDocument';
import { onError } from '../lib/onError';
import {
  VisitSelector,
  FormSelector,
  DocumentEditSteps,
  } from './DocumentTask';
import 'antd/dist/antd.css';
import './EditDocumentTask.scss';
import { v4 as uuidv4 } from 'uuid';

export const EditDocumentTask = props => {
  const { subjectData, setDocumentName } = props;
  const { organizationId, studyId, siteId,
          subjectId, documentId, version } = useParams();
  const { t } = useTranslation();
  const history = useHistory();
  const { siteFetch } = useSiteFetch();
  const { siteData } = useSite();
  const { documentItem } = useDocument();

  const [ sourceFile, setSourceFile ] = useState(null);
  // maybe we will use sourceFile to reset editing process
  const [ destinationDocument, setDestinationDocument ] = useState(null);
  const [ associatedEvents, setAssociatedEvents ] = useState([]);
  const [ associatedLogs, setAssociatedLogs ] = useState([]);
  
  const [ isDataLoaded, setIsDataLoaded ] = useState(false);

   // chunking from https://github.com/y3n3rrr/LargeFileUpload/blob/master/src/App.js
   const chunkSize = 512 * 1024; // 256 kiB
   const counter = useRef(0)
   const [fileToBeUpload, setFileToBeUpload] = useState({})
   const beginningOfTheChunk = useRef(0)
   const endOfTheChunk = useRef(chunkSize)
   const [progress, setProgress] = useState(0)
   const [fileGuid, setFileGuid] = useState("")
   const fileSize = useRef(0)
   const [chunkCount, setChunkCount] = useState(0)
   const [presentSignatureMetadata,setPresentSignatureMetadata] = useState();
   const [ stepConfirm, setStepConfirm ] = useState(null);  

   const resetChunkProperties = useCallback( (e) => {
     counter.current = 0;
     beginningOfTheChunk.current = 0
     endOfTheChunk.current = chunkSize
   },[chunkSize])
 
   const getFileContext = useCallback( (e) => {
     resetChunkProperties();
     const _file = e.blob;
 
     const _totalCount = _file.size % chunkSize === 0 ? _file.size / chunkSize : Math.floor(_file.size / chunkSize) + 1; // Total count of chunks will have been upload to finish the file
     setChunkCount(_totalCount)
 
     const _fileID = uuidv4() + '-' + documentId;
     fileSize.current = _file.size;
     setFileGuid(_fileID);
     setFileToBeUpload(_file)
   },[documentId, chunkSize, resetChunkProperties])
 
 
   const uploadCompleted = useCallback(() => {
     const upload = async () => {
      setIsDataLoaded(false);
      let hash = '';
      const formData = new FormData();
      formData.append('fileName', fileGuid);
      const blob = new Blob([null], { type: 'application/pdf' });
      
      formData.append('uploadSource', blob, documentItem.originalFileMetadata.name);

      const version = documentItem.version;
      documentItem.associatedEvents = associatedEvents;
      documentItem.associatedLogs = associatedLogs;

      formData.append(
        'documentItem',
        JSON.stringify({
          ...documentItem,
          hash,
          reviewData: {
            ...documentItem.reviewData,
            status: 'pending',
          },
          signatureMetadata: [
            ...documentItem.signatureMetadata,
            presentSignatureMetadata,
          ],
        })
      );

      try {
        const response = await siteFetch(
          `/gui/organization/${organizationId}/study/${studyId}/site/${siteId}/subject/${subjectId}/document/${documentId}/version/${version}`,
          {
            method: 'PATCH',
            headers: {
              TZ: (new Intl.DateTimeFormat()).resolvedOptions().timeZone,
            },
            body: formData, // body
          }
        );
        // setIsDataLoaded(true);

        if (response.status === 200) {
          history.push(
            `/organization/${organizationId}/study/${studyId}/site/${siteId}/document`
            );
          return;
        }  else {
          const json = await response.json();
          const msg = `${t('api.Upload Error')}: ${response.status} ${response.statusText}: ${json.error ? json.error : ''}`;
          onError(msg + (response.status === 401 ? ': ' + t('api.try again'): ''));
        }

      } catch (e) {

        setIsDataLoaded(true);
        console.log(e);
        onError(e);
      } finally {
        setIsDataLoaded(true);
      }
     }
     upload();
   },[    
     fileGuid,
     organizationId,
     siteId,
     studyId,
     subjectId,
     t,
     history,
     documentId,
     documentItem,
     presentSignatureMetadata,
     associatedEvents,
     associatedLogs,
     siteFetch,
   ]);
 
   const uploadChunk = useCallback( async (chunk) => {
    setIsDataLoaded(false);
     try {
       const formData = new FormData();
       const blob = new Blob([chunk], { type: 'application/pdf' });
       formData.append('uploadSource', blob, documentId);        
       formData.append('flowChunkNumber', counter.current);
       formData.append('flowChunkSize', chunkSize);
       formData.append('flowTotalSize', fileSize.current);
       formData.append('flowIdentifier', fileGuid);
       formData.append('flowFilename', documentId);
       formData.append('singleChunk', chunkCount === 1 ? true : false);
       formData.append('flowTotalChunks', chunkCount);
 
       let hash = '';
        let docItem = {
          ...documentItem,
          hash,
          reviewData: {
            ...documentItem.reviewData,
            status: 'pending',
          },
          signatureMetadata: [
            ...documentItem.signatureMetadata,
            presentSignatureMetadata,
          ],
        };
        docItem.signatureMetadata[0] = docItem.signatureMetadata[docItem.signatureMetadata.length - 1];
       formData.append(
        'documentItem',
        JSON.stringify(docItem)
      );
    
     const response = await siteFetch(`/gui/organization/${organizationId}/study/${studyId}/site/${siteId}/subject/${subjectId}/document/chunk`, {
         method: 'POST',
         headers: {
           TZ: (new Intl.DateTimeFormat()).resolvedOptions().timeZone
         },
         body: formData, // body
       });
 
       const data = await response.json();
       if (data.isSuccess) {
         beginningOfTheChunk.current = endOfTheChunk.current;
         endOfTheChunk.current = endOfTheChunk.current + chunkSize;
         if (counter.current === chunkCount) {
           uploadCompleted();
         } else {
           let percentage = (counter.current / chunkCount) * 100;
           setProgress(percentage);
         }
       } else {
         setIsDataLoaded(true);
         if(response.status === 401
          && (data.error === 'Session expired'
            || data.error === 'Session lost'
            )
          ) {
          onError(`${t('api.401 Unauthorized')}: '${t('api.' + data.error)}'`);
          return;
        }

        const msg = `${t('api.Upload Error')}: ${response.status} ${response.statusText}: ${data.error ? data.error : ''}`;
        onError(msg + (response.status === 401 ? `: ${t('api.try again')}`: ''));

       }
     } catch (error) {
       console.log('error', error);
       setIsDataLoaded(true);
     }
   },[
     counter, 
     chunkCount, 
     endOfTheChunk, 
     organizationId,
     siteId,
     studyId,
     subjectId,
     uploadCompleted,
     fileGuid,
     chunkSize,
     documentId,
     documentItem,
     presentSignatureMetadata,
     t,
     siteFetch,
   ]);
 
 
 
   useEffect(() => {
     const fileUpload = async () => {
       counter.current = counter.current + 1;
       if (counter.current <= chunkCount) {
         let chunk = fileToBeUpload.slice(beginningOfTheChunk.current, endOfTheChunk.current);
         await uploadChunk(chunk)
       }
     };
       if (fileSize.current > 0 && fileToBeUpload && fileToBeUpload.size > 0 ) {
         fileUpload(counter.current);
       }
   }, [
     fileToBeUpload, 
     progress, 
     counter, 
     beginningOfTheChunk, 
     chunkCount, 
     endOfTheChunk, 
     uploadChunk
   ])
 
   // end chunking

   const passwordVerify = useCallback( async (presentSignatureMetadata) => {
    const url = `/gui/organization/${organizationId}/study/${studyId}/site/${siteId}/subject/${subjectId}/verify`;
    const formData = new FormData();
    let signatureMetadataItem = {
      signatureMetadata: [ presentSignatureMetadata ],
    }

    for (let key in signatureMetadataItem) {
      if (key === "signatureMetadata") {
        let tmpItem = signatureMetadataItem[key];
        tmpItem[0] = tmpItem[tmpItem.length - 1];
        
      }
    }
    formData.append('documentItem', JSON.stringify(signatureMetadataItem));

      const response = await siteFetch(url, {

        method: 'PATCH',
        headers: {
          TZ: (new Intl.DateTimeFormat()).resolvedOptions().timeZone,
        },
        body: formData,

      });
      return response;

  },[organizationId, siteFetch, siteId, studyId, subjectId])
  
  const onSignatureComplete = useCallback((presentSignatureMetadata) => {

    const onTaskDone = async presentSignatureMetadata => {
      setPresentSignatureMetadata(presentSignatureMetadata);

      const isTotpVerified = await passwordVerify(presentSignatureMetadata);
      if(isTotpVerified.status === 200){
        setIsDataLoaded(false);
        const blob = new Blob([destinationDocument], { type: 'application/pdf' });
        getFileContext({blob});  
        
      }else{
        setIsDataLoaded(true);
        onError((isTotpVerified.status === 401 ? `${t('api.try again')}`: ''));
      }
    };

    onTaskDone( presentSignatureMetadata );

  }, [
       destinationDocument,
       t,
       getFileContext,
       passwordVerify,
     ]);

  useEffect(() => {
    if(documentItem) {
      setAssociatedEvents(documentItem.associatedEvents);
      setAssociatedLogs(documentItem.associatedLogs);
      setDocumentName( documentItem.originalFileMetadata.name );
    }
  }, [
    documentItem,
    documentItem.associatedEvents,
    documentItem.associatedLogs,
    documentItem.originalFileMetadata.name,
    setDocumentName
  ]);


  useEffect(() => {

    const onLoad = async () => {
      const url = `/gui/organization/${organizationId}/study/${studyId}/site/${siteId}/subject/${subjectId}/document/${documentId}/version/${version}/download`;
      const response = await siteFetch(url, {
        headers: {
          TZ: (new Intl.DateTimeFormat()).resolvedOptions().timeZone,
        },
      });

      if(response.status !== 200) {
        const json = await response.json();
        onError(`${response.status} ${response.statusText}: '${json.error}'`);
        return;
      }

      const buffer = await response.arrayBuffer();
      setSourceFile(buffer);
      setDestinationDocument(buffer);
    };

    if (!sourceFile) {
      onLoad();
    }

  }, [
    siteFetch,
    sourceFile,
    organizationId,
    studyId,
    siteId,
    subjectId,
    documentId,
    version,
  ]);


  const isRedactionRequired = 
    siteData?.edcUsers?.[0]?.sites?.[0]?.isRedactionRequired === true;

  const steps = useMemo(() => {

    if(!destinationDocument || !sourceFile) {
      return [];
    };

    const result = [];

    // no need to hide redaction editor. If !isRedactionRequired, you can't
    // navigate here

    result.push({
        title: t('document-task.Redact'),
        isRedactStep: true,
        content: (
          <DocumentRedactionEditor
            setDestinationDocument={setDestinationDocument}
            sourceFile={sourceFile}
            isLoading={false}
            setStepConfirm={setStepConfirm}
            stepConfirm={stepConfirm}
          />
        ),
      });

    result.push({
        title: t('document-task.Associate Events'),
        content: (
          <VisitSelector
            subjectData={subjectData}
            associatedEvents={associatedEvents}
            setAssociatedEvents={setAssociatedEvents}
          />
        ),
      });

    if (subjectData.log && subjectData.log.length >= 1) {

      result.push({
        title: t('document-task.Associate Logs'),
        content: (
          <FormSelector
            subjectData={subjectData}
            associatedLogs={associatedLogs}
            setAssociatedLogs={setAssociatedLogs}
          />
        ),
      });
    } // if

    result.push({
        title: t('document-task.Certify'),
        content: (
          <CertificationPage
            displayDocument={destinationDocument}
            originalFileMetadata={documentItem?.originalFileMetadata}
            associatedEvents={associatedEvents}
            associatedLogs={associatedLogs}
            onPasswordComplete={onSignatureComplete}
            isRedactionRequired={isRedactionRequired}
            isLoading={!isDataLoaded}
          />
        ),
      });

    return result;
  }, [
    documentItem?.originalFileMetadata,
    associatedEvents,
    associatedLogs,
    destinationDocument,
    subjectData,
    sourceFile,
    onSignatureComplete,
    t,
    isRedactionRequired,
    isDataLoaded,
    stepConfirm,
    setStepConfirm,
  ]);

  if(!sourceFile
     || !documentItem
     || steps.length === 0
    ) {

    return (
      <div className='EditDocumentTask spin-holder'>
        <Spin className='spin' />
      </div>
      );
  }

  return <DocumentEditSteps
    className='EditDocumentTask'
    steps={steps}
    reviewData={documentItem.reviewData}
    setStepConfirm={setStepConfirm}
    stepConfirm={stepConfirm}
    />
};
