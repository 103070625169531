import { useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import useAppUser from './useAppUser';
import useSessionTracking from './useSessionTracking';

import { onError } from './onError';

const useFetch = () => {

  const { sessionData, invalidateSession } = useAppUser();
  const { setSessionExpiry } = useSessionTracking();
  const { t } = useTranslation();
  const fetching = useRef([]);

  const isFetching = useCallback( input => fetching.current.includes(input),[]);

  const authenticatedFetch = useCallback(async (input, init) => {

    if(fetching.current.includes(input)) {
      /*
      console.log({
        message: 'fetch: return empty',
        input,
      });
      */
      return;
    }
    fetching.current = [ ...fetching.current, input];

    try {
      
      // console.log({
      //   message: 'fetch',
      //   input: `${init?.method || 'GET'} ${input}`,
      //   fetching: fetching.current,
      // });
      //console.trace();

      const response = await fetch( `${process.env.REACT_APP_API_HOST}${input}`, 
      {
        ...init,
        headers: {
          ...(init?.headers ? init.headers : []),
          Authorization: `Bearer ${sessionData.sessionId}`
        },
      });

      if(response.status === 401) {
        const json = await response.clone().json();

        // Some clients trap a non-session 401, eg. documentReview onApprove()

        if (   json.error === 'Session expired'
            || json.error === 'Session lost'
           ) {
            onError(`${t('api.401 Unauthorized')}: '${t('api.' + json.error)}'`);
            invalidateSession();
            return response;
        }

        return response;

      } // response.status === 401
      
      const sessionExpiry = response?.headers?.get('X-ProtocolFirst-Session-Expires');
      if(sessionExpiry !== undefined) {
        setSessionExpiry(sessionExpiry);
      }

      if(response.status === 204) { // no content
        return response;
      }

      if(response.status === 500) {

        const json = await response.clone().json();
        onError(`${t('api.500 Internal Server Error')}: '${json.error}'`);
        return response;

      }

      return response;

    } finally {
      fetching.current = fetching.current.filter(e => e !== input);
    }

  }, [
    invalidateSession,
    sessionData.sessionId,
    setSessionExpiry,
    t
  ]);

  return {
    authenticatedFetch,
    isFetching,
  };

};

export default useFetch;
