import React, { useState } from 'react';

// follows https://upmostly.com/tutorials/how-to-use-the-usecontext-hook-in-react

const SubjectContext = React.createContext([{}, () => {}]);

const SubjectProvider = (props) => {

  const [state, setState] = useState({isLoaded: false});
  return (
    <SubjectContext.Provider value={[state, setState]}>
      {props.children}
    </SubjectContext.Provider>
  );
}

export { SubjectContext, SubjectProvider };
