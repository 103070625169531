import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Divider, Form, Select, Button } from 'antd';
import useFetch from '../../lib/useFetch';
import useAdmin from '../../lib/useAdmin';
import { onError } from '../../lib/onError';
import { RawData } from '../../components/AdminForms';

const config = {
  name: 'Domain',
};

const RemoveForm = props => {

  const history = useHistory();
  const { organizationId, domainId } = useParams();
  const { authenticatedFetch } = useFetch();
  const { domainItems, setDomainItems } = useAdmin();

  const removeDomain = async () => {

    const response = await authenticatedFetch(
      `/api/organization/${organizationId}/domain/${domainId}`,
      {
        method: 'DELETE',
      });

    // success status 204

    if(response.status !== 204) {
      const json = await response.json();
      onError(`Delete Error: '${json.error}'`);
      return;
    }

    const newDomainItems = JSON.parse(JSON.stringify(domainItems));

    const index = newDomainItems.findIndex(e => e.domainId === domainId);
    newDomainItems.splice(index, 1);
    history.push(`/admin/organization/${organizationId}/domain`);
    setDomainItems(newDomainItems);


  }

  return <Button onClick={removeDomain}>Remove</Button>

};

const EditForm = props => {

  const { data, setData } = props;

  const { organizationId, domainId } = useParams();
  const { authenticatedFetch } = useFetch();
  const { domainItems, setDomainItems } = useAdmin();

  const { Item } = Form;
  const { Option } = Select;

  const onFinish = async values => {

    const domainItem = {
      ...data,
      authenticationType: values.authenticationType,
    };

    const response = await authenticatedFetch(
      `/api/organization/${organizationId}/domain/${domainId}`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(domainItem),

      });

    const json = await response.json();

    if(response.status !== 200) {
      onError(`Upload Error: '${json.error}'`);
      return;
    }

    const newDomainItems = JSON.parse(JSON.stringify(domainItems));

    const index = newDomainItems.findIndex(e => e.domainId === domainId);
    newDomainItems[index] = json;
    setData(json);
    setDomainItems(newDomainItems, true);

  };



  
  return (
    <Form
      onFinish={onFinish}
      initialValues={{
        authenticationType: data.authenticationType,
      }}
      style={{ width: '600px', }}
    >

      <Item name='authenticationType' label='Type'>
        <Select style={{width: '200px'}}>
          <Option value='totp'>TOTP</Option>
          <Option value='password'>Password</Option>
        </Select>
      </Item>

      <Item>
        <Button type='primary' htmlType='submit'>Submit</Button>
      </Item>

    </Form>
  );
};

const ItemList = props => {

  const { authenticatedFetch } = useFetch();
  const { organizationId, domainId } = useParams();
  const [ data, setData ] = useState('');

  useEffect(() => {

    const loadData = async () => {

      const response = await authenticatedFetch(
        `/api/organization/${organizationId}/domain/${domainId}`);
      const json = await response.json();
      setData(json);
    };

    loadData();
  }, [
    organizationId,
    domainId,
    authenticatedFetch,
  ]);

  if(!data) {
    return null;
  }

  return (
    <div>
      <h1>{config.name} {data.domain}</h1>
      <Divider orientation='left'>Edit</Divider>

      <EditForm 
        data={data}
        setData={setData} />

      <Divider orientation='left'>Remove</Divider>

      <RemoveForm /> 

      <Divider orientation='left'>Raw</Divider>
      <RawData data={data} />

    </div>
  );
};

const AdminDomain = props => {
  return <ItemList />;
};

export { AdminDomain };
