import { useContext, useCallback } from 'react';
import { SiteContext } from './SiteContext';

const useSite = () => {

  const [state, setState ] = useContext(SiteContext);

  const setSiteData = useCallback((siteData) => {
    setState({
      ...state,
      isSiteContextLoaded: true,
      data: siteData,

    });
  }, [ setState, state ]);
  return {
    isSiteContextLoaded: state.isSiteContextLoaded,
    siteData: state.data,
    currentSiteId: state?.data?.edcUsers?.[0]?.sites?.[0]?.siteId,
    setSiteData,
  };


};

export default useSite;
