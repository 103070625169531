import React, { useEffect } from 'react';
import { useParams, Link, useLocation, useHistory } from 'react-router-dom';
import { Breadcrumb } from 'antd';
import { useTranslation } from 'react-i18next';
import useAdmin from '../lib/useAdmin';
import useFetch from '../lib/useFetch';
import { onError } from '../lib/onError';
import 'antd/dist/antd.css';

const OrganizationListBreadcrumbItem = props => {

  const { path } = props;
  const history = useHistory();
  const { setOrganizationItems, organizationItems } = useAdmin();
  const { authenticatedFetch, isFetching } = useFetch();

  const isVisible = path.length >= 2 && path[1] === 'organization';

  useEffect(() => {

    const url = '/api/organization';

    const loadData = async () => {

      const response = await authenticatedFetch(url);
      const json = await response.json();
 
      if(response.status !== 200) {

        onError(`${response.status} ${response.statusText}: '${json.error}'`);
        history.push('/');
        return;

      }
      setOrganizationItems(json);
    };

    if(!isVisible || organizationItems || isFetching( url )) {
      return;
    }

    loadData();
  }, [
       organizationItems,
       setOrganizationItems,
       history,
       authenticatedFetch,
       isFetching,
       isVisible,
     ]);

  if(path[1] !== 'organization' || !organizationItems) {
    return null;
  }

  return (
    <Breadcrumb.Item key='organizationList'>
      <Link to='/admin/organization'> Organizations </Link>
    </Breadcrumb.Item>
  );
};

const AppUserListBreadcrumbItem = props => {

  const { path } = props;

  if(path[1] !== 'user') {
    return null;
  }

  return (
    <Breadcrumb.Item key='appUserList'>
      <Link to='/admin/user'>App User</Link>
    </Breadcrumb.Item>
  );
};

const AppUserBreadcrumbItem = props => {

  const { path, params } = props;

  if(path[1] !== 'user') {
    return null;
  }

  if(!params.email) {
    return null;
  }

  return (
    <Breadcrumb.Item key='appUser'>
      <Link to={`/admin/user/${params.email}`}>{params.email}</Link>
    </Breadcrumb.Item>
  );
};

const OrganizationBreadcrumbItem = props => {

  const { path, params } = props;
  const { setOrganizationId, organizationItems, } = useAdmin();

  useEffect(() => {

    if(!organizationItems || !params.organizationId) {
      return;
    }

    setOrganizationId( params.organizationId );

  }, [ organizationItems, params.organizationId, setOrganizationId ]);

  if(path[1] !== 'organization' || !params.organizationId || !organizationItems) {
    return null;
  }

  return (
    <Breadcrumb.Item key='organization'>
      <Link to={`/admin/organization/${params.organizationId}`}>
        { 
              organizationItems
                .find(r => r.organizationId === params.organizationId)
                .name
        }
      </Link>
    </Breadcrumb.Item>
  );
};

const StudyListBreadcrumbItem = props => {

  const { path, params } = props;
  const history = useHistory();
  const { organizationItems, organizationId, studyItems, setStudyItems } = useAdmin();
  const { authenticatedFetch, isFetching } = useFetch();

  const isVisible = path.length >= 4 && path[3] === 'study';

  useEffect(() => {

    const url = `/api/organization/${params.organizationId}/study`;

    const loadData = async () => {

      const response = await authenticatedFetch(url);
      const json = await response.json();
 
      if(response.status !== 200) {

        onError(`${response.status} ${response.statusText}: '${json.error}'`);
        history.push('/admin/organization');
        return;

      }
 
      setStudyItems(json);
    };

    if(!organizationItems || !organizationId || !params.organizationId) {
      return;
    }

    if( !isVisible || studyItems || isFetching(url) ) {
      return;
    }

    loadData();
  }, [
       isVisible,
       organizationItems,
       organizationId,
       studyItems,
       setStudyItems,
       params.organizationId,
       history,
       authenticatedFetch,
       isFetching,
     ]);

  if(!isVisible | !organizationItems ) {
    return null;
  }

  return (
    <Breadcrumb.Item key='studyList'>
      <Link to={`/admin/organization/${params.organizationId}/study`}>
        { 
              `${organizationItems
                   .find(r => r.organizationId === params.organizationId)
                   .name} Studies`
        }
      </Link>
    </Breadcrumb.Item>
  );
};

const DomainListBreadcrumbItem = props => {

  const { path, params } = props;
  const history = useHistory();
  const { organizationItems, organizationId, domainItems, setDomainItems } = useAdmin();
  const { authenticatedFetch } = useFetch();

  const isVisible = path.length >= 4 && path[3] === 'domain';

  useEffect(() => {

    const url = `/api/organization/${params.organizationId}/domain`;
    const loadData = async () => {

      const response = await authenticatedFetch( url );
      const json = await response.json();

      if(response.status !== 200) {

        onError(`${response.status} ${response.statusText}: '${json.error}'`);
        history.push('/admin/organization');
        return;

      }
 
      setDomainItems(json);
    };
 
    if(!organizationItems || !organizationId) {
      return;
    }

    if(!isVisible || domainItems ) {
      return;
    }
    loadData();

  }, [
       isVisible,
       organizationItems,
       organizationId,
       domainItems,
       params.organizationId,
       setDomainItems,
       history,
       authenticatedFetch,
     ]);

  if( !isVisible || !organizationItems ) {
    return null;
  }

  return (
    <Breadcrumb.Item key='domainList'>
      <Link to={`/admin/organization/${params.organizationId}/domain`}>
        { 
              `${organizationItems
                   .find(r => r.organizationId === params.organizationId)
                   .name} Domains`
        }
      </Link>
    </Breadcrumb.Item>
  );
};

const DomainBreadcrumbItem = props => {

  const { path, params } = props;
  const { organizationItems, organizationId, domainItems } = useAdmin();

  if(!organizationItems || !organizationId) {
    return null;
  }

  if(path.length < 5 || path[3] !== 'domain' || !params.domainId || !domainItems ) {
    return null;
  }

  return (
    <Breadcrumb.Item key='domainList'>
      <Link to={`/admin/organization/${params.organizationId
                            }/domain/${params.domainId}`}>
        { 
              domainItems
                .find(r => r.domainId === params.domainId)
                .domain
        }
      </Link>
    </Breadcrumb.Item>
  );
};

const DatakeyListBreadcrumbItem = props => {

  const { path, } = props;
  const { datakeyItems, setDatakeyItems } = useAdmin();
  const { authenticatedFetch, isFetching } = useFetch();
  const isVisible = path.length >= 2 && path[1] === 'datakey';

  useEffect(() => {
    const url = '/api/datakey';
    const loadData = async () => {

      const response = await authenticatedFetch(url);

      if(response.status !== 200) {

        const json = await response.json();
        onError(`${response.status} ${response.statusText}: '${json.error}'`);
        return;

      }
 
      setDatakeyItems(await response.json());
    };
 
    if (datakeyItems || !isVisible || isFetching( url ) ) {
      return;
    }
    loadData();

  }, [
       datakeyItems,
       setDatakeyItems,
       isVisible,
       authenticatedFetch,
       isFetching,
     ]);

  if(!isVisible) {
    return null;
  }

  return (
    <Breadcrumb.Item key='datakeyList'>
      <Link to={`/admin/datakey`}>
        Data Keys
      </Link>
    </Breadcrumb.Item>
  );
};

const DatakeyBreadcrumbItem = props => {

  const { path, params } = props;
  const { datakeyItems } = useAdmin();

  if(path.length < 3 || path[1] !== 'datakey' || !params.datakeyId || !datakeyItems ) {
    return null;
  }


  return (
    <Breadcrumb.Item key='datakey'>
      <Link to={`/admin/datakey/${params.datakeyId}`}>
        { 
              datakeyItems
                .find(r => r.datakeyId === params.datakeyId)
                ?.datakeyName
        }
      </Link>
    </Breadcrumb.Item>
  );
};

const StudyBreadcrumbItem = props => {

  const { path, params } = props;
  const { organizationItems, organizationId, studyItems } = useAdmin();

  if(path.length < 5 || path[3] !== 'study' || !params.studyId ) {
    return null;
  }

  let studyItem;
  if( studyItems ) {
    studyItem = studyItems.find(r => r.studyId === params.studyId)
  }

  if(!organizationItems || !organizationId || !studyItem) {
    return null;
  }


  return (
    <Breadcrumb.Item key='study'>
      <Link to={`/admin/organization/${params.organizationId
                             }/study/${params.studyId}`}>
        { 
              studyItem.name
        }
      </Link>
    </Breadcrumb.Item>
  );
};

const StudySiteListBreadcrumbItem = props => {

  const { path, params } = props;
  const history = useHistory();
  const { organizationItems, organizationId, studyItems, siteItems, setSiteItems } = useAdmin();
  const { authenticatedFetch, isFetching } = useFetch();

  const isVisible = path.length >= 6 && path[5] === 'site' ;

  useEffect(() => {
    const url = `/api/organization/${params.organizationId
                 }/study/${params.studyId
                 }/site`;
    const loadData = async () => {

      const response = await authenticatedFetch(url);
      const json = await response.json();

      if(response.status !== 200) {

        onError(`${response.status} ${response.statusText}: '${json.error}'`);
        history.push('/admin/organization');
        return;

      }
      setSiteItems(json);
    };

    if(!organizationItems || !organizationId || !studyItems) {
      return;
    }

    if( !isVisible
        || (siteItems
            && siteItems.length > 0
            && siteItems[0].studyId === params.studyId
           )
        || isFetching(url)
      ) {
      return;
    }

    loadData();
  }, [
       isVisible,
       organizationItems,
       organizationId,
       studyItems,
       siteItems,
       params.organizationId,
       params.studyId,
       params.siteId,
       setSiteItems,
       history,
       authenticatedFetch,
       isFetching,
     ]);

  if (!isVisible || !studyItems) {
    return null;
  }

  return (
    <Breadcrumb.Item key='studySiteList'>
      <Link to={`/admin/organization/${params.organizationId}/study/${params.studyId}/site`}>
        { 
              `${studyItems
                   .find(r => params.studyId === r.studyId)
                   .name} Sites`
        }
      </Link>
    </Breadcrumb.Item>
  );
};

const StudySiteBreadcrumbItem = props => {

  const { path, params } = props;
  const { organizationItems, organizationId, studyItems, siteItems } = useAdmin();

  if(!organizationItems || !organizationId || !studyItems) {
    return null;
  }

  if(path.length < 7 || path[5] !== 'site' || !params.siteId || !siteItems ) {
    return null;
  }

  return (
    <Breadcrumb.Item key='studySite'>
      <Link
      to={`/admin/organization/${params.organizationId
                       }/study/${params.studyId
                        }/site/${params.siteId}`}>
        { 
              siteItems
                .find(r => r.siteId === params.siteId)
                .siteName
        }
      </Link>
    </Breadcrumb.Item>
  );
};

const StudyUserListBreadcrumbItem = props => {

  const { path, params } = props;
  const history = useHistory();
  const { organizationItems, organizationId, studyItems, studyUserItems, setStudyUserItems } = useAdmin();
  const { authenticatedFetch } = useFetch();

  const isVisible = path.length >= 6 && path[5] === 'user';

  useEffect(() => {

    const url = `/api/organization/${params.organizationId
                           }/study/${params.studyId
                           }/user`;
    const loadData = async () => {

      const response = await authenticatedFetch(url);
      const json = await response.json();
 
      if(response.status !== 200) {

        onError(`${response.status} ${response.statusText}: '${json.error}'`);
        history.push('/admin/organization');
        return;

      }
 
      setStudyUserItems(json);
    };

    if(!organizationItems || !organizationId || !studyItems) {
      return;
    }

    if( !isVisible
        || (studyUserItems
            && studyUserItems.length > 0
            && studyUserItems[0].studyId === params.studyId
           )
      ) {
      return;
    }

    loadData();

  }, [
       isVisible,
       organizationItems,
       organizationId,
       studyItems,
       studyUserItems,
       params.organizationId,
       params.studyId,
       setStudyUserItems,
       history,
       authenticatedFetch,
       ]);

  if( !isVisible || !studyItems ) {
    return null;
  }

  return (
    <Breadcrumb.Item key='studyUserList'>
      <Link to={`/admin/organization/${params.organizationId
                             }/study/${params.studyId}/user`}>
        { 
              `${studyItems
                   .find(r => r.studyId === params.studyId)
                   .name} Users`
        }
      </Link>
    </Breadcrumb.Item>
  );
};

export const AdminBreadcrumb = props => {

  const { t } = useTranslation();
  const params = useParams();
  const location = useLocation();
  const [ , ...segments ] = location.pathname.split('/'); // remove leading ''

  if( segments.length < 1 || segments[0] !== 'admin') {
    return null;
  }


  return (
    <Breadcrumb className={`AdminBreadcrumb ${props.className}`}>
      <Breadcrumb.Item key='home'>
        <Link to='/'> {t('navigation.Home')} </Link>
      </Breadcrumb.Item>

      <AppUserListBreadcrumbItem path={segments}/>
      <AppUserBreadcrumbItem path={segments} params={params}/>

      <OrganizationListBreadcrumbItem path={segments}/>
      <OrganizationBreadcrumbItem path={segments} params={params}/>

      <DomainListBreadcrumbItem path={segments} params={params}/>
      <DomainBreadcrumbItem path={segments} params={params}/>

      <DatakeyListBreadcrumbItem path={segments} params={params}/>
      <DatakeyBreadcrumbItem path={segments} params={params}/>

      <StudyListBreadcrumbItem path={segments} params={params}/>
      <StudyBreadcrumbItem path={segments} params={params}/>
      <StudySiteListBreadcrumbItem path={segments} params={params}/>
      <StudySiteBreadcrumbItem path={segments} params={params}/>
      <StudyUserListBreadcrumbItem path={segments} params={params}/>

    </Breadcrumb>
    );
};

// following keeps ant's devWarning quiet. 
AppUserListBreadcrumbItem.__ANT_BREADCRUMB_ITEM = true;
AppUserBreadcrumbItem.__ANT_BREADCRUMB_ITEM = true;
OrganizationListBreadcrumbItem.__ANT_BREADCRUMB_ITEM = true;
OrganizationBreadcrumbItem.__ANT_BREADCRUMB_ITEM = true;
DomainListBreadcrumbItem.__ANT_BREADCRUMB_ITEM = true;
DomainBreadcrumbItem.__ANT_BREADCRUMB_ITEM = true;
DatakeyListBreadcrumbItem.__ANT_BREADCRUMB_ITEM = true;
DatakeyBreadcrumbItem.__ANT_BREADCRUMB_ITEM = true;
StudyListBreadcrumbItem.__ANT_BREADCRUMB_ITEM = true;
StudyBreadcrumbItem.__ANT_BREADCRUMB_ITEM = true;
StudySiteListBreadcrumbItem.__ANT_BREADCRUMB_ITEM = true;
StudySiteBreadcrumbItem.__ANT_BREADCRUMB_ITEM = true;
StudyUserListBreadcrumbItem.__ANT_BREADCRUMB_ITEM = true;
