import i18n from 'i18next';
import { notification } from 'antd';


export function onError(error) {

  let message = error.toString();

  // We have an i18n puzzle here. For Error objects, error.toString() produces
  // something like `${error.name}: ${error.message}`. Even if we ensure that
  // error.message is translated upstream, error.name has a value like
  // 'Error', which resembles a word in English. Resolution is a compromise,
  // to translate the name only if it is 'Error'.

  if((error instanceof Error) && error.name === 'Error') {
    message = `${i18n.t('general.Error')}: ${error.message}`;
  }

  if (!(error instanceof Error) && error.message) {
    message = error.message;
  }

  //alert(message);
  notification.error({
    message: i18n.t('general.Error'),
    description: message,
    // duration: 4.5, // seconds; default is 4.5 seconds

  });
}
