import { useTranslation } from 'react-i18next';

export const VisitLabel = props => {

  const { studyEventItem } = props;
  const { t } = useTranslation();

  if(studyEventItem.studyEventDate) {
    return <>{studyEventItem.studyEventName
              } ({t('study event.formattedDate',
                    { date: studyEventItem.studyEventDate })})</>;
  }
  return <>{studyEventItem.studyEventName}</>;

};

export const FormLabel = props => {

  const { studyLogItem } = props;
  const { t } = useTranslation();
  return <>{studyLogItem.edcName
            } &mdash; {t('study log.Row')
            } {studyLogItem.edcFormRepeatKey}</>;
}

