import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Form,
         Radio,
         Space,
         Checkbox,
         Select,
         Button,
         Divider,
         Input,
         Popover,
         } from 'antd';
import useFetch from '../../lib/useFetch';
import useAdmin from '../../lib/useAdmin';
import { onError } from '../../lib/onError';
import { RawData } from '../../components/AdminForms';

const config = {
  name: 'Study Site',
};

const GenerateDatakeyWidget = props => {

  const { data, setData } = props;
  const { authenticatedFetch } = useFetch();
  const { resetDatakeyItems } = useAdmin();
  const [ isFormVisible, setIsFormVisible ] = useState( false );
  const { Item } = Form;
  const [ form ] = Form.useForm();

  const onFinish = async values => {

    const datakeyItem = {
      datakeyName: values.datakeyName,
    };

    const response = await authenticatedFetch('/gui/datakey/generate',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(datakeyItem),

      });

    const json = await response.json();

    if(response.status !== 201) {
      onError(`Upload Error: '${json.error}'`);
      return;
    }

    resetDatakeyItems();
    setData({ ...data, datakeyId: json.datakeyId });
    setIsFormVisible(false);

  };

  const datakeyForm = (
    <Form
      form={form}
      onFinish={onFinish} >
      <Item name='datakeyName' label='Name'>
        <Input />
      </Item>
      <Item>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Item>
    </Form>
  );

  return (
    <Popover
    content={datakeyForm}
    title='Generate Datakey'
    trigger='click'
    onVisibleChange={ isVisible => {
        if(isVisible){
          form.resetFields();
        }
    }}
    >
      <Button
        onClick={() => setIsFormVisible(!isFormVisible)}
      >Generate Datakey</Button>
    </Popover>
  );
};

const EditForm = props => {

  const { data, setData } = props;
  const { authenticatedFetch, isFetching } = useFetch();
  const { organizationId, studyId, siteId } = useParams();
  const { organizationItems, organizationId: breadcrumbOrganizationId,
          studyItems, siteItems,
          datakeyItems, setDatakeyItems, resetStudyItems } = useAdmin();
  const [ isWaiting, setIsWaiting ] = useState(false);

  const { Option } = Select;
  const { Item } = Form;

  useEffect(() => {
    const url = '/api/datakey';
    const loadData = async () => {

      const response = await authenticatedFetch(url);
      const json = await response.json();

      if(response.status !== 200) {

        onError(`${response.status} ${response.statusText}: '${json.error}'`);
        return;

      }

      setDatakeyItems(json);
    };

    // wait for breadcrumbs to load
    if( !organizationItems
        || !breadcrumbOrganizationId
        || !studyItems
        || !siteItems) {
      return;
    }
    if(datakeyItems || isFetching( url ) ) {
      return;
    }
    loadData();

  }, [
    organizationItems,
    breadcrumbOrganizationId,
    studyItems,
    siteItems,
    datakeyItems,
    setDatakeyItems,
    authenticatedFetch,
    isFetching,
  ]);


  const siteModeValue = siteItem => {

    const isRedactionQualityControlRequired =
      typeof siteItem.isRedactionQualityControlRequired !== 'undefined'
      ? siteItem.isRedactionQualityControlRequired
      : false;

    if(isRedactionQualityControlRequired) {
      return 'qc';
    }

    const isRedactionRequired =
      typeof siteItem.isRedactionRequired !== 'undefined'
      ? siteItem.isRedactionRequired
      : false;

    if(isRedactionRequired){
      return 'redact-only';
    }

    return 'basic';
  };

  const onFinish = async values => {

    const siteItem = {
      ...data,
      locality: values.locality,
      datakeyId: values.datakeyId,
      isSiteActive: values.isSiteActive,
      isSubjectApprovalRequired: values.isSubjectApprovalRequired,
      isRedactionQualityControlRequired: values.mode === 'qc',
      isRedactionRequired: values.mode !== 'basic',

    };

    setIsWaiting(true);

    const response = await authenticatedFetch(
      `/gui/organization/${organizationId
                 }/study/${studyId
                  }/site/${siteId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(siteItem),

      });

    setIsWaiting(false);

    const json = await response.json();

    if(response.status !== 200) {
      onError(`Upload Error: '${json.error}'`);
      return;
    }

    setData(json);
    resetStudyItems();
  };
 
  if( !data || datakeyItems === null) {
    return null;
  }

  return (
    <Form 
      className='AdminStudySite-EditForm'
      onFinish={onFinish}
      initialValues={{
        mode: siteModeValue(data),
        locality: data.locality ? data.locality : null,
        datakeyId: data?.datakeyId,
        isSiteActive: data?.isSiteActive === undefined
                      ? true: data.isSiteActive,
        isSubjectApprovalRequired:
          data.isSubjectApprovalRequired ? true: false,
      }}
    >

      <Item name='mode' label='Mode'>
        <Radio.Group>
          <Space>
            <Radio.Button value="basic">Basic</Radio.Button>
            <Radio.Button value="redact-only">Redact Only</Radio.Button>
            <Radio.Button value="qc">QC</Radio.Button>
          </Space>
        </Radio.Group>
      </Item>
      <Item name='locality' label='Locality'>
        <Select style={{width: '200px'}}>
          <Option key='none' value={null}></Option>
          <Option key='us' value='us'>USA</Option>
          <Option key='eu' value='eu'>EU</Option>
          <Option key='uk' value='uk'>UK</Option>
          <Option key='jp' value='jp'>Japan</Option>
        </Select>
      </Item>
      <Item label='Data Key'>
        <Space>
        <Item name='datakeyId' noStyle>
          <Select
            showSearch
            placeholder="Search to Select"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            filterSort={(optionA, optionB) =>
              optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
            }
            style={{width: '200px'}}
            >
          {
            datakeyItems.map(i => (
              <Option key={i.datakeyId} value={i.datakeyId}>{i.datakeyName}</Option>
            ))
          }
          </Select>
        </Item>
        <GenerateDatakeyWidget
          data={data}
          setData={setData}
        />
        </Space>
      </Item>
      <Item name='isSiteActive'
            label='Is Site Active'
            valuePropName='checked'
            >
          <Checkbox />
      </Item>
      <Item name='isSubjectApprovalRequired'
            label='Require Subject Approval'
            valuePropName='checked'
            hidden={true}
            >
          <Checkbox
            disabled={true}
          />
      </Item>
      <Item>
        <Button
          type='primary'
          htmlType='submit'
          loading={isWaiting}
        >Submit</Button>
      </Item>
    </Form>
  );
};

const ItemList = props => {

  const { authenticatedFetch } = useFetch();
  const { organizationId, studyId, siteId } = useParams();
  const [ data, setData ] = useState('');

  useEffect(() => {

    const url = `/api/organization/${organizationId
                           }/study/${studyId
                            }/site/${siteId}`;

    const loadData = async () => {

      const response = await authenticatedFetch( url );
      const json = await response.json();

      setData(json);
    };

    loadData();
  }, [
    organizationId,
    studyId,
    siteId,
    authenticatedFetch,
  ]);

  if(!data) {
    return null;
  }



  return (
    <div>
      <h1>{config.name} {data.siteName}</h1>

      <Divider orientation='left'>Edit</Divider>
      <EditForm 
        data={data}
        setData={setData} />

      <Divider orientation='left'>Raw</Divider>
      <RawData data={data} />
    </div>
  );
};

const AdminStudySite = props => {
  return <ItemList />;
};

export { AdminStudySite };
