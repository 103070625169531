import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { PageHeader, Table, Tag } from 'antd';
import useSiteFetch from '../lib/useSiteFetch';
import { VisitLabel, FormLabel } from './Labels';
import { onError } from "../lib/onError";
import { useTranslation } from 'react-i18next';
import './DocumentListVersions.scss';

const DataTable = props => {

  const {

    isSiteMonitor,
    loading,
    className,
    dataSource,

  } = props;
  const { t } = useTranslation();

  const ownerFromRecord = record => {
    switch (record.reviewData.status) {
      case 'pending':
        return record.reviewData.certifier;
      case 'rejected':
      case 'discarded':
      case 'approved':
        return record.reviewData.reviewer;
      default: throw new Error(`Unrecognized review status '${record.reviewData.status}'`);
    }
  };

  const columns = [];
  columns.push({
      title: t('site.documents.File name'),
      dataIndex: ['originalFileMetadata', 'name'],
      key: 'filename',
  });
  columns.push({
      title: t('site.documents.Visits'),
      dataIndex: 'associatedEvents',
      key: 'associatedEvents',
      render: items =>
      items.map(item => <Tag key={item.studyEventName}>
                          <VisitLabel studyEventItem={item}/>
                        </Tag>),
  });
  columns.push({
      title: t('site.documents.Logs'),
      dataIndex: 'associatedLogs',
      key: 'associatedLogs',
      render: items => items.map(item =>
     <Tag key={`${item.edcName} ${item.edcFormRepeatKey}`}>
       <FormLabel studyLogItem={item} />
     </Tag>),
  });
  columns.push({
      title: t('site.documents.Date'),
      dataIndex: ['dateCreate'],
      key: 'created',
      render: items => 
      t('site.documents.formattedDate', { date: items }),
  });
  if(!isSiteMonitor) {
    columns.push({
      title: t('site.documents.Owner'),
      key: 'owner',
      sorter: (a, b) => ownerFromRecord(a) < ownerFromRecord(b) ? -1 : 1,
      render: (text, record) => ownerFromRecord(record),
    });
  } // !isSiteMonitor
  columns.push({
      title: t('site.documents.Review Status'),
      key: 'status',
      dataIndex: ['reviewData', 'status'],
      render: text => t(`site.documents.review-status.${text}`),
  });
  columns.push({
      title: t('site.documents.Version'),
      key: 'version',
      render: (text, record) => (
        <Link
          to={`/organization/${record.context.organizationId
                     }/study/${record.context.studyId
                      }/site/${record.context.siteId
                   }/subject/${record.context.subjectId
                  }/document/${record.documentId
                   }/version/${record.version
                      }/view`}
        >
          {record.version}
        </Link>
      ),

  });

  return (
    <Table className={className}
           dataSource={dataSource}
           columns={columns}
           rowKey={row => row.version}
           loading={loading}
           />
  );
};

export const DocumentListVersions = props => {

  const { isSiteMonitor, } = props;
  const { t } = useTranslation();
  const { organizationId, studyId, siteId, subjectId, documentId } = useParams();
  const { siteFetch, isSiteFetching } = useSiteFetch();
  const [ isLoading, setIsLoading ] = useState(true);
  const [ documentData, setDocumentData ] = useState(null);
  
  useEffect(() => {
    const url = `/gui/organization/${organizationId}/study/${studyId}/site/${siteId}/subject/${subjectId}/document/${documentId}`;

    const onLoad = async () => {

      setIsLoading(true);

      try {

        const response = await siteFetch(url);
        const json = await response.json();

        if(response.status !== 200) {
          onError(`${response.status} ${response.statusText}: '${json.error}'`);
          return;
        }


        setDocumentData(json);

      } catch (e) {
        onError(e);
      } finally {
        setIsLoading(false);
      }
    };

    if(!isSiteFetching(url)) {
      onLoad();
    }
  }, [organizationId,
      studyId,
      siteId,
      subjectId,
      documentId,
      siteFetch,
      isSiteFetching,
     ]);

  if(!documentData) {
    return <Table />;
  }

  const fileName = documentData
                   .sort((a, b) => (a.dateUpdate < b.dateUpdate ? -1 : 1))
                   .map( item => item.originalFileMetadata.name)
                   .find( () => true )
                   ;

  return (
    <div className='DocumentListVersions'>

      <PageHeader
          className='page-title'
          title={`${fileName} ${t('site.documents.Versions')}`}
        />

      <DataTable
        className='DocumentList'
        dataSource={documentData}
        isSiteMonitor={isSiteMonitor}
        loading={isLoading}
        />
    </div>  
  );
};
