import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Layout, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import gfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';

import ReactMarkdown from 'react-markdown';
import useAppUser from '../lib/useAppUser';
import useFetch from '../lib/useFetch';
import { onError } from '../lib/onError';
import { SuFooter } from '../components/Layout';

import '../components/Layout.scss';
import '../components/Header.scss';
import './Training.scss';

function querystring(name, url = window.location.href) {
  const escapedName = name.replace(/[[]]/g, "\\$&");

  const regex = new RegExp("[?&]" + escapedName + "(=([^&#]*)|&|#|$)", "i");
  const results = regex.exec(url);

  if (!results) {
    return null;
  }
  if (!results[2]) {
    return "";
  }

  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

const TrainingHeader = props => {
  const { Header } = Layout;
  const { t } = useTranslation();

  return (
      <Header className='Header' key='header'>
      <div className='navigation'>
        <div className='logo'>
          <Link>
            <img className='HeaderLogo'
                 src='/SU-logo-with-lettering-426-transparent.png'
                 alt={t('product-name')}
             />
          </Link>
        </div>
      </div>
    </Header>
  );
};

const TrainingLayout = props => {
  const { Content } = Layout;
  
  return (
    <Layout className='ViewportLayout TrainingLayout Layout'>
      <TrainingHeader className='Header' />
      <Content className='LayoutContent'>
        {props.children}
      </Content>
      <SuFooter className='Footer'/>
    </Layout>
  );
};

const ImageRenderer = props => {

  const { src, alt, title, width, height, /*...rest*/ } = props;

  const { authenticatedFetch } = useFetch();

  const [ objectUrl, setObjectUrl ] = useState(null);

  useEffect(() => {

    const fetchImage = async src => {

      const response = await authenticatedFetch(`/gui/article/image/${src}`);

      if(response.status !== 200) {
        const json = await response.json();
        onError(`${response.status} ${response.statusText}: '${json.error}'`);
        return;
      }

      const data = await response.blob();
      const objectUrl = URL.createObjectURL( data );
      setObjectUrl( objectUrl );

    };

    if(objectUrl) {
      return;
    }

    fetchImage(src);

    return () => {

      if(!objectUrl) {
        return;
      }
      URL.revokeObjectURL( objectUrl );

    };

  }, [
    authenticatedFetch,
    src,
    objectUrl,
  ]);

  return (
    <img
      src={objectUrl}
      alt={alt}
      title={title}
      width={width}
      height={height}
      />
  );
};

const sendTelemetry = async ({
  authenticatedFetch,
  stepNum,
  sessionData,
  setIsSendingTelemetry,
  telemetryItem,
  }) => {

  const url = `/gui/telemetry/article/${sessionData.training[stepNum].id
                  }/version/${ sessionData.training[stepNum].version}`;

  setIsSendingTelemetry(true);

  const response = await authenticatedFetch(url, {
    method: 'POST',
    headers: {
      TZ: (new Intl.DateTimeFormat()).resolvedOptions().timeZone,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(telemetryItem),
  });
  setIsSendingTelemetry(false);

  if(response.status !== 201) {
    const json = await response.json();
    onError(`${response.status} ${response.statusText}: '${json.error}'`);
    return;
  }

};



const Training = props => {

  const { t } = useTranslation();
  const { Header, Content, Footer } = Layout;
  const { sessionData } = useAppUser();
  const { authenticatedFetch } = useFetch();

  const history = useHistory();
  const [ currentPage, setCurrentPage ] = useState(null);
  const [ stepNum, setStepNum ] = useState(0);
  const [ isNavigationActive, setIsNavigationActive ] = useState(false);
  const [ isSendingTelemetry, setIsSendingTelemetry ] = useState(false);
  const [ timeOfPageStart, setTimeOfPageStart ] = useState( 0 );
  const [ contentLanguage, setContentLanguage ] = useState( null );

  useEffect(() => {

    const loadPage = async stepNum => {

      const url = `/gui/article/${
       sessionData.training[stepNum].id}/version/${
       sessionData.training[stepNum].version}`;

      const response = await authenticatedFetch(url);

      if(response.status !== 200) {
        const json = await response.json();
        onError(`${response.status} ${response.statusText}: '${json.error}'`);
        return;
      }

      const md = await response.text();

       setCurrentPage(md);
       setContentLanguage(response.headers.get('Content-Language'));
       setTimeOfPageStart(new Date());
    };

    loadPage(stepNum);

  }, [ stepNum,
       sessionData.training,
       authenticatedFetch,
     ]);

  useEffect(() => {
    setIsNavigationActive(false);
    setTimeout(() => {
      setIsNavigationActive(true);
    }, 3000);
  }, [ stepNum ]);

  const redirect = querystring('redirect');

  const onFinishArticle = async pageNum => {
    const now = new Date();
    await sendTelemetry({
      authenticatedFetch,
      stepNum: pageNum,
      sessionData,
      setIsSendingTelemetry,
      telemetryItem: {
        clientTime: now,
        duration: now - timeOfPageStart,
        contentLanguage,
      },
    });
  };

  return (
    <TrainingLayout>
      <Layout className='Training'>
        <Header className='content-header'>
          <h1>{t('training.Training')}</h1>
        </Header>
        <Content
        >
          <ReactMarkdown
            className='article'
            remarkPlugins={[ gfm ]}
            rehypePlugins={[ rehypeRaw ]}
            components={{ img: ImageRenderer }}
            >
            { currentPage }
          </ReactMarkdown>
        </Content>
        <Footer className='Footer'>

          <span className='label'>
            {t('training.page-of', {
                 stepNum: stepNum + 1,
                 numSteps: sessionData.training.length})}
          </span>

         { sessionData.training.length > stepNum + 1 ? (

          <Button
            className='next-button'
            loading={isSendingTelemetry}
            disabled={!isNavigationActive}
            onClick={async () => {
              await onFinishArticle(stepNum);
              setStepNum(stepNum + 1);
            }}

          >
            {t('training.Next Page')}
          </Button>
          ) : (
          <Button
            className='next-button'
            disabled={!isNavigationActive}
            onClick={async () => {
              await onFinishArticle(stepNum);
              history.push(redirect ? redirect : '/');
            }}
          >
            {t('training.Finished')}
          </Button>
          )}
        </Footer>
      </Layout>
    </TrainingLayout>
    );
};

export default Training;
