import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Spin } from 'antd';
import useSiteFetch from '../lib/useSiteFetch';
import useDocument from '../lib/useDocument';
import { onError } from '../lib/onError';
import SiteFrame from '../components/SiteFrame';
import { DocumentItemView } from '../components/DocumentItemView';
import './DocumentView.scss'

export default function DocumentView ( props ) {

  const { organizationId, studyId, siteId, subjectId, documentId, version } = useParams();
  const { siteFetch } = useSiteFetch();
  const { documentItem, isDocumentItemLoaded } = useDocument();
  const [ displayDocument, setDisplayDocument ] = useState(null);

  useEffect(() => {

    const onLoad = async () => {
      const url = `/gui/organization/${organizationId}/study/${studyId}/site/${siteId}/subject/${subjectId}/document/${documentId}/version/${version}/download`
      const response = await siteFetch(url, {
        headers: {
          TZ: (new Intl.DateTimeFormat()).resolvedOptions().timeZone,
        },
      });

      if(response.status !== 200) {
        const json = await response.json();
        onError(`${response.status} ${response.statusText}: '${json.error}'`);
        return;
      }
      const buffer = await response.arrayBuffer();
     
      setDisplayDocument(buffer);
      

    };

    if(!displayDocument) {
      onLoad();
    }

  },[
      displayDocument,
      organizationId,
      studyId,
      siteId,
      subjectId,
      documentId,
      version,
      siteFetch,
    ]);

  return (
    <SiteFrame className='DocumentViewFrame' subjectId={subjectId}>
      <Spin
         className='content-loading-feedback'
         spinning={!isDocumentItemLoaded || !displayDocument}
         //spinning={true}
         >
          <DocumentItemView
            className='DocumentViewContent'
            documentItem={documentItem}
            displayDocument={displayDocument}
          />
      </Spin>
    </SiteFrame>
  );
}
