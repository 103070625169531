import React from 'react';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Tabs, PageHeader, Button } from 'antd';
import { BellTwoTone } from '@ant-design/icons';
import SiteSubjectList from '../components/SiteSubjectList';
import SiteDocumentReviewTable from '../components/SiteDocumentReviewTable';
import useSite from '../lib/useSite';
import useFetch from '../lib/useFetch';
import { onError } from '../lib/onError';
import 'antd/dist/antd.css';
import './SiteView.scss';

const downloadAudit = async ( authenticatedFetch, organizationId, studyId, siteId ) => {

  try {

    const response = await authenticatedFetch(
      `/gui/organization/${organizationId}/study/${studyId}/site/${siteId}/audit`,
      {
        responseType: 'arrayBuffer',
      }
    );
    const buffer = await response.arrayBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.ms-excel' });
    const objectUrl = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = objectUrl;
    a.style = "display: none";
    a.download = `SiteAuditTrail-${(new Date()).toISOString().replace(/:/g, "-")}.xlsx`;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(objectUrl);
    document.body.removeChild(a);

  } catch (e) {
    onError(e);
  }

};


export default function SiteView() {

  const { t } = useTranslation();
  const { TabPane } = Tabs;

  const location = useLocation();
  const history = useHistory();
  const { organizationId, studyId, siteId } = useParams();
  const { siteData } = useSite();
  const { authenticatedFetch } = useFetch();

  if(!siteData) {
    return null;
  }

  const mode = location.pathname.split('/').pop();
  if(!siteData?.edcUsers?.[0]?.sites?.[0]?.role) {
    return null;
  }
  const isSiteMonitor = siteData.edcUsers[0].sites[0].role === 'monitor';

  if(isSiteMonitor && mode !== 'subject') {
    throw new Error('Site monitor is denied access to site document view');
  }

  if(isSiteMonitor) {
    return <SiteSubjectList />;
  }

  return (
    <div className='SiteView'>
      <div className='site-page-header-row'>
        <PageHeader
          className='site-page-header'
          title={siteData?.edcUsers[0]?.sites?.[0]?.siteName
                  || 'Unknown Site Name'}
        />
        <Button
          className='audit-button'
          onClick={() => downloadAudit(authenticatedFetch, organizationId, studyId, siteId )}
        >
          {t('site.Download Audit Trail')}
        </Button>
      </div>    
      <Tabs
        className='SiteViewTabs'
        type='card'
        activeKey={mode}
        onTabClick={(key, event) => {
          history.push(`/organization/${organizationId
                       }/study/${studyId
                       }/site/${siteId
                       }/${key}`);
        }}
        >
        <TabPane key='subject' tab={t('site.Subjects')}>
           <SiteSubjectList />
        </TabPane>
        <TabPane
          key='document'
          tab={
            siteData?.edcUsers?.[0]?.sites?.[0]?.documentData?.pendingDocumentCount > 0
              ? (
                <span>
                  {t('site.Document Review')}

                  <BellTwoTone
                    className='review-notification'
                    twoToneColor='#eb2f96'
                  />
                </span>
              )
              : t('site.Document Review')
            }
          >
          <SiteDocumentReviewTable/>
        </TabPane>
      </Tabs>
    </div>
  );

}

