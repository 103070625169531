import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Table } from 'antd';
import useAdmin from '../../lib/useAdmin';
import { RawData } from '../../components/AdminForms';

const config = {
  name: 'Study Site',
};

const siteModeValue = siteItem => {

  const isRedactionQualityControlRequired =
    typeof siteItem.isRedactionQualityControlRequired !== 'undefined'
    ? siteItem.isRedactionQualityControlRequired
    : false;

  if(isRedactionQualityControlRequired) {
    return 'qc';
  }

  const isRedactionRequired =
    typeof siteItem.isRedactionRequired !== 'undefined'
    ? siteItem.isRedactionRequired
    : false;

  if(isRedactionRequired){
    return 'redact-only';
  }

  return 'basic';
};



const ItemList = props => {

  const { siteItems, studyItems } = useAdmin();
  const { studyId } = useParams();
  const history = useHistory();

  if(!studyItems || !siteItems) {
    return null;
  }

  const studyName = studyItems
                      .find(r => r.studyId === studyId)
                      .name;

  return (
    <div>
      <h1>{studyName} {config.name} List</h1>

        <h1>Active Sites</h1>
        <Table
          dataSource={siteItems.filter(e => e.isSiteActive)}
          rowKey={record => record.siteId}
          onRow={r => ({ onClick: e => history.push(
            `/admin/organization/${r.organizationId}/study/${r.studyId}/site/${r.siteId}`
            )})}
          columns={[
            {
              title: 'Name',
              dataIndex: 'siteName',
              key: 'siteName',
            },
            {
              title: 'Locality',
              dataIndex: 'locality',
              key: 'locality',
            },
            {
              title: 'Redaction Mode',
              //dataIndex: 'locality',
              key: 'mode',
              render: (text, record) => siteModeValue(record), 
            },
          ]}
        >
        </Table>



        <h1>Inactive Sites</h1>
        <Table
          dataSource={siteItems.filter(e => !e.isSiteActive)}
          rowKey={record => record.siteId}
          onRow={r => ({ onClick: e => history.push(
            `/admin/organization/${r.organizationId}/study/${r.studyId}/site/${r.siteId}`
            )})}
          columns={[
            {
              title: 'Name',
              dataIndex: 'siteName',
              key: 'siteName',
            },
            {
              title: 'Locality',
              render: () => '-',
              key: 'locality',
            },
            {
              title: 'Redaction Mode',
              key: 'mode',
              render: () => '-',
            },
          ]}
        />


      <RawData data={siteItems} />
    </div>
  );
};

const AdminStudySiteList = props => {
  return <ItemList />;
};

export { AdminStudySiteList };
