import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Form, Checkbox, Button, Divider } from 'antd';
import useFetch from '../../lib/useFetch';
import useStudies from '../../lib/useStudies';
import useAdmin from '../../lib/useAdmin';
import { onError } from '../../lib/onError';
import { AccessItemsP1, AccessItemsRave, ListItem, RawData } from
  '../../components/AdminForms';

const config = {
  name: 'Study',
};

const refreshCacheData = async (
  studyItem, authenticatedFetch,
  setIsLoading, setData, resetStudyItems )=> {

  setIsLoading(true);

  const response = await authenticatedFetch( 
    `/gui/organization/${studyItem.organizationId
               }/study/${studyItem.studyId
               }/refresh-cache`);

  setIsLoading(false);

  if(response.status !== 200) {
    const json = await response.json();
    onError(`Upload Error: '${json.error}'`);
    return;
  };

  const json = await response.json();
  setData(json);
  resetStudyItems();
 
};

const UserAccessChangeLogDownloadButton = props => {

  const { organizationId, studyId  } = props;
  const { authenticatedFetch } = useFetch();
  const [ isWaiting, setIsWaiting ] = useState(false);

  const fetchData = async () => {

    try {

      setIsWaiting(true);
      
      const response = await authenticatedFetch(
        `/gui/organization/${organizationId
                   }/study/${studyId
                   }/user-access-changes`,
      );

      setIsWaiting(false);

      const buffer = await response.arrayBuffer();
      const blob = new Blob([buffer], { type: 'application/vnd.ms-excel' });
      const objectUrl = window.URL.createObjectURL(blob);

      const a = document.createElement('a');

      a.href = objectUrl;
      a.style = 'display: none';
      a.download = `study-user-access-change-log-${(new Date()).toISOString().replace(/:/g, '-')}.xlsx`;

      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(objectUrl);
      document.body.removeChild(a);
    
    } catch (e) {
      console.trace(e);
      onError(e);
    }

  };

  return (
    <Button onClick={() => fetchData()} loading={isWaiting} >
      Download User Access Change Log
    </Button>
  );

};

const EditForm = props => {

  const { data, setData } = props;
  const { organizationId, studyId } = useParams();
  const { authenticatedFetch } = useFetch();

  const { Item } = Form;

  const onFinish = async values => {

    const studyItem = {
      ...data,

      isActive: values.isActive,
      isActiveUserManagement: values.isActiveUserManagement,

      roles: {
        site: [
          ...values.site,
        ]?.filter(i => i ?? false), // remove empty added fields
        monitor: [
          ...values.monitor,
        ]?.filter(i => i ?? false), // remove empty added fields
        auditor: [
          ...values.auditor,
        ]?.filter(i => i ?? false), // remove empty added fields
      },

    };

    switch (data.edcType) {
      case 'p1edc':
        studyItem.login = {
          apiId: values.apiId ?? null,
          apiSecret: values.apiSecret ?? null,
          environment: values.environment ?? null,
        };
        break;
      case 'rave':  
        studyItem.access = {
          username: values.username ?? null,
          password: values.password ?? null,
          host: values.host ?? null,
          environment: values.environment ?? null,
          subjectLogFormOIDs: values.subjectLogFormOIDs
            ?.filter(i => i ?? false) ?? [], // remove empty added fields
          subjectPath: values.subjectPath ?? null,
          usersPath: values.usersPath ?? null,
        };
        break;
      default:
        throw new Error(`Unknown EDC Type '${data.edcType}'`);  

    }

    const response = await authenticatedFetch(
      `/api/organization/${organizationId
                 }/study/${studyId}`, 
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(studyItem),

      });

    const json = await response.json();

    if(response.status !== 200) {
      onError(`Upload Error: '${json.error}'`);
      return;
    }

    setData(json);
  };

  return (
    <Form
      onFinish={onFinish}
      initialValues={{

        // p1edc
              apiId: data.login ? data.login.apiId : undefined,
          apiSecret: data.login ? data.login.apiSecret : undefined,

        // rave
           username: data.access ? data.access.username : undefined,
           password: data.access ? data.access.password : undefined,
               host: data.access ? data.access.host : undefined,
 subjectLogFormOIDs: data.access ? data.access.subjectLogFormOIDs
                                 : undefined,
        subjectPath: data.access ? data.access.subjectPath
                                 : undefined,
          usersPath: data.access ? data.access.usersPath
                                 : undefined,


        // combined       
        environment: data.login ? data.login.environment
                                : data.access ? data.access.environment
                                              : undefined,

        site: data.roles.site,
        monitor: data.roles.monitor,
        auditor: data.roles.auditor,

        isActive: data.isActive ? true: false,
        isActiveUserManagement: data?.isActiveUserManagement === undefined
                      ? true: data.isActiveUserManagement,

      }}
      style={{ width: '800px', }}
    >

      <Item name='isActive'
            label='Is Active'
            valuePropName='checked'
            >
          <Checkbox />
      </Item>

      <Item name='isActiveUserManagement'
            label='Use Active User Management'
            valuePropName='checked'
            >
          <Checkbox />
      </Item>

      <AccessItemsP1 data={data} />
      <AccessItemsRave data={data} />

      { data.edcType === 'rave' && (

        <ListItem
          name='subjectLogFormOIDs'
          label='Subject Log FormOIDs'
        />

      )}

      <ListItem
        name='site'
        label='Role Mapping, Site User'
      />

      <ListItem
        name='monitor'
        label='Role Mapping, Monitor'
      />

      <ListItem
        name='auditor'
        label='Role Mapping, Auditor'
      />

      <Item>
        <Button type='primary' htmlType='submit'>Submit</Button>
      </Item>

    </Form>
  );
};

const ItemList = props => {

  const { authenticatedFetch } = useFetch();
  const { resetStudyData } = useStudies();
  const { resetStudyItems } = useAdmin();
  const { organizationId, studyId } = useParams();
  const [ data, setData ] = useState('');
  const [ isLoading, setIsLoading ] = useState(false);

  useEffect(() => {

    const loadData = async () => {

      const response = await authenticatedFetch(
        `/api/organization/${organizationId}/study/${studyId}`);
      const json = await response.json();

      setData(json);
    };

    loadData();
  }, [
    organizationId,
    studyId,
    authenticatedFetch,
  ]);

  if(!data) {
    return null;
  }

  return (
    <div {...props}>
      <h1>{config.name} {data.name}</h1>
      <ul>
        <li>Copy {data.name} as New Study</li>
        <li>
          <Link 
            to={`/admin/organization/${organizationId}/study/${studyId}/user`}
            >{data.name} Users</Link>
        </li>
        <li>
          <Link 
            to={`/admin/organization/${organizationId}/study/${studyId}/site`}
            >{data.name} Sites</Link>
        </li>
      </ul>
      <Divider orientation='left'>User Access Change Log: {data.name}</Divider>

      <UserAccessChangeLogDownloadButton
        organizationId={organizationId}
        studyId={studyId}
       />

      <Divider orientation='left'>Refresh Cache: {data.name}</Divider>

      <Button
        onClick={event => {
          resetStudyData();
          refreshCacheData(data,
                           authenticatedFetch,
                           setIsLoading,
                           setData,
                           resetStudyItems);
        }}
        loading={isLoading}
        >Refresh</Button>

      <Divider orientation='left'>Edit</Divider>

      <EditForm 
        data={data}
        setData={setData} />

      <Divider orientation='left'>Raw</Divider>

      <RawData data={data} />
    </div>
  );
};

const AdminStudy = props => {
  return <ItemList className='AdminStudy'/>;
};

export { AdminStudy };
