import { useContext, useCallback } from 'react';
import { SessionTrackingContext } from './SessionTrackingContext';

const useContextTracking = () => {

  const [ state, setState ] = useContext(SessionTrackingContext);

  const setSessionExpiry = useCallback(
                             sessionExpiry => setState(sessionExpiry),
                             [ setState ]);

  return {
    sessionExpiry: state !== null
      ? parseInt(JSON.parse(JSON.stringify(state)))
      : null,
    setSessionExpiry,
  };

};

export default useContextTracking;
