import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams } from 'react-router-dom'
import { Table, Tag, Typography } from 'antd';
import useSite from '../lib/useSite';
import documentItemsUtil from '../lib/documentItemsUtil';
import useAppUser from '../lib/useAppUser';
import useSiteFetch from '../lib/useSiteFetch';
import usePreferences from '../lib/usePreferences';
import { VisitLabel, FormLabel } from '../components/Labels';
import { onError } from '../lib/onError';
import { useTranslation } from 'react-i18next';
import 'antd/dist/antd.css';
import './SiteDocumentReviewTable.scss';

const PAGE_SIZE = 25;
const DEFAULT_SORT_STATE = { columnKey: 'dateUpdate', order: 'descend' };
const DEFAULT_FILTER_STATE = [{
  attribute: [ 'reviewData', 'status' ],
  operator: 'in',
  value: [ 'pending', 'rejected' ]
  //extra: {'pending-exclude': siteData.email}
}];

const SiteDocumentReviewTable = props => {

  const { organizationId, studyId, siteId } = useParams();

  const { t } = useTranslation();

  const { siteData } = useSite();
  const { sessionData } = useAppUser();
  const { siteFetch } = useSiteFetch();
  const { getPreference } = usePreferences();

  const pagingData = useRef({});

  const [ documentItems, setDocumentItems ] = useState([]);
  const [ currentSiteId, setCurrentSiteId ] = useState(null);
  const [ currentPageNumber, setCurrentPageNumber ] = useState(1);
  //const [ pageSize, setPageSize ] = useState(PAGE_SIZE);
  const [ totalItemCount, setTotalItemCount ] = useState(0);
  const [ isLoading, setIsLoading ] = useState(true);
  const [ sortState, setSortState ] = useState( DEFAULT_SORT_STATE );
  const [ filterState, setFilterState ] = useState( null );

  const pageSize = PAGE_SIZE;

  useEffect(() => {

    if(!siteData) {
      return;
    }

    DEFAULT_FILTER_STATE[0].extra = {'pending-exclude': siteData.email};
    setFilterState( DEFAULT_FILTER_STATE );

  }, [ siteData ]);


  useEffect( () => {

    const loadData = async () => {

      setIsLoading(true);

      try {
      const response = await siteFetch(

        `/gui/organization/${organizationId
            }/study/${studyId
            }/site/${siteId
            }/document?max-results=${pageSize
            }&paging=${
              pagingData.current.lastKey
                ? encodeURIComponent(JSON.stringify(pagingData.current.lastKey))
                : ''
            }&sort=${

              typeof sortState.order !== 'undefined' && sortState.order !== false
                ? encodeURIComponent(JSON.stringify(sortState))
                : ''

            }&filter=${
               filterState.length > 0
                 ?  encodeURIComponent(JSON.stringify(filterState))
                 : ''
             }`
      );

        const json = await response.json();

        if(response.status === 200) {
          setDocumentItems(json.items);
          if(json.paging.lastKey) {
            setTotalItemCount(currentPageNumber * pageSize + 1);
          } else {
            setTotalItemCount((currentPageNumber - 1) * pageSize + json.items.length);
          }

          pagingData.current.lastKey = json.paging.lastKey;

        } else {
          const msg = `${t('api.Loading Error')}: ${response.status} ${response.statusText}: ${json.error ? json.error : ''}`;
          console.log(msg);
          onError(msg);

        }

      } catch (e) {
        onError(e);
      } finally {
        setIsLoading(false);
      }

    };

    if(!DEFAULT_FILTER_STATE[0]?.extra) {
      return;
    }

    if(siteId !== currentSiteId) {
      setCurrentSiteId(siteId);
      setCurrentPageNumber(1);
      setTotalItemCount(0);
      setDocumentItems([]);
      pagingData.current = {};
      setSortState(DEFAULT_SORT_STATE);
      setFilterState(DEFAULT_FILTER_STATE);
      return;

    }

    if(currentPageNumber === 1) {
      pagingData.current = {};
    }

    loadData();

  }, [
       organizationId,
       studyId,
       siteId,
       pageSize,
       currentPageNumber,
       currentSiteId,
       sortState,
       filterState,
       siteFetch,
       t,
     ]);

  const logFilters = documentItemsUtil.extractSubjectLogFilters(documentItems);
  const visitFilters = documentItemsUtil.extractVisitFilters(documentItems);

  if(getPreference('dev-is-fake-id')) {
    sessionData.email = 'fake@example.com';
  }

  const ownerFromRecord = record => {
    switch (record.reviewData.status) {
      case 'pending':
        return record.reviewData.certifier;
      case 'rejected':
      case 'discarded':
      case 'approved':
        return record.reviewData.reviewer;
      default: throw new Error(`Unrecognized review status '${record.reviewData.status}'`);  
    }
  };

  const onTableChange = async (pagination, filters, sorter, extra) => {
    
    /*
     pagination: {current: 2, pageSize: 3, total: 5}
     filters: { associatedEvents: null, associatedLogs: null }
     */

    switch (extra.action) {
      case 'paginate':
        setCurrentPageNumber(pagination.current);
      break;
      case 'sort':
        setSortState({
          columnKey: sorter.columnKey,
          order: sorter.order,
          });
        setCurrentPageNumber(1);
      break;
      case 'filter':
        const requestFilters = [];

        if(filters.associatedEvents && filters.associatedEvents.length > 0) {

          requestFilters.push({

            attribute: ['associatedEventNames'],
            operator: 'containsAny',
            value: filters.associatedEvents,

          });

        }

        if(filters.associatedLogs && filters.associatedLogs.length > 0) {
          requestFilters.push({

            attribute: ['associatedLogNames'],
            operator: 'containsAny',
            value: filters.associatedLogs,

          });
        }

        // we go ahead and set reviewData.status filters 
        // in any case, because we always filter all but
        // pending, pending-mine, and rejected
        const value = filters['reviewData.status']
                      ?.filter(i => !i.startsWith('pending')) || [];

        if(filters['reviewData.status'] === null) {
          value.push(...[ 'pending', 'rejected']);          
        }
        
        if(value.length === 0) {
          value.push(...[ 'pending']);
        }

        const isPendingPresent = filters['reviewData.status']
                                   ?.includes('pending') || false;
        const isPendingMinePresent = filters['reviewData.status']
                                       ?.includes('pending-mine') || false;

        const extra = {};
        
        if(isPendingPresent || isPendingMinePresent) {
          value.push('pending');

          if(!isPendingMinePresent) {
            extra['pending-exclude'] = siteData.email;
          }

          else if(!isPendingPresent) {
            extra['pending-include'] = siteData.email;
          }

        }

        requestFilters.push({

          attribute: ['reviewData', 'status'],
          operator: 'in',
          value,
          extra,

        });

        setFilterState(requestFilters);

      break;
      default:
       throw new Error(`Unrecognized table action '${extra.action}'`);
    }


  };  

  // subject code, uploader (is principal?), visit, file name 
  const columns = [];
  columns.push(
    {
      title: t('site.documents.Subject'),
      dataIndex: 'edcSubjectKey', 
      key: 'edcSubjectKey', 
      width: '8%',
      render: (text, record) => <Typography.Text ellipsis={true}>
        {text}
      </Typography.Text>,
    });
  columns.push({
      title: t('site.documents.Visits'),
      dataIndex: 'associatedEvents',
      width: '30%',
      render: items => {
        if(!items || items.length === 0) {
          return '-';
        }
        return items.map(item =>
          <Typography.Text ellipsis={true} >
            <Tag key={item.studyEventName}>
              <VisitLabel studyEventItem={item} />
            </Tag>
          </Typography.Text>);
      },
      filters: visitFilters,
    });
  columns.push({
      title: t('site.documents.Logs'),
      dataIndex: 'associatedLogs',
      width: '15%',
      render: items => {
        if(!items || items.length === 0) {
          return '-';
        }
        return items.map(item =>
          <Typography.Text ellipsis={true} >
            <Tag key={`${item.edcName} ${item.edcFormRepeatKey}`}>
              <FormLabel studyLogItem={item} />
            </Tag>
          </Typography.Text>);
      },
      filters: logFilters,
    });
  columns.push({
      title: t('site.documents.File name'),
      dataIndex: ['originalFileMetadata', 'name'],
      key: 'filename',
      width: '10%',
    });
  columns.push({
        title: t('site.documents.Date'),
        dataIndex: ['dateUpdate'],
        key: 'dateUpdate',
        width: '15%',
        render: items => t('site.documents.formattedDate', { date: items }),
        sorter: true,
        sortOrder: sortState.columnKey === 'dateUpdate' ? sortState.order : false,

    });

  if(siteData?.edcUsers?.[0]?.sites?.[0]?.isRedactionQualityControlRequired){

    columns.push({
      title: t('site.documents.Owner'),
      key: 'owner',
      width: '15%',
      render: (text, record) => ownerFromRecord(record),
    });

    columns.push({
      title: t('site.documents.Review Status'),
      dataIndex: ['reviewData', 'status'],
      width: '7%',
      render: (text, record) =>  {
        let value = text;
        if(record.reviewData.status === 'pending'
           && record.reviewData.certifier === sessionData.email) {

          value = 'pending-mine';

        }

        if(value === 'pending') {

          const url = `/organization/${organizationId}/study/${studyId}/site/${siteId}/subject/${record.context.subjectId}/document/${record.documentId}/version/${record.version}/review`;

          return <Link to={url}>
            {t('site.documents.review-status.pending')}
          </Link>

        }
        if(value === 'rejected' && record.reviewData.certifier === sessionData.email) {

          const url = `/organization/${organizationId}/study/${studyId}/site/${siteId}/subject/${record.context.subjectId}/document/${record.documentId}/version/${record.version}/edit`;
          return <Link to={url}>
            {t('site.documents.review-status.rejected')}
          </Link>

        }

        return (
        <Link to={`/organization/${record.context.organizationId
                         }/study/${record.context.studyId
                          }/site/${record.context.siteId
                       }/subject/${record.context.subjectId
                      }/document/${record.documentId
                       }/version/${record.version
                          }/view`}
              >
          {t(`site.documents.review-status.${value}`)}
        </Link>);
      },
      filters: [
        { text: t('subject.documents.review-status.pending'),
          value: 'pending' },
        { text: t('subject.documents.review-status.pending-mine'),
          value: 'pending-mine' },
        { text: t('subject.documents.review-status.rejected'),
          value: 'rejected' },
      ],
      defaultFilteredValue: ['pending', 'rejected'],
    });

    columns.push({
      title: t('site.documents.Version'),
      key: 'version',
      dataIndex: 'version',
      className: 'version',
      width: '7%',
      render: (text, record) => <Link
          to={`/organization/${organizationId}/study/${studyId}/site/${siteId}/subject/${record.context.subjectId}/document/${record.documentId}/`}>
            {text}
          </Link>,
    });
  }

  return (
    <div className='SiteDocumentReviewTable'>
      <Table className='document-table'
        dataSource={documentItems}
        loading={isLoading}
        columns={columns}
        rowKey={row => row.documentId}
        
        onChange={onTableChange}
        pagination={{
          current: currentPageNumber,
          pageSize: pageSize,
          total: siteData?.edcUsers?.[0]?.sites?.[0]?.documentData?.documentCount
            || totalItemCount,
          // hideOnSinglePage can leave user stranded if totalItemCount active
          // and true total equals pageSize, because of our adaptive total
          hideOnSinglePage: siteData?.edcUsers?.[0]?.sites?.[0]?.documentData?.documentCount
                            ? true : false,
             }}
             rowClassName={(record, index) => {
               if(ownerFromRecord(record) === sessionData.email) {
                 return 'user-owned-document';
               }
             }}


             >
      </Table>
    </div>
  );
};


export default SiteDocumentReviewTable;
