import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Divider, Button, Form, Input } from 'antd';
import useFetch from '../../lib/useFetch';
import useAdmin from '../../lib/useAdmin';
import { onError } from '../../lib/onError';

import { DatakeyArnListItem, RawData } from '../../components/AdminForms';

const config = {
  name: 'Datakey',
};

const RemoveForm = props => {

  const history = useHistory();
  const { datakeyId } = useParams();
  const { authenticatedFetch } = useFetch();
  const { resetDatakeyItems } = useAdmin();

  const removeDatakey = async () => {

    const response = await authenticatedFetch(
      `/api/datakey/${datakeyId}`,
      {
        method: 'DELETE',
      });

    // success status 204

    if(response.status !== 204) {
      const json = await response.json();
      onError(`Delete Error: '${json.error}'`);
      return;
    }

    resetDatakeyItems();
    history.push(`/admin/datakey`);

  }

  return <Button onClick={removeDatakey}>Remove</Button>

};

const EditForm = props => {

  const { data, setData } = props;
  const { authenticatedFetch } = useFetch();
  const { resetDatakeyItems } = useAdmin();

  const { Item } = Form;

  const onFinish = async values => {
    const datakeyItem = {
      ...data,
      datakeyName: values.datakeyName,
      keyArns: values.keyArns,
    };

    const response = await authenticatedFetch(
      `/api/datakey/${data.datakeyId}`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(datakeyItem),

      });

    const json = await response.json();

    if(response.status !== 200) {
      onError(`Upload Error: '${json.error}'`);
      return;
    }

    setData( json );
    resetDatakeyItems();

  };

  return (
    <Form
      onFinish={onFinish}
      initialValues={{
        datakeyName: data.datakeyName,
        keyArns: data.keyArns,
      }}
      style={{ width: '800px', }}
    >

      <Item name='datakeyName' label='Name'>
        <Input />
      </Item>

      <DatakeyArnListItem
        name='keyArns'
        label='Key ARNs'
        placeholder='arn:aws:kms:region:owningAWSAccountId:key/keyId'
      />

      <Item>
        <Button type='primary' htmlType='submit'>Submit</Button>
      </Item>

    </Form>
  );
};

const VersionsList = props => {
  const { numVersions } = props;
  const { authenticatedFetch } = useFetch();
  const { datakeyId } = useParams();
  const [ data, setData ] = useState();

  useEffect(() => {

    const loadData = async () => {

      const response = await authenticatedFetch(`/gui/datakey/${datakeyId}`);
      const json = await response.json();

      if(response.status !== 200) {
        onError(`${response.status} ${response.statusText}: '${json.error}'`);
        return;
      }

      setData(json);
    };

    if(data?.versions === numVersions) {
      return;
    }


    loadData();
  }, [
    datakeyId,
    authenticatedFetch,
    data?.versions,
    numVersions,
  ]);

  if(!data) {
    return null;
  }

  return <RawData data={data} title='Raw Versions'/>

};

const ItemList = props => {

  const { authenticatedFetch } = useFetch();
  const { datakeyId } = useParams();
  const { datakeyItems } = useAdmin();
  const [ data, setData ] = useState();

  useEffect(() => {

    const loadData = async () => {

      const response = await authenticatedFetch(`/api/datakey/${datakeyId}`);
      const json = await response.json();

      if(response.status !== 200) {
        onError(`${response.status} ${response.statusText}: '${json.error}'`);
        return;
      }

      setData(json);
    };

    loadData();
  }, [
    datakeyId,
    authenticatedFetch,
  ]);

  if(!data) {
    return null;
  }

  const numVersions = datakeyItems?.find(i => i.datakeyId === datakeyId)
                               ?.versions;

  return (
    <div>
      <h1>{config.name} {data.datakeyName}</h1>

      <Divider orientation='left'>Edit</Divider>
      <EditForm
        data={data}
        setData={setData}
      /> 

      <Divider orientation='left'>Remove</Divider>
      <RemoveForm /> 

      <Divider orientation='left'>Raw</Divider>
      <RawData data={data} />

      <Divider orientation='left'>Raw Versions List</Divider>
      <VersionsList numVersions={numVersions}/>
    </div>
  );
};

const AdminDatakey = props => {
  return <ItemList />;
};

export { AdminDatakey };
