import React from 'react';
import { Link } from 'react-router-dom';
import { Divider, Form, Input, Button } from 'antd';
import useAdmin from '../../lib/useAdmin';
import useFetch from '../../lib/useFetch';
import { onError } from '../../lib/onError';
import { DatakeyArnListItem, RawData } from '../../components/AdminForms';

const config = {
  name: 'Data Key',
};

const AddForm = props => {

  const { authenticatedFetch } = useFetch();
  const { resetDatakeyItems } = useAdmin();
  const [ form ] = Form.useForm();

  const { Item } = Form;

  const onFinish = async values => {

    const datakeyItem = {
      datakeyName: values.datakeyName,
      keyArns: values.keyArns,
    };

    const response = await authenticatedFetch('/api/datakey',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(datakeyItem),

      });

    const json = await response.json();

    if(response.status !== 201) {
      onError(`Upload Error: '${json.error}'`);
      return;
    }

    resetDatakeyItems();
    form.resetFields();

  };

  return (
    <Form
      onFinish={onFinish}
      initialValues={{
        keyArns: [''],
      }}
      style={{ width: '800px', }}
      form={form}
    >

      <Item name='datakeyName'
            label='Name'
            >
        <Input placeholder='HCI Healthcare Institution' />
      </Item>

      <DatakeyArnListItem 
        name='keyArns'
        label='Key ARNs' 
        placeholder='arn:aws:kms:region:owningAWSAccountId:key/keyId'
      />

      <Item>
        <Button type='primary' htmlType='submit'>Submit</Button>
      </Item>

    </Form>
  );
};

const ItemList = props => {

  const { datakeyItems } = useAdmin();

  if(!datakeyItems) {
    return null;
  }

  return (
    <div>
      <h1>{config.name} List</h1>
      <ul>
        {
          datakeyItems.map(r => (
            <li key={r.datakeyId}>
              <Link
              to={`/admin/datakey/${r.datakeyId}`}
              >
                {r.datakeyName}
              </Link>
            </li>
          ))
        }
      </ul>
      <Divider orientation='left'>New Data Key</Divider>
      <AddForm />
      <Divider orientation='left'>Raw</Divider>
      <RawData data={datakeyItems} />
    </div>
  );
};

const AdminDatakeyList = props => {
  return <ItemList />;
};

export { AdminDatakeyList };
