/**
Standing in for aws-amplify Auth for the purpose of following
https://serverless-stack.com/chapters/load-the-state-from-the-session.html
without signing up for AWS Cognito.

In other words, we're kinda-sorta emulating this interface:
https://aws-amplify.github.io/amplify-js/api/classes/authclass.html

*/

import i18n from 'i18next';

let state = {
  email: null,
  isRegistered: false,
  sessionId: null,
};

const Type = {
  PASSWORD: 'password',
  TOTP: 'totp',
};

function hasEmail() {
  return getEmail() !== null;
}

function getEmail() {
  return state.email;
}

async function setEmail(email) {

  const response = await fetch(`${process.env.REACT_APP_API_HOST}/gui/user/${email}`);
  if (!response.ok) {
    const json = await response.json();
    throw new Error(json.error);
  }
  const json = await response.json();
  state.isRegistered = json.isRegistered;
  state.isResetRequested = json.isResetRequested;
  state.isEmailConfirmed = json.isEmailConfirmed;
  state.email = email.toLowerCase().trim();
  state.authenticationType = json.type;

  switch (json.type) {
    case Type.PASSWORD:
      return Type.PASSWORD;
    case Type.TOTP:
      return Type.TOTP;
    default:
      throw new Error(`${i18n.t('authentication.error.unrecognized auth type')} '${json.type}'`);
  }
}

async function signIn(email, password) {
  if (state.email === null || state.email !== email.toLowerCase().trim()) {
    throw new Error(
      i18n.t('email doesn\'t match previously submitted email',
             { email, previousEmail: state.email })
    );
  }
  
  const response = await fetch(`${process.env.REACT_APP_API_HOST}/gui/user/${state.email}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },

    body: JSON.stringify({
      password,
    }), // body
  });

  if (!response.ok) {
    throw new Error(i18n.t('authentication.error.sign-in error'));
  }

  const json = await response.json();
  state.isRegistered = true;
  state.sessionId = json.sessionId;
  state.session = json.session;

  return json;
}

async function currentSession() {
  if (!state) {
    throw new Error(i18n.t('authentication.error.No current user'));
  }
  return { ...state };
}

async function signOut() {
  state = {};
}

async function register(email) {
  // register: 'gui-appuser-register' sends email
  // basically, requests code to be sent by email
  const response = await fetch(`${process.env.REACT_APP_API_HOST}/gui/user/${email}/register`, {});
  if (!response.ok) {
    throw new Error(i18n.t('authentication.error.registration error'));
  }
}

async function signUp(credentials /*email, password*/) {
  // register: 'gui-appuser-register-password' records new password
  const response = await fetch(`${process.env.REACT_APP_API_HOST}/gui/user/${credentials.username}/password`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },

    body: JSON.stringify({
      existingPassword: null,
      password: credentials.password,
    }), // body
  });

  if (!response.ok) {
    throw new Error(i18n.t('authentication.error.sign-up error'));
  }
}

async function setupTOTP(email) {

  const response = await fetch(`${process.env.REACT_APP_API_HOST}/gui/user/${email}/totp`);
  return response.json();
}

async function confirmSignUp(email, confirmationCode, options) {

  let rawBody = {
    emailVerificationCode: confirmationCode,
  };
  if(options && options.isCancelReset) {
    // auth is in reset-request state, 
    // we cancel the reset
    rawBody.isCancelReset = options.isCancelReset;
  }

  const response = await fetch(`${process.env.REACT_APP_API_HOST}/gui/user/${email}/email-verify`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },

    body: JSON.stringify(rawBody), // body
  });

  if (!response.ok) {
    if(response.status === 400) {
      const json = await response.json();
      return { status: 400,
               ...json
             };
    }
    throw new Error(i18n.t('authentication.error.confirm sign-up error'));
  }
  return {};
}

const forgotPassword = async email => {
  await fetch(`${process.env.REACT_APP_API_HOST}/gui/user/${email}/reset`);
};

const Auth = {
  Type,
  hasEmail,
  getEmail,
  setEmail,
  signIn,
  currentSession,
  signOut,
  register,
  signUp,
  setupTOTP,
  confirmSignUp,
  forgotPassword,
};
export default Auth;
