import React from 'react';
import { Link } from 'react-router-dom';
import { Divider, Form, Input, Select, Button } from 'antd';
import useAdmin from '../../lib/useAdmin';
import useFetch from '../../lib/useFetch';
import { onError } from '../../lib/onError';
import { RawData } from '../../components/AdminForms';

const config = {
  name: 'Organization',
};

const AddForm = props => {

  const { authenticatedFetch } = useFetch();
  const { organizationItems, setOrganizationItems } = useAdmin();

  const { Item } = Form;
  const { Option } = Select;

  const onFinish = async values => {

    const organizationItem = {
      name: values.name,
      type: values.type,
    };

    const response = await authenticatedFetch(
      '/api/organization',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(organizationItem),

      });

    const json = await response.json();

    if(response.status !== 201) {
      onError(`Upload Error: '${json.error}'`);
      return;
    }

    const newOrganizationItems = JSON.parse(JSON.stringify(organizationItems));

    newOrganizationItems.unshift(json);
    setOrganizationItems(newOrganizationItems, true);
  };
  
  return (
    <Form
      onFinish={onFinish}
      initialValues={{
      }}
      style={{ width: '600px', }}
    >

      <Item name='name'
            label='Name'
            >
          <Input />
      </Item>

      <Item name='type' label='Type'>
        <Select style={{width: '200px'}}>
          <Option value='SITE'>Site</Option>
          <Option value='SPONSOR'>Sponsor</Option>
          <Option value='CRO'>CRO</Option>
          <Option value='VENDOR'>Vendor (eg. ProtocolFirst)</Option>
        </Select>
      </Item>

      <Item>
        <Button type='primary' htmlType='submit'>Submit</Button>
      </Item>

    </Form>
  );
};

const ItemList = props => {

  const { organizationItems } = useAdmin();

  if(!organizationItems) {
    return null;
  }

  return (
    <div>
      <h1>{config.name} List</h1>
      <ul>
        {
          organizationItems.map(i => (
          <React.Fragment key={i.organizationId}>
            <li>
              <Link to={`/admin/organization/${i.organizationId}`}>
                {i.name}
              </Link>
            </li>
            <ul>
              <li>
                <Link to={`/admin/organization/${i.organizationId}/study`}>
                  {i.name} Studies
                </Link>
              </li>
              <li>
                <Link to={`/admin/organization/${i.organizationId}/domain`}>
                  {i.name} Domains
                </Link>
              </li>
            </ul>
          </React.Fragment>
          ))
        }
      </ul>
      <Divider orientation='left'>New Organization</Divider>
      <AddForm />
      <Divider orientation='left'>Raw</Divider>
      <RawData data={organizationItems} />
    </div>
  );
};

const AdminOrganizationList = props => {
  return <ItemList />;
};

export { AdminOrganizationList };
