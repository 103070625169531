import React, { useState } from 'react';
import { useParams, Link, useHistory } from 'react-router-dom';
import { Divider, Form, Input, Select, Button, Checkbox, Table } from 'antd';
import useAdmin from '../../lib/useAdmin';
import useFetch from '../../lib/useFetch';
import { onError } from '../../lib/onError';
import { AccessItemsP1, AccessItemsRave, ListItem, RawData } from
  '../../components/AdminForms';

const config = {
  name: 'Study',
};

const AddForm = props => {

  const { organizationId } = useParams();
  const history = useHistory();
  const { authenticatedFetch } = useFetch();
  const { resetStudyItems } = useAdmin();
  const [ edcType, setEdcType ] = useState(null);
  const [ isWaiting, setIsWaiting ] = useState(false);

  const { Item } = Form;
  const { Option } = Select;

  const saveStudyRecord = async values => {

    if(!values.edcType) {
      onError('No EDC Type defined');
      return;
    }

    const studyItem = {

      organizationId,
      name: values.name ?? null,
      edcType: values.edcType ?? null,

      isActive: values.isActive,
      isActiveUserManagement: values.isActiveUserManagement,

      roles: {
        site: values.site
          ?.filter(i => i ?? false) ?? [], // remove empty added fields
        monitor: values.monitor
          ?.filter(i => i ?? false) ?? [], // remove empty added fields
        auditor: values.auditor
          ?.filter(i => i ?? false) ?? [], // remove empty added fields
      },

    };

    switch (values.edcType) {
      case 'p1edc':
        studyItem.login = {
          apiId: values.apiId ?? null,
          apiSecret: values.apiSecret ?? null,
          environment: values.environment ?? null,
        };
        break;
      case 'rave':  
        studyItem.access = {
          username: values.username ?? null,
          password: values.password ?? null,
          host: values.host ?? null,
          environment: values.environment ?? null,
          subjectLogFormOIDs: values.subjectLogFormOIDs
                               ?.filter(i => i ?? false) ?? [],
          subjectPath: values.subjectPath ?? null,
          usersPath: values.usersPath ?? null,
        };
        break;
      default:
        throw new Error(`Unknown EDC Type '${values.edcType}'`);  

    }

    const response = await authenticatedFetch(
      `/api/organization/${organizationId}/study`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(studyItem),

      });

    const json = await response.json();

    if(response.status !== 201) {
      onError(`Upload Error: '${json.error}'`);
      return;
    }

    return json;

  };

  const refreshCacheData = async studyItem => {


    const response = await authenticatedFetch(
      `/gui/organization/${studyItem.organizationId
                 }/study/${studyItem.studyId
                 }/refresh-cache`);

    if(response.status !== 200) {
      const json = await response.json();
      onError(`Upload Error: '${json.error}'`);
      return;
    };

    resetStudyItems();

  };


  const onFinish = async values => {
    setIsWaiting(true);

    const studyItem = await saveStudyRecord(values);

    if(!studyItem) {
      setIsWaiting(false);
      return;
    }

    await refreshCacheData(studyItem);

    setIsWaiting(false);
    history.push(`/admin/organization/${organizationId}/study/${studyItem.studyId}`);
  };

  return (
    <Form
      onFinish={onFinish}
      style={{ width: '800px', }}
      initialValues={{
        isActive: true,
        isActiveUserManagement: false,
      }}
    >

      <Item name='edcType' label='EDC Type'>
        <Select style={{width: '200px'}}
          onSelect={ value => setEdcType(value) }
        >
          <Option value='p1edc'>Protocol First EDC</Option>
          <Option value='rave'>Medidata Rave EDC</Option>
        </Select>
      </Item>

      <Item name='name'
            label='Name'
            >
          <Input />
      </Item>

      <Item name='isActive'
            label='Is Active'
            valuePropName='checked'
            >
          <Checkbox />
      </Item>

      <Item name='isActiveUserManagement'
            label='Use Active User Management'
            valuePropName='checked'
            >
          <Checkbox />
      </Item>
      <AccessItemsP1 data={{ edcType, }} />
      <AccessItemsRave data={{ edcType, }} />

      { edcType === 'rave' && (

        <ListItem
          name='subjectLogFormOIDs'
          label='Subject Log FormOIDs'
        />

      )}


      <ListItem
        name='site'
        label='Role Mapping, Site User'
      />

      <ListItem
        name='monitor'
        label='Role Mapping, Monitor'
      />

      <ListItem
        name='auditor'
        label='Role Mapping, Auditor'
      />

      <Item>
        <Button
          type='primary'
          htmlType='submit'
          loading={isWaiting}
          >Submit Study Record and Cache Study Data</Button>
      </Item>

    </Form>
  );
};

const ItemList = props => {

  const { organizationId } = useParams();
  const { organizationItems, studyItems } = useAdmin();

  const { Column } = Table;

  if(!studyItems || !organizationItems) {
    return null;
  }

  const organizationName = organizationItems
                             .find(e => e.organizationId === organizationId)
                             .name;
  return (
    <div>
      <h1>{organizationName} {config.name} List</h1>

      <Table
        dataSource={studyItems}
        rowKey={record => record.studyId}
      >
        <Column title='Study' dataIndex='name' key='name' />
        <Column title='Sites'
                key='sites'
                render={s => (
                <Link 
                  to={`/admin/organization/${organizationId}/study/${s.studyId}/site`}
                  >Sites</Link>
                )}
                />
        <Column title='Users'
                key='users'
                render={s => (
                <Link 
                  to={`/admin/organization/${organizationId}/study/${s.studyId}/user`}
                  >Users</Link>
                )}
                />
        <Column title='Edit'
                key='edit'
                render={s => (
                <Link 
                  to={`/admin/organization/${organizationId}/study/${s.studyId}`}
                  >Edit</Link>
                )}
                />
      </Table>
      <Divider orientation='left'>New {organizationName} Study</Divider>
      <AddForm />
      <Divider orientation='left'>Raw</Divider>
      <RawData data={studyItems} />
    </div>
  );
};

const AdminStudyList = props => {
  return <ItemList />;
};

export { AdminStudyList };
