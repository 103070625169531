import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: 'en',
    //debug: true,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default

      format: (value, format, lng) => {

        if (format === 'intlDate') {
          let options = {
            year: 'numeric', month: 'numeric', day: 'numeric',
            hour: 'numeric', minute: 'numeric',
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            timeZoneName: 'short',
            // hourCycle: 'h23',
            hour12: true
          };
          return new Intl.DateTimeFormat(lng, options).format( new Date(value));
        }

        if (format === 'shortDateFromISO8601') {
          let options = {
            dateStyle: 'short',
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            //timeZoneName: 'short',
            // hourCycle: 'h23',
            //hour12: true
          };
          return new Intl.DateTimeFormat(lng, options).format(new Date(value));
        }

        return value;
      }, //format

    }, // interpolation
    detection: {
      // language detection options
      // we want to switch cookies off
      caches: null,
      lookupCookie: null,
      lookupLocalStorage: null,
      lookupSessionStorage: null,

    },
  });


export default i18n;
