import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useSiteFetch from '../lib/useSiteFetch';
import useSite from '../lib/useSite';
import { onError } from '../lib/onError';
import { DocumentRedactionEditor } from './DocumentRedactionEditor';
import { CertificationPage } from './CertificationPage';
import { v4 as uuidv4 } from 'uuid';
import useAppUser from '../lib/useAppUser';
import {
         DocumentUploadPage,
         VisitSelector,
         FormSelector,
         DocumentEditSteps,
         } from './DocumentTask';
import './NewDocumentTask.scss'         
import 'antd/dist/antd.css';

const chunkSize = 512 * 1024; // 256 kiB

export const NewDocumentTask = (props) => {

  const { subjectData, setDocumentName } = props;

  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const { siteFetch } = useSiteFetch();
  const { siteData } = useSite();
  const [ isLoading, setIsLoading ] = useState(false);
  const [sourceFile, setSourceFile ] = useState(null);
  // maybe we will use sourceFile to reset editing process
  const [ originalFileMetadata, setOriginalFileMetadata ] = useState(null);
  const [ destinationDocument, setDestinationDocument ] = useState(null);
  const [ associatedEvents, setAssociatedEvents ] = useState([]);
  const [ associatedLogs, setAssociatedLogs ] = useState([]);

  const { organizationId, studyId, siteId, subjectId } = useParams();
  const { sessionData } = useAppUser();

  // chunking from https://github.com/y3n3rrr/LargeFileUpload/blob/master/src/App.js
  const counter = useRef(0)
  const [fileToBeUpload, setFileToBeUpload] = useState({})
  const beginningOfTheChunk = useRef(0)
  const endOfTheChunk = useRef(chunkSize)
  const [progress, setProgress] = useState(0)
  const [fileGuid, setFileGuid] = useState("")
  const fileSize = useRef(0)
  const [chunkCount, setChunkCount] = useState(0)
  const [completedFormData, setCompletedFormData] = useState(null);
  const [ stepConfirm, setStepConfirm ] = useState(null);  

  const getFileContext = useCallback( (e) => {
    resetChunkProperties();
    const _file = e.blob;
    const _totalCount = _file.size % chunkSize === 0 ? _file.size / chunkSize : Math.floor(_file.size / chunkSize) + 1; // Total count of chunks will have been upload to finish the file
    setChunkCount(_totalCount)
    const _fileID = uuidv4() + "-" + originalFileMetadata.name;
    setFileGuid(_fileID);
    fileSize.current = _file.size;
    setFileToBeUpload(_file)
  },[originalFileMetadata])


  const uploadCompleted = useCallback(() => {
    const upload = async () => {
      try {
        let formData = completedFormData;
        formData.append("fileName", fileGuid);

        const response = await siteFetch(
          `/gui/organization/${organizationId}/study/${studyId}/site/${siteId}/subject/${subjectId}/document`,
          {
            method: 'POST',
            headers: {
              TZ: (new Intl.DateTimeFormat()).resolvedOptions().timeZone,
            },
            body: formData, // body
          }
        );
        if (response.status === 201) {
          history.push( `/organization/${organizationId}/study/${studyId}/site/${siteId}/subject/${subjectId}`);
        } else {
          const json = await response.json();

          // we can get 401 if the code is fatfingered/late or if the 
          // session has expired.
          // if code is fatfingered / late, json.error is 'Unauthorized'
          // if session has expired, json.error is 'Session lost' or 'Session
          // expired'

          const msg = `${t('api.Upload Error')}: ${response.status} ${response.statusText}: ${json.error ? json.error : ''}`;
          onError(msg + (response.status === 401 ? `: ${t('api.try again')}`: ''));
        }
      
      } catch (e) {
        setIsLoading(false);
      }
    }
    upload();
  },[    
    siteFetch,
    history,
    organizationId,
    siteId,
    studyId,
    subjectId,
    fileGuid,
    t,
    completedFormData,
  ]);

  const uploadChunk = useCallback( async (chunk) => {
    if(fileGuid === 0) return;
    try {
      const formData = new FormData();
      const blob = new Blob([chunk], { type: 'application/pdf' });
      formData.append('uploadSource', blob, originalFileMetadata.name);        
      formData.append('flowChunkNumber', counter.current);
      formData.append('flowChunkSize', chunkSize);
      formData.append('flowTotalSize', fileSize.current);
      formData.append('flowIdentifier', fileGuid);
      formData.append('flowFilename', originalFileMetadata.name);
      formData.append('singleChunk', chunkCount === 1 ? true : false);
      formData.append('flowTotalChunks', chunkCount);
     for (var pair of completedFormData.entries()) {
       if(pair[0] === 'documentItem'){
          formData.append(pair[0], pair[1]);
        }
     }
     console.log('chunk upload')
     const response = await siteFetch(`/gui/organization/${organizationId}/study/${studyId}/site/${siteId}/subject/${subjectId}/document/chunk`, {
        method: 'POST',
        headers: {
          TZ: (new Intl.DateTimeFormat()).resolvedOptions().timeZone,
          Authorization: `Bearer ${sessionData.sessionId}`,
        },
        body: formData, // body
      });

      const data = await response.json();
      if (data.isSuccess) {
        beginningOfTheChunk.current = endOfTheChunk.current;
        endOfTheChunk.current = endOfTheChunk.current + chunkSize;
        if (counter.current === chunkCount) {
          uploadCompleted();
        } else {
          let percentage = (counter.current / chunkCount) * 100;
          setProgress(percentage);
        }
      } else {
        setIsLoading(false);
        if(response.status === 401
          && (data.error === 'Session expired'
            || data.error === 'Session lost'
            )
          ) {
          onError(`${t('api.401 Unauthorized')}: '${t('api.' + data.error)}'`);
          return;
        }

        const msg = `${t('api.Upload Error')}: ${response.status} ${response.statusText}: ${data.error ? data.error : ''}`;
        onError(msg + (response.status === 401 ? `: ${t('api.try again')}`: ''));
      }
    } catch (error) {
      setIsLoading(false);
      console.log('error', error);
    }
  },[
    sessionData.sessionId,
    counter, 
    chunkCount, 
    endOfTheChunk, 
    organizationId,
    originalFileMetadata,
    siteId,
    studyId,
    subjectId,
    uploadCompleted,
    fileGuid,
    completedFormData,    
    t,
    siteFetch,
  ]);

  

  useEffect(() => {
    const fileUpload = async () => {
      counter.current = counter.current + 1;
      if (counter.current <= chunkCount) {
        let chunk = fileToBeUpload.slice(beginningOfTheChunk.current, endOfTheChunk.current);
        await uploadChunk(chunk)
      }
    };
      if (fileSize.current > 0 && fileToBeUpload && fileToBeUpload.size > 0 ) {
        fileUpload();
      }
  }, [
    fileToBeUpload, 
    progress, 
    counter, 
    beginningOfTheChunk, 
    chunkCount, 
    endOfTheChunk, 
    uploadChunk
  ])

  

  

  const resetChunkProperties = () => {
    counter.current = 0;
    beginningOfTheChunk.current = 0
    endOfTheChunk.current = chunkSize
  }

  // end chunking
  

  const passwordVerify = useCallback( async (presentSignatureMetadata) => {
    const url = `/gui/organization/${organizationId}/study/${studyId}/site/${siteId}/subject/${subjectId}/verify`;
    const formData = new FormData();
    let signatureMetadataItem = {
      signatureMetadata: [ presentSignatureMetadata ],
    }

    for (let key in signatureMetadataItem) {
      if (key === "signatureMetadata") {
        let tmpItem = signatureMetadataItem[key];
        tmpItem[0] = tmpItem[tmpItem.length - 1];
        
      }
    }
    formData.append('documentItem', JSON.stringify(signatureMetadataItem));

      const response = await siteFetch(url, {

        method: 'PATCH',
        headers: {
          TZ: (new Intl.DateTimeFormat()).resolvedOptions().timeZone,
          Authorization: `Bearer ${sessionData.sessionId}`,
        },
        body: formData,

      });
      return response;

  },[organizationId, sessionData.sessionId, siteId, studyId, subjectId, siteFetch])

  const isFileUploaded = useCallback (
    () => location.state && location.state.file,
     [ location.state ]);

  useEffect(() => {

    const setNewSourceFile = async file => {

      setOriginalFileMetadata({
        lastModified: file.lastModified,
        lastModifiedDate: file.lastModifiedDate,
        name: file.name,
        size: file.size,
        type: file.type,
      });

      const newSourceFile = await file.arrayBuffer();
      setSourceFile(newSourceFile);
      setDocumentName(file.name);
    };

    if(!isFileUploaded()){
        return;
    }

    setIsLoading(true);
    setNewSourceFile(location.state.file);
    setIsLoading(false);

  },[ location.state, isFileUploaded, setDocumentName ]);

  const site = siteData.edcUsers[0].sites[0];

  const onSignatureComplete = useCallback(
    (presentSignatureMetadata, setIsSaving) => {

    const onTaskDone = async presentSignatureMetadata => {


      setIsSaving(true);
      const formData = new FormData();

      const isRedactionQualityControlRequired =
        typeof site.isRedactionQualityControlRequired !== 'undefined'
        ? site.isRedactionQualityControlRequired
        : true;

      formData.append('documentItem', JSON.stringify({
        originalFileMetadata,
        associatedEvents,
        associatedLogs,
        signatureMetadata: [ presentSignatureMetadata ],
        reviewData: {
          status: isRedactionQualityControlRequired ? 'pending' : 'approved',
        }
      }));

      formData.append('uploadSource', new Blob([null], { type: 'application/pdf' }), originalFileMetadata.name);
      formData.append('edcSubjectKey', subjectData.subjectItem.edcSubjectKey);

      try {
        setIsLoading(true);
        setCompletedFormData(formData);
        const isTotpVerified = await passwordVerify(presentSignatureMetadata);
        if(isTotpVerified.status === 200){
          const blob = new Blob([destinationDocument], { type: 'application/pdf' });
          getFileContext({blob});
        }else{
          // throw exception
          onError((isTotpVerified.status === 401 ? `${t('api.try again')}`: ''));
          setIsLoading(false);
        }
        
      } catch (e) {
        console.log(e);
        onError(e);
      } finally {
        setIsSaving(false);
      }
      return;
    };

    onTaskDone( presentSignatureMetadata );
  }, [
       associatedEvents,
       associatedLogs,
       destinationDocument,
       originalFileMetadata,
       site.isRedactionQualityControlRequired,
       subjectData.subjectItem.edcSubjectKey,
       getFileContext,
       passwordVerify,
       t,
  ]);

  const steps = useMemo(() => {



    const result = [];

    // for URL reload
    if(!isFileUploaded()) {

      result.push({
        title: t('document-task.Upload Source'),
        content: <DocumentUploadPage
          setSourceFile={setSourceFile} 
          setOriginalFileMetadata={setOriginalFileMetadata}
          />,
      });

    }

    const isRedactionRequired = site?.isRedactionRequired ?  true : false;

    if(isRedactionRequired) {  
    
      result.push({
        title: t('document-task.Redact'),
        isRedactStep: true,
        content: (
            <DocumentRedactionEditor
              setDestinationDocument={setDestinationDocument}
              sourceFile={sourceFile}
              isLoading={isLoading}
              stepConfirm={stepConfirm}
              setStepConfirm={setStepConfirm}
            />
        ),
      });

    } else {

      setDestinationDocument(sourceFile);

    }

    result.push({
        title: t('document-task.Associate Events'),
        content: (
          <VisitSelector
            subjectData={subjectData}
            associatedEvents={associatedEvents}
            setAssociatedEvents={setAssociatedEvents}
          />
        ),
    });

    if (subjectData.log && subjectData.log.length >= 1) {
      result.push({
        title: t('document-task.Associate Logs'),
        content: (
          <FormSelector
            subjectData={subjectData}
            associatedLogs={associatedLogs}
            setAssociatedLogs={setAssociatedLogs}
          />
        ),
      });
    }
    result.push({
        title: t('document-task.Certify'),
        content: (
          <CertificationPage
            displayDocument={destinationDocument}
            originalFileMetadata={originalFileMetadata}
            associatedEvents={associatedEvents}
            associatedLogs={associatedLogs}
            onPasswordComplete={onSignatureComplete}
            isRedactionRequired={isRedactionRequired}
            isLoading={isLoading}
            />
        ),
    });
    
    return result;
  }, [
    associatedEvents,
    associatedLogs,
    destinationDocument,
    isFileUploaded,
    isLoading,
    onSignatureComplete,
    originalFileMetadata,
    site?.isRedactionRequired,
    sourceFile,
    subjectData,
    t,
    stepConfirm,
    setStepConfirm
  ]);

  return <DocumentEditSteps
           className='NewDocumentTask'
           steps={steps}
           stepConfirm={stepConfirm}
           setStepConfirm={setStepConfirm}
           />

};
