import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Auth from '../../lib/Auth';
import useAppUser from '../../lib/useAppUser';
import { onError } from '../../lib/onError';
import {
  EmailConfirmationForm,
  SubmitPasswordForm,
  TOTPKeyForm,
       } from '../../components/AuthorizationForms';
import './Register.css';

export default function Register() {


  const history = useHistory();
  const [ isRegistrationRequested, setIsRegistrationRequested ] = useState(false);
  const [ isEmailConfirmed, setIsEmailConfirmed ] = useState(false);
  const { accountState, userHasAuthenticated, setSessionData } = useAppUser();

  if(!Auth.hasEmail()) {
    history.push('/login');
  }

  useEffect(() => {

    const requestRegistration = async () => {

      const currentSession = await Auth.currentSession();

      if( currentSession.isEmailConfirmed ) {

        // we just reset authentication, email is already verified
        setIsEmailConfirmed(true);
        setIsRegistrationRequested( true );
        return;
      }
      
      try {
        Auth.register(Auth.getEmail());
      } catch (e) {
        console.log(e);
        onError(e);
      }
      setIsRegistrationRequested( true );
    };

    if(isRegistrationRequested) {
      return;
    }
    requestRegistration();

  }, [ isRegistrationRequested ]);

  const signIn = async newUser => {

    try { 
      const sessionData = await Auth.signIn(newUser.username, newUser.password);
      setSessionData(sessionData);
      userHasAuthenticated(true);
      if(sessionData.training.length > 0) {
        history.push('/training');
        return;
      }
      history.push('/');
    } catch (e) {
      console.log(e);
      onError(e);
    }

  };

  if(!isEmailConfirmed) {
    return (
      <div className='Register'>
        <EmailConfirmationForm
          email={Auth.getEmail()}
          onEmailConfirmed={() => setIsEmailConfirmed(true)}
        />
      </div>
    );
  }

  if(accountState.authenticationType === 'totp') {
    return (
      <div className='Register'>
        <TOTPKeyForm
          email={Auth.getEmail()}
          onTotpRegistered={signIn}
        />
      </div>
    );
  }

  return (
    <div className='Register'>
      <SubmitPasswordForm
        email={Auth.getEmail()}
        onPasswordRegistered={signIn}
      />
    </div>
    );

}
