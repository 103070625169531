import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Upload, PageHeader, Spin } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { ListDocumentsTask } from '../components/ListDocumentsTask';
import SiteFrame from '../components/SiteFrame';
import useSite from '../lib/useSite';
import useSubjectItem from '../lib/useSubjectItem';
import { onError } from '../lib/onError';
import 'antd/dist/antd.css';
import './SubjectDocumentList.scss';

const DocumentUpload = props => {

  const { gotopage, history } = props;
  const { t } = useTranslation();
  const { Dragger } = Upload;

  const [fileList, setFileList] = useState([]);

  const acceptableFileTypes = [
    'application/pdf',
  ];

  const handleChange = ({ fileList }) => {
    setFileList(fileList);
  };

  const uploadSource = argv => {
    argv.onSuccess();
    history.push({pathname: gotopage, state: {file: argv.file }});
  };

  return (
    <Dragger className='site-document-upload-dragger' {...{
      name: 'uploadSource',
      customRequest: uploadSource,
      onChange: handleChange,
      fileList,
      listType: 'picture-card',
      //accept: acceptableFileTypes.join(','), // negates beforeUpload for d'n'd
      beforeUpload: file => {
        if(!acceptableFileTypes.includes(file.type)) {
          onError(t('subject.We do not support that file format', { format: file.type }));
          return Upload.LIST_IGNORE;
        }
        return file;
      },
    }} >
      <p className='icon-container' >
        <InboxOutlined />
      </p>
      <div>
          {t('subject.Click or Drag file to this area to upload new document for subject')}
      </div>
    </Dragger>
  );

};

export default function SubjectDocumentList ( props ) {

  const { subjectItem } = useSubjectItem();
  const { siteData } = useSite();
  const { organizationId, studyId, siteId, subjectId } = useParams();
  const history = useHistory();
  const documentAddUrl = `/organization/${organizationId}/study/${studyId}/site/${siteId}/subject/${subjectId}/document/add`;

  if(!siteData || !subjectItem) {
    return <Spin />;
  }
  const subjectKey = subjectItem?.edcSubjectKey;
  const isSiteMonitor = siteData.edcUsers[0].sites[0].role === 'monitor';

  return (
    <div className='SubjectDocumentList'>
      <SiteFrame className='SubjectFrame' subjectId={subjectId}>
        <PageHeader
          className='page-header'
          title={subjectKey}
        />

        <ListDocumentsTask
          className='subject-list-documents-task'
          isSiteMonitor={isSiteMonitor}
          />

        {!isSiteMonitor && (
        <div className='document-upload'>
          <DocumentUpload
            gotopage={documentAddUrl}
            history={history}
            />
        </div>
        )}

      </SiteFrame>
    </div>
  );
}

/*
*/
