import { useContext } from 'react';
import { PreferencesContext } from './PreferencesContext';

const usePreferences = () => {
  const [state, setState ] = useContext(PreferencesContext);

  const setPreferences = preferences => {
    setState({
      ...state,
      ...preferences,
    });
  };

  const setPreference = (k, v) => {
    setState({
      ...state,
      [k]: v,
    });
  };

  const getPreference = k => state[k];
  const hasPreference = k => k in state;

  return {
    setPreferences,
    preferences: { ...state },
    setPreference,
    getPreference,
    hasPreference,
  };

};

export default usePreferences;
