import { useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import useAppUser from './useAppUser';
import useLocality from '../lib/useLocality';
import useSessionTracking from './useSessionTracking';

import { onError } from './onError';

const useSiteFetch = () => {

  const { sessionData, invalidateSession } = useAppUser();
  const { setSessionExpiry } = useSessionTracking();
  const {locality} = useLocality();
  const { t } = useTranslation();
  const fetching = useRef([]);
  
  const isSiteFetching = useCallback( input => fetching.current.includes(input),[]);
  
  const siteFetch = useCallback(async (input, init) => {

    if(fetching.current.includes(input)) {
      
      return;
    }
    fetching.current = [ ...fetching.current, input];

    try {

      if(!['dev', 'localhost'].includes(process.env.REACT_APP_API_HOST) && locality === null){
        throw new Error('locality is null');
      }
      const baseUrl = ['dev', 'localhost'].includes(process.env.REACT_APP_DOMAIN_API_HOST)
                      ? process.env.REACT_APP_API_HOST 
                      : `https://${process.env.REACT_APP_DOMAIN_API_HOST}${locality}.sourceupload.com`

      const response = await fetch( `${baseUrl}${input}`, 
      {
        ...init,
        headers: {
          ...(init?.headers ? init.headers : []),
          Authorization: `Bearer ${sessionData.sessionId}`
        },
      });
      
      if(response.status === 401) {
        const json = await response.clone().json();

        // Some clients trap a non-session 401, eg. documentReview onApprove()

        if (   json.error === 'Session expired'
            || json.error === 'Session lost'
           ) {
            onError(`${t('api.401 Unauthorized')}: '${t('api.' + json.error)}'`);
            invalidateSession();
            return response;
        }

        return response;

      } // response.status === 401

      const sessionExpiry = response?.headers?.get('X-ProtocolFirst-Session-Expires');
      if(sessionExpiry !== undefined) {
        setSessionExpiry(sessionExpiry);
      }

      if(response.status === 204) { // no content
        return response;
      }

      if(response.status === 500) {

        const json = await response.clone().json();
        onError(`${t('api.500 Internal Server Error')}: '${json.error}'`);
        return response;

      }

      return response;

    } finally {
      fetching.current = fetching.current.filter(e => e !== input);
    }


  }, [
    invalidateSession,
    sessionData.sessionId,
    setSessionExpiry,
    t,
    locality,
  ]);

  return {
    siteFetch,
    isSiteFetching,
  };

};

export default useSiteFetch;
