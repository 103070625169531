import React  from 'react';
import { useTranslation } from 'react-i18next';
import { Layout } from 'antd';
import Header from './Header';
import { StudiesBreadcrumb,
         SiteBreadcrumb,
         DocumentBreadcrumb,
         }
  from './AppBreadcrumbs'
import { AdminBreadcrumb } from './AdminBreadcrumbs';  

import 'antd/dist/antd.css';
import './Layout.scss';

const { Footer, Content } = Layout;

export const SuFooter = (props) => {

  const { t } = useTranslation();

  /*
  <Footer className='Footer'>
    Source Upload GUI v.{process.env.REACT_APP_GITREV} &copy;20xx<a href='https://www.protocolfirst.com' target='_blank' rel='noopener noreferrer'>Protocol First</a>
  </Footer>
  */

  return (

  <Footer className='Footer'>
    {t('general.copyright',
       { revision: process.env.REACT_APP_GITREV,
         copyrightYear: 2022,
       })
    } <a href={t('vendor-url')}
         target='_blank'
         rel='noopener noreferrer'>{t('vendor-name')}</a>
  </Footer>
  )
};


const StudiesProviderLayout = (props) => {
  return (
    <Layout className={`Layout StudiesProviderLayout ${props.className}`}>

      <Header className='LayoutHeader'/>
      <Content className='LayoutContent'>
        {props.children}
      </Content>
      <SuFooter  className='LayoutFooter'/>

    </Layout>
  );
};

export const AdminLayout = (props)  => {

      //<StudiesBreadcrumb className='StudiesLayout-StudiesBreadCrumb'/>
  return (
    <StudiesProviderLayout className='AdminLayout'>

      <AdminBreadcrumb/>
      <main className='StudiesLayout-Main'>
        {props.children}
      </main>

    </StudiesProviderLayout>
  );

};

export const StudiesViewportLayout = (props)  => {

  return (
    <StudiesProviderLayout className='ViewportLayout StudiesLayout'>

      <StudiesBreadcrumb className='StudiesLayout-StudiesBreadCrumb'/>
      <main className='StudiesLayout-Main'>
        {props.children}
      </main>

    </StudiesProviderLayout>
  );

};

export const SiteViewportLayout = (props)  => {
  return (
    <StudiesProviderLayout className='ViewportLayout SiteLayout'>
      
        <SiteBreadcrumb { ...props } className='SiteLayout-SiteBreadCrumb'/>
        <main className='SiteLayout-Main'>
          {props.children}
        </main>

    </StudiesProviderLayout>
  );

};

export const DocumentLayout = (props)  => {
  return (
    <StudiesProviderLayout className='DocumentLayout'>
        <DocumentBreadcrumb className='DocumentLayout-DocumentBreadCrumb'/>
        <main className='DocumentLayout-Main'>
          {props.children}
        </main>
    </StudiesProviderLayout>
  );

};

export const DocumentViewportLayout = (props)  => {
  return (
    <StudiesProviderLayout className='ViewportLayout DocumentLayout'>
        <DocumentBreadcrumb className='DocumentLayout-DocumentBreadCrumb'/>
        <main className='DocumentLayout-Main'>
          {props.children}
        </main>
    </StudiesProviderLayout>
  );

};

export const DefaultViewportLayout = (props)  => {

  return (
    <Layout className='ViewportLayout Layout DefaultLayout'>

      <Header/>
      <Content>
        <main>
          {props.children}
        </main>
      </Content>
      <SuFooter />

    </Layout>

  );

};


