import React, { useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Form, Input, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import Auth from '../../lib/Auth';
import useAppUser from "../../lib/useAppUser";
import { onError } from '../../lib/onError';
import './Login.css';

export default function EmailLogin() {

  const { t } = useTranslation();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const { search } = useLocation();
  const { email } = useParams() ;
  const [ form ] = Form.useForm();
  const { setAccountState } = useAppUser();

  async function handleSubmit(values) {
    setIsLoading(true);

    try {
      await Auth.setEmail(values.email);
      const authState = await Auth.currentSession();
      setAccountState(authState);

      if(authState.isRegistered) {
        history.push(`/password${search}`);
      } else {
        if(authState.isResetRequested) {
          history.push(`/reset${search}`);
        } else {
          history.push(`/register${search}`);
        }
      }
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
  }

  return (
    <div className='Login'>
      <Form onFinish={handleSubmit}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            form={form}
            initialValues={{
              email,
            }}
      >
      <Form.Item
        label={t('authentication.Email')}
        type='email'
        name='email'
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input type='email'/>
      </Form.Item>        

      <Form.Item wrapperCol={{ offset: 8, span: 16 }} >
        <Button
          loading={isLoading}
          htmlType='submit'
          type='primary'
        >{t('authentication.Login')}</Button>
      </Form.Item>

    </Form>
  </div>
  );

}
