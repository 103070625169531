import React, { useState } from 'react';

// follows https://upmostly.com/tutorials/how-to-use-the-usecontext-hook-in-react

const SessionTrackingContext = React.createContext([{}, () => {}]);

const SessionTrackingProvider = (props) => {

  const [state, setState] = useState(null);
  return (
    <SessionTrackingContext.Provider value={[state, setState]}>
      {props.children}
    </SessionTrackingContext.Provider>
  );
}

export { SessionTrackingContext, SessionTrackingProvider };
