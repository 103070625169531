import React, { useEffect, useState } from 'react';
import useAppUser from '../lib/useAppUser';
import useFetch from '../lib/useFetch';
import { onError } from '../lib/onError';
import { useTranslation } from 'react-i18next';

import './UserProfile.css';

export default function UserProfile() {

  const { sessionData } = useAppUser();
  const { authenticatedFetch } = useFetch();
  const [profile, setProfile] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    async function onLoad() {

      try {

        const response = await authenticatedFetch(
                                 `/gui/user/${sessionData.email}/profile`);
       const json = await response.json();

       if(response.status !== 200) {


          onError(`${t('profile.Error fetching profile')}: ${response.status} ${response.statusText}: ${json.error ? json.error : ''}`);
          return;

        }
        setProfile(json);
      } catch (e) {
        onError(e);
      }
    }

    onLoad();

  }, [
       sessionData.email,
       authenticatedFetch,
       t,
     ]);



  if(profile === null) {
    return null;
  }

  return (
    <div className="UserProfile">
      <div className='content'>
        <h1>{t('profile.User Profile')}</h1>
        <h2>{profile.name}</h2>
        <h2>{profile.email}</h2>
        <p>{profile.role}</p>

      </div>  
    </div>
  );
}
