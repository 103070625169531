import React from 'react';
import {
  Form,
  Input,
  } from 'antd';
import { useTranslation } from 'react-i18next';
import useSite from '../lib/useSite'
import TotpInput from './TotpInput';
import 'antd/dist/antd.css';

export const SiteUserSignatureForm = props => {

  const {
    signatureReasons,
    onPasswordComplete,
    onPasswordIncomplete = () => {},
    isPasswordDisabled,
  } = props;

  const { t } = useTranslation();
  const { siteData } = useSite();
  const [ form ] = Form.useForm();

  return (
    <Form
      form={form}
    >
        <div className='reason'>
          <ul>
            {signatureReasons.map( (reason, i) => (
            <li key={`reason-${i}`}>{reason}</li>
            ))}
          </ul>
        </div>
      <Form.Item
        labelCol={{span: 4 }}
        wrapperCol={{span: 16 }}
        label={t('site-user-signature-form.Email')}
        name='email'
        initialValue={siteData.email}
        >
        <Input
          disabled={true}
        />
      </Form.Item>
      <Form.Item
        labelCol={{span: 4 }}
        wrapperCol={{span: 4 }}
        label={t('site-user-signature-form.TOTP Code')}
        name='password'
        >
        <TotpInput 
          disabled={isPasswordDisabled}
          onPasswordComplete={() => onPasswordComplete({
              reasons: signatureReasons,
              name: `${siteData.edcUsers[0].firstName} ${siteData.edcUsers[0].lastName}`,
              email: siteData.email,
              ...form.getFieldsValue(true),
              clientDate: Date.now(),
            })}
          onPasswordIncomplete={onPasswordIncomplete}    
        />
      </Form.Item>
    </Form>
  );
};

