import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Auth from '../../lib/Auth';
import useAppUser from '../../lib/useAppUser';
import { Form, Input, Button, Space } from 'antd';
import { onError } from '../../lib/onError';

import 'antd/dist/antd.css';
import './Register.css';

const resetLabel = (t, authenticationType) => {
  switch (authenticationType) {
    case 'totp':
      return t('authentication.Reset TOTP Key');
    case 'password':
    default:
      return t('authentication.Reset Password');
  }
};

export default function PasswordReset() {

  const { t } = useTranslation();
  const history = useHistory();
  const { accountState } = useAppUser();
  const [ form ] = Form.useForm();
  const [ isSubmitButtonVisible, setIsSubmitButtonVisible ] = useState( false );
  const [ isLoading, setIsLoading ] = useState(false);

  if(!Auth.hasEmail()) {
    history.push('/login');
  }

  const confirmEmail = async value => {

    setIsLoading(true);
    try {

      let options = {};

      if(value.isCancelReset) {
        options.isCancelReset = value.isCancelReset;
      }

      const response = await Auth.confirmSignUp(Auth.getEmail(), value.confirmationCode, options);

      if(response.error && response.error === 'Invalid confirmation code') {
        console.log(response.error);
        onError(t('authentication.Invalid confirmation code'));
        setIsLoading(false);
        return;
      }
      if(response.error && response.error === 'Confirmation code has expired') {
        Auth.forgotPassword(Auth.getEmail());
        const message = t('authentication.confirmation-code-expiry-message');
        console.log(message);
        onError(message);
        setIsLoading(false);
        return;
      }


      if(value.isCancelReset) {
        history.push('/password');
      } else {
        history.push('/register');
      }
    } catch (e) {
      console.log(e);
      onError(e);
      setIsLoading(false);
    }

  };

  const handleForgottenPassword = async () => {
    Auth.forgotPassword(Auth.getEmail());
    history.push('/reset');
  };

  return (
    <div className='Register'>
      <Form onFinish={confirmEmail}
        form={form}
        onValuesChange={(changedValues, allValues) => {
          setIsSubmitButtonVisible(
            allValues.confirmationCode
            && allValues.confirmationCode.length === 6
          );
        }} 
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
      >
        <Form.Item
          name='confirmationCode'
          label={t('authentication.Confirmation Code')}
          extra={t('authentication.please-check-your-email-for-the-code')}
          rules={[
              {
                required: true,
                message: t('authentication.email confirmation code is required'),
              },
              // don't be so hasty to draw rejection messages
              form => ({
                validator: (rule, value) =>
                  new Promise(
                    (resolve, reject) => setTimeout(() => resolve(), 800))
              }),
              {len: 6},
            ]}
        >
          <Input
            type='tel'
          />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 8, span: 16 }} >
          <Space>
            <Form.Item>
              <Button
                disabled={ !isSubmitButtonVisible }
                loading={isLoading}
                htmlType='submit'
                onClick={() => {
                  form.setFieldsValue({isCancelReset: false});
                }}
              >{resetLabel(t, accountState.authenticationType)}</Button>
            </Form.Item>
            <Form.Item name='isCancelReset'>
              <Button
                disabled={ !isSubmitButtonVisible }
                loading={isLoading}
                htmlType='submit'
                onClick={() => form.setFieldsValue({isCancelReset: true})}
              >{t('authentication.Cancel Reset')}</Button>
            </Form.Item>
          </Space>
          <div>
            <Button type='link'
                    style={{ padding: '0px'}}
                    onClick={ handleForgottenPassword }>{
              t('authentication.Resend Confirmation Code')
            }</Button>
          </div>
        </Form.Item>
      </Form>
    </div>
  );

}
