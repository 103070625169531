import React, { useEffect, useMemo }  from 'react';
import { useParams, Link } from 'react-router-dom';
import { Breadcrumb } from 'antd';
import { useTranslation } from 'react-i18next';
import useStudies from '../lib/useStudies';
import useSite from '../lib/useSite';
import useLocality from '../lib/useLocality';
import useSubjectItem from '../lib/useSubjectItem';
import useDocument from '../lib/useDocument';
import useFetch from '../lib/useFetch';
import useSiteFetch from '../lib/useSiteFetch';
import 'antd/dist/antd.css';

export function StudiesBreadcrumb(props) {

  const { organizationId, studyId } = useParams();

  const { t } = useTranslation();
  const { isStudiesContextLoaded, studyData, setStudyData } = useStudies();
  const { authenticatedFetch, isFetching } = useFetch();

  useEffect(() => {
    const url = '/gui/study';
    const loadStudyData = async () => {
      const response = await authenticatedFetch(url);
      setStudyData(await response.json());
    }

    if(!isStudiesContextLoaded && !isFetching(url)) {
      loadStudyData();
    }
  }, [ authenticatedFetch, isStudiesContextLoaded, setStudyData, isFetching ]);

  const renderBreadcrumbs = (studyData) => {

    let breadcrumbs = [
      <Breadcrumb.Item key='home'>
        <Link to='/study'>
          {t('navigation.Home')}
        </Link>
      </Breadcrumb.Item>
    ];


    if(studyId) {

    const study = studyData.find(s => 
                        s.organization.organizationId === organizationId
                     && s.studyId === studyId);
      breadcrumbs.push(
        <Breadcrumb.Item key={studyId}>
          <Link to={`/organization/${organizationId}/study/${studyId}`}>
            {study.studyName}
          </Link>
         </Breadcrumb.Item>
      );
    }

    return breadcrumbs;
  }

  return (
    <Breadcrumb className={`Breadcrumb ${props.className}`}>
      {isStudiesContextLoaded && renderBreadcrumbs(studyData)}
    </Breadcrumb>
  )

}

export const SiteBreadcrumb = props => {
  
  const { t } = useTranslation();
  const { isSiteContextLoaded, siteData, setSiteData, currentSiteId } = useSite();
  const { setLocality } = useLocality();
  const { setSubjectItem, subjectItem } = useSubjectItem();
  const { organizationId, studyId, siteId, subjectId } = useParams();
  const { siteFetch, isSiteFetching } = useSiteFetch();
  const { authenticatedFetch, isFetching } = useFetch();
  
  useEffect(() => {
    const url = `/gui/organization/${organizationId}/study/${studyId}/site/${siteId}`;
    const loadSiteData = async () => {
      const response = await authenticatedFetch( url );
      setSiteData(await response.json());
    }

    if( (!isSiteContextLoaded || currentSiteId !== siteId) && !isFetching(url) ) {
      loadSiteData();
    }
  }, [
    authenticatedFetch,
    isFetching,
    isSiteContextLoaded,
    currentSiteId,
    setSiteData,
    organizationId,
    siteId,
    studyId
  ]);
  
  useEffect(() => {
    if( isSiteContextLoaded && currentSiteId !== undefined && currentSiteId === siteId ) {
      setLocality(siteData?.edcUsers?.[0]?.sites?.[0]?.locality);
    }
  },[ currentSiteId, isSiteContextLoaded, setLocality, siteId, siteData?.edcUsers ]);

  useEffect(() => {

    const url = `/gui/organization/${organizationId}/study/${studyId}/site/${siteId}/subject/${subjectId}`;

    const loadSubjectItem = async () => {


      const response = await siteFetch( url, {
        headers: {
          // timezone for 'view subject' audit entry
          TZ: (new Intl.DateTimeFormat()).resolvedOptions().timeZone,
        },
      });
      setSubjectItem(await response.json());
    }

    if(    subjectId !== undefined
        && subjectId
        && subjectId !== subjectItem?.subjectId
        && !isSiteFetching( url )) {
      loadSubjectItem();
    }

  }, [
    siteFetch,
    isSiteFetching,
    organizationId,
    siteId,
    studyId,
    subjectId,
    setSubjectItem,
    subjectItem?.subjectId 
   ]);

  if(!siteData) {
    return null;
  }
 
  const study = siteData?.edcUsers?.[0]?.study;
  const site = siteData?.edcUsers?.[0]?.sites?.[0];

  if(!study || !site ) {
    return null;
  }

  if(subjectId) {

    if(!subjectItem
       || subjectItem.subjectId !== subjectId) {
      return null; 
    }

    
    return (
      <Breadcrumb className={`Breadcrumb ${props.className}`}>
        <Breadcrumb.Item key='home'>
          <Link to='/study'> {t('navigation.Home')} </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item key={studyId}>
          <Link to={`/organization/${organizationId}/study/${studyId}`}>
            {study.studyName}
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item key={siteId}>
          <Link to={`/organization/${organizationId}/study/${studyId}/site/${siteId}/subject`}>
            {site.siteName} [{('' + site.locality).toUpperCase()}]
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item key={subjectId}>
          <Link to={`/organization/${organizationId}/study/${studyId}/site/${siteId}/subject/${subjectId}`}>
          {subjectItem.edcSubjectKey}
          </Link>
        </Breadcrumb.Item>
      </Breadcrumb>
    );
  }
  return (
    <Breadcrumb className={`Breadcrumb ${props.className}`}>
      <Breadcrumb.Item key='home'>
        <Link to='/study'> {t('navigation.Home')} </Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item key={studyId}>
        <Link to={`/organization/${organizationId}/study/${studyId}`}>
          {study.studyName}
        </Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item key={siteId}>
        <Link to={`/organization/${organizationId}/study/${studyId}/site/${siteId}/subject`}>
        {site.siteName} [{('' + site.locality).toUpperCase()}]
        </Link>
      </Breadcrumb.Item>
    </Breadcrumb>
  );
};

export const DocumentBreadcrumb = props => {

  const { t } = useTranslation();
  const { isSiteContextLoaded, siteData, setSiteData, currentSiteId } = useSite();
  const { setSubjectItem, subjectItem } = useSubjectItem();
  const { documentItem, setDocumentItem, isDocumentItemLoaded, currentDocumentId, currentDocumentVersion } = useDocument();
  const { organizationId, studyId, siteId, subjectId, documentId, version } = useParams();
  const { siteFetch, isSiteFetching } = useSiteFetch();
  const { authenticatedFetch, isFetching } = useFetch();
  const { setLocality } = useLocality();
  
  useEffect(() => {
    const url = `/gui/organization/${organizationId}/study/${studyId}/site/${siteId}`;
    const loadSiteData = async () => {
      const response = await authenticatedFetch( url );
      setSiteData(await response.json());
    }

    if( (!isSiteContextLoaded || currentSiteId !== siteId) && !isFetching(url) ) {
      loadSiteData();
    }
  }, [
    authenticatedFetch,
    isFetching,
    isSiteContextLoaded,
    currentSiteId,
    setSiteData,
    organizationId,
    siteId,
    studyId
  ]);

  useEffect(() => {
    if( isSiteContextLoaded && currentSiteId === siteId ) {
      setLocality(siteData?.edcUsers?.[0]?.sites?.[0]?.locality);
    }
  },[ currentSiteId, isSiteContextLoaded, setLocality, siteId, siteData?.edcUsers ]);


  useEffect(() => {

    const url = `/gui/organization/${organizationId}/study/${studyId}/site/${siteId}/subject/${subjectId}`;

    const loadSubjectItem = async () => {


      const response = await siteFetch( url, {
        headers: {
          // timezone for 'view subject' audit entry
          TZ: (new Intl.DateTimeFormat()).resolvedOptions().timeZone,
          },
      });
      setSubjectItem(await response.json());
    }

    if(    subjectId !== undefined
        && subjectId
        && subjectId !== subjectItem?.subjectId
        && !isSiteFetching( url )) {
      loadSubjectItem();
    }

  }, [
    siteFetch,
    isSiteFetching,
    organizationId,
    siteId,
    studyId,
    subjectId,
    setSubjectItem,
    subjectItem?.subjectId 
   ]);

  // version is undefined for a versions list
  // we use version 0 in that case, which fetches the latest version.
  const effectiveVersion = useMemo(() => {

    if(typeof version !== 'undefined') {
      return version;
    }
    if(currentDocumentId === documentId) {
      return currentDocumentVersion;
    }
    return 0;

  }, [ version, currentDocumentId, currentDocumentVersion, documentId ]);

  useEffect(() => {

    const url = `/gui/organization/${organizationId
                           }/study/${studyId
                            }/site/${siteId
                         }/subject/${subjectId
                        }/document/${documentId
                         }/version/${effectiveVersion}/view`

    const loadDocumentItem = async () => {


      const response = await siteFetch( url );
      setDocumentItem(await response.json());
    }

    if(!isDocumentItemLoaded
        && currentDocumentId !== documentId
        && (!currentDocumentVersion
            || currentDocumentVersion.toString() !== effectiveVersion.toString())
        && !isSiteFetching( url )) {

      loadDocumentItem();
    }


  }, [ 
      siteFetch,
      isSiteFetching,
      organizationId,
      siteId,
      studyId,
      subjectId,
      documentId,
      effectiveVersion,
      currentDocumentId,
      currentDocumentVersion,
      isDocumentItemLoaded,
      setDocumentItem,
      t,
  ]);

  if( !isSiteContextLoaded) {
    return null;
  }

  if( !subjectItem || subjectItem.subjectId !== subjectId) {
    return null; 
  }


  if (!documentId) {
    return null;
  }

  if (!isDocumentItemLoaded) {
    return null;
  }

  const breadcrumbs = [
    <Breadcrumb.Item key='home'>
      <Link to='/study'> {t('navigation.Home')} </Link>
    </Breadcrumb.Item>
  ];

  const study = siteData.edcUsers[0].study;

  breadcrumbs.push(
    <Breadcrumb.Item key={studyId}>
      <Link to={`/organization/${organizationId}/study/${studyId}`}>
        {study.studyName}
      </Link>
    </Breadcrumb.Item>
  );

  // const site = siteData.edcUsers[0].sites[0];
  const site = siteData.edcUsers[0].sites[0];
  breadcrumbs.push(
    <Breadcrumb.Item key={siteId}>
      <Link to={`/organization/${organizationId}/study/${studyId}/site/${siteId}/subject`}>
      {site.siteName} [{('' + site.locality).toUpperCase()}]
      </Link>
    </Breadcrumb.Item>
  );

  breadcrumbs.push(
    <Breadcrumb.Item key={subjectId}>
      <Link to={`/organization/${organizationId}/study/${studyId}/site/${siteId}/subject/${subjectId}`}>
        {subjectItem.edcSubjectKey}
      </Link>
    </Breadcrumb.Item>
  );  

  const isSiteMonitor = site.role === 'monitor';

  if(!isSiteMonitor) {
    breadcrumbs.push(
      <Breadcrumb.Item key={documentId}>
        <Link to={`/organization/${organizationId}/study/${studyId}/site/${siteId}/subject/${subjectId}/document/${documentId}`}>
          {documentItem.originalFileMetadata.name} 
        </Link>
      </Breadcrumb.Item>
    );
  }

  if(version) {

    if(isSiteMonitor) {
    breadcrumbs.push(
        <Breadcrumb.Item key='version'>
          <Link to={`/organization/${organizationId}/study/${studyId}/site/${siteId}/subject/${subjectId}/document/${documentId}/version/${version}/view`}>
            {documentItem.originalFileMetadata.name} 
          </Link>
        </Breadcrumb.Item>
    );
    }

    breadcrumbs.push(
        <Breadcrumb.Item key='version'>
          <Link to={`/organization/${organizationId}/study/${studyId}/site/${siteId}/subject/${subjectId}/document/${documentId}/version/${version}/view`}>
            {t('document-item-view.Version')} {documentItem.version}
          </Link>
        </Breadcrumb.Item>
    );

  }

  return (
    <Breadcrumb className={`Breadcrumb ${props.className}`}>
      {breadcrumbs}
    </Breadcrumb>
  );  


};
