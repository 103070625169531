import React, { useState, useEffect  }  from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'antd';
import useSessionTracking from '../lib/useSessionTracking';
import useFetch from '../lib/useFetch';

const calculateSecondsRemaining = sessionExpiry => {

  if(sessionExpiry === undefined || sessionExpiry === null) {
    return null;
  }
  return sessionExpiry - ( Date.now() / 1000 );
};

const formatTime = duration => {
  const m = Math.floor(duration / 60);
  const s = Math.floor(duration - m * 60);

  const format = n => n.toString().padStart(2, '0');

  return `${format(m)}:${format(s)}`;
};

const SessionWatcher = props => {

  const { warningTime, expireAction } = props;
  const { t } = useTranslation();
  const { sessionExpiry } = useSessionTracking();
  const { authenticatedFetch } = useFetch();
  const [ isWarningVisible, setIsWarningVisible ] = useState(false);
  const [ isSkipWarning, setIsSkipWarning] = useState(false);
  const [ secondsRemaining, setSecondsRemaining ] = useState(
                                      calculateSecondsRemaining(sessionExpiry));

  useEffect(() => {
    const timerId = setInterval( () => {
      setSecondsRemaining( calculateSecondsRemaining(sessionExpiry));
    }, 1000 );
    return () => clearInterval(timerId);
  });

  // reset: user was warned, canceled, and hit the server, extending session
  useEffect(() => {
    if(!isSkipWarning || secondsRemaining === null || secondsRemaining <= warningTime) {
      return;
    }
    setIsSkipWarning(false);
  }, [ secondsRemaining, warningTime, isSkipWarning ]);

  useEffect(() => {
    if(isSkipWarning ||secondsRemaining === null || secondsRemaining > warningTime) {
      return;
    }
    setIsWarningVisible(true);
  }, [ secondsRemaining, warningTime, isSkipWarning ]);

  useEffect(() => {
    if(secondsRemaining === null || secondsRemaining > 0) {
      return;
    }
    expireAction();
  }, [ expireAction, secondsRemaining, sessionExpiry ]);

  // only just logged in ?
  if(secondsRemaining === null) {
    return null;
  }

  return (<Modal 
            visible={isWarningVisible}
            cancelText={t('session-watcher.Dismiss')}
            okText={t('session-watcher.Extend Session')}
            onCancel={() => {
              setIsSkipWarning(true);
              setIsWarningVisible(false);
            }}
            onOk={() => {
              setIsWarningVisible(false);
              authenticatedFetch('/gui/ping');
            }}
            maskClosable={false}
          >
            <h2>{t('session-watcher.Session Expiry Warning')}</h2>
            <p>{t('session-watcher.Session Expiry')}: {formatTime(secondsRemaining)}</p>

          </Modal>);
};

export default SessionWatcher;
