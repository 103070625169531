import React, { useState, useEffect, Suspense }  from 'react';
import { AppUserProvider } from './lib/AppUserContext';
import { onError } from './lib/onError';
import Auth from './lib/Auth';
import Routes from './Routes';

const App = () => {
  const [isAuthenticating, setIsAuthenticating] = useState(true);

  useEffect(() => {
    onLoad();
  }, []);

  async function onLoad() {

    try {
      await Auth.currentSession();
    }
    catch(e) {

      if (e !== 'No current user') {
        onError(e);
      }
    }

    setIsAuthenticating(false);
  }

  // loading component for suspense fallback
  /*
      <img src={logo} className="App-logo" alt="logo" />
  */
  const Loader = () => (
    <div className="App">
      <img src='/SU-logo-430x409-transparent.png' alt='logo' />
    </div>
  );

  return (
    !isAuthenticating &&
      <Suspense fallback={<Loader />}>
        <AppUserProvider>
          <Routes/>
        </AppUserProvider>
      </Suspense>
  );
}

export default App;
