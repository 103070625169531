import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Form, Input, Button, Divider, Select, Checkbox } from 'antd';
import useFetch from '../../lib/useFetch';
import { onError } from '../../lib/onError';
import { RawData } from '../../components/AdminForms';

const config = {
  name: 'User',
};

const roles = [
  { role: 'study-user', label: 'Study User' },
  { role: 'account-manager', label: 'Account Manager' },
  //{ role: 'demo-user', label: 'Demo User' },
  //{ role: 'developer', label: 'Developer' },

];

const InviteForm = props => {

  const { data } = props;
  const { authenticatedFetch } = useFetch();
  const [ studies, setStudies ] = useState(null)
  const [ isWaiting, setIsWaiting ] = useState(false)

  const { Item } = Form;
  const { Option } = Select;

  useEffect(() => {

    const url = `/gui/site/${data.email}`;

    const loadData = async () => {

      
      try{
      
        const response = await authenticatedFetch(url);

        if(response.status !== 200) {
  
          const json = await response.json();
          onError(`${response.status} ${response.statusText}: '${json.error}'`);
          return;
  
        }

        const json = await response.json();
        setStudies(json);

      } catch(error) {

        console.log({error});
        onError('No results (data for invite form)');
      }

    };

    if(studies || !data?.email) {
      return;
    }

    loadData();
  }, [
    studies,
    data?.email,
    authenticatedFetch,
  ]);

  const sendInvite = async values => {

    const studyItem = studies.find(s => s.studyId = values.studyId);

    setIsWaiting(true);
    const response = await authenticatedFetch( '/gui/app-user/invite',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: data.email,
          organizationId: studyItem.organizationId,
          studyId: studyItem.studyId,
        })
      });
    setIsWaiting(false);

    if(response.status === 500) {
      return;
    }

    if(response.status !== 201) {
      const json = await response.json();
      onError(`Update Error: '${json.error}'`);
      return;
    }

  };

  if(!studies) {
    return null;
  }

  return (
    <Form
      onFinish={sendInvite}
    >
      <Item name='studyId' label='Study'
        rules={[{ required: true }]}
      >
        <Select style={{width: '200px'}} >
          {studies.map(e => (

            <Option key={e.studyId} value={e.studyId}>{e.studyName}</Option>

          ))}
        </Select>
      </Item>

      <Item>
        <Button
          type='primary'
          htmlType='submit'
          loading={isWaiting}
          >Send invite</Button>
      </Item>
    </Form>

  );
      //{message}
};

const EditForm = props => {

  const { data, setData } = props;
  const { authenticatedFetch } = useFetch();
  const [ isWaiting, setIsWaiting ] = useState(false)

  const { Item } = Form;
  const { Option } = Select;

  const onFinish = async values => {

    const appUserItem = {
      email: values.email,
      name: values.name,
      role: values.role,
      signingData: values.resetSigningCertificate
        ?  undefined
        : { ...data.signingData },
      credentials: values.resetCredentials || values.resetSigningCertificate
        ? undefined
        : { ...data.credentials },
    };


    setIsWaiting(true);
    const response = await authenticatedFetch(
      `/api/app-user/${values.email}`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(appUserItem),

      });
    setIsWaiting(false);

    const json = await response.json();

    if(response.status !== 200) {
      onError(`Update Error: '${json.error}'`);
      return;
    }

    setData(json);

  };

  return (
    <Form
      onFinish={onFinish}
      initialValues={{
        email: data.email,
        name: data.name,
        role: data.role,
        resetCredentials: false,
        resetSigningCertificate: false,
      }}
      style={{ width: '600px', }}
    >

      <Item name='email' label='Email'>
        <Input />
      </Item>

      <Item name='name' label='Name'>
        <Input />
      </Item>

      <Item name='role' label='Role'>
        <Select style={{width: '200px'}}>
          {
            roles.map(e => <Option key={e.role} value={e.role}>{e.label}</Option>)
          }
        </Select>
      </Item>

      <Item name='resetCredentials'
            label='Reset Credentials'
            valuePropName='checked'
            >
        <Checkbox />
      </Item>

      <Item name='resetSigningCertificate'
            label='Reset Signing Certificate'
            valuePropName='checked'
            >
        <Checkbox />
      </Item>

      <Item>
        <Button
          type='primary'
          htmlType='submit'
          loading={isWaiting}
          >Submit</Button>
      </Item>
    </Form>
  );
};

const AddForm = props => {
  const { email, setData } = props;
  const { authenticatedFetch } = useFetch();

  const { Item } = Form;
  const { Option } = Select;

  const onFinish = async values => {
    try {

      const appUserItem = {
        email: values.email,
        name: values.name,
        role: values.role,
      };

      const response = await authenticatedFetch(
        '/api/app-user',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(appUserItem),

        });
        const json = await response.json();
 
        if(response.status !== 201) {
          onError(`Upload Error: '${json.error}'`);
          return;
        }

        setData(json);

      } catch (e) {
        console.error(e)
      }
  }; // onFinish
  
  return (
    <Form
      onFinish={onFinish}
      initialValues={{
        email,
      }}
      style={{ width: '600px', }}
    >

      <Item name='email' label='Email'>
        <Input />
      </Item>

      <Item name='name' label='Name'>
        <Input />
      </Item>

      <Item name='role' label='Role'>
        <Select style={{width: '200px'}}>
          {
            roles.map(e => <Option key={e.role} value={e.role}>{e.label}</Option>)
          }
        </Select>
      </Item>

      <Item>
        <Button type='primary' htmlType='submit' >Submit</Button>
      </Item>

    </Form>
  );
    

};

const SearchForm = props => {

  const history = useHistory();
  const { placeholder } = props;

  return (
    <Form onFinish={values => history.push(`/admin/user/${values.email}`)} >
      <Form.Item name='email' >
        <Input style={{ width: '300px'}} placeholder={placeholder} />
      </Form.Item>
      <Form.Item>
        <Button type='primary' htmlType='submit' >Submit</Button>
      </Form.Item>
    </Form>
  );
};

const TrainingList = props => {

  const { email } = props;
  const [ isDataVisible, setIsDataVisible ] = useState(false);
  const { authenticatedFetch } = useFetch();
  const [ data, setData ] = useState('');

  const loadData = async () => {


    try{

      const response = await authenticatedFetch(`/gui/training/${email}`);
    
      if(response.status !== 200) {
  
        const json = await response.json();
        onError(`${response.status} ${response.statusText}: '${json.error}'`);
        return;
  
      }

      const json = await response.json();
      setData(json);
      setIsDataVisible(true);

    }catch(error){

      console.log({error});
      onError(`No results (training)`);
    }

  };

  return (
    <>
      <Button onClick={loadData}>
        Training (Raw)
      </Button>
      <pre style={{
        visibility: isDataVisible ? 'visible' : 'hidden',
        height: isDataVisible ? 'inherit' : '0px',
        backgroundColor: 'white',
        padding: isDataVisible ? '30px' : '0px',
        margin: isDataVisible ? '20px' : '0px',
        border: 'solid black 1px',
      }}
      >
        {JSON.stringify(data, null, 2)}
      </pre>
    </>
  );
};

const ItemList = props => {

  const { email } = useParams();

  const { authenticatedFetch } = useFetch();

  const [ data, setData ] = useState('');

  useEffect(() => {

    const loadData = async () => {

      try {

        const response = await authenticatedFetch(`/api/app-user/${email}`);

        if(response.status !== 200) {
  
          const json = await response.json();
          onError(`${response.status} ${response.statusText}: '${json.error}'`);
          return;
  
        }

        const json = await response.json();
        setData(json);

      } catch(error) {
        console.log({error});
        onError(`No results (data for ${email})`);
      }

    };

    if(!email) {
      return;
    }

    loadData();

  }, [
    email,
    authenticatedFetch,
    ]);

  if(!email) {
    return (
      <SearchForm placeholder='email address'/>
    );
  }
  
  if(!data || data.email !== email) {
    return (
      <div>
        <Divider orientation='left'>New SourceUpload User</Divider>
        <AddForm
          email={email}
          setData={setData}
        />
      </div>
    );
  }


  return (
    <div>
      <h1>{config.name} View: {email}</h1>
      <Divider orientation='left'>Edit SourceUpload User {email}</Divider>
      <EditForm
        data={data}
        setData={setData}
      />
      <Divider orientation='left'>Invite {email}</Divider>

      <InviteForm
        data={data}
      />

      <Divider orientation='left'>Raw</Divider>
      <RawData data={data} />

      <Divider orientation='left'>Training (Raw)</Divider>
      <TrainingList email={email}/>

    </div>
  );
};

const AdminUser = props => {
  return <ItemList />;
};

export { AdminUser };
