import React from 'react';
import { useParams } from 'react-router-dom';

import { DocumentListVersions } from '../components/DocumentListVersions';
import SiteFrame from '../components/SiteFrame';
import useSite from '../lib/useSite';

export default function DocumentVersionsList ( props ) {

  const { subjectId } = useParams();
  const { siteData } = useSite();

  if(!siteData) {
    return null;
  }

  const isSiteMonitor = siteData.edcUsers[0].sites[0].role === 'monitor';

  return (
    <SiteFrame className='SubjectFrame' subjectId={subjectId}>
      <DocumentListVersions 
        isSiteMonitor={isSiteMonitor}
      />
    </SiteFrame>
  );
}
